import styled, { keyframes } from "styled-components";
import {
  AccordionSummary,
  Alert,
  Button,
  Dialog,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/lab";

const color = (severity) => {
  switch (severity) {
    case "success":
      return "#0ab4aa";
    case "error":
      return "#f72626";
    case "info":
      return "#0498fc";
    case "warning":
      return "#ff5a18";
  }
};

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #0ab4aa !important;
  border-radius: 15px;
`;

export const AlertStyled = styled(Alert)`
  margin: 10px;
  border-radius: 15px !important;
  padding: 4px 16px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  max-width: 100%;
  border: none !important;

  .MuiSvgIcon-root {
    color: ${(props) => color(props.severity)} !important;
  }
  .paragraph {
    color: ${(props) => color(props.severity)} !important;
    align-self: center;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 1.1em;
    font-weight: 500 !important;
    max-width: 600px;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
  .MuiInputLabel-root {
    color: ${(props) => color(props.severity)} !important;
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  color: white !important;

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 50px;
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
`;
export const ReloadButton = styled(IconButton)`
  .MuiSvgIcon-fontSizeMedium {
    width: 40px;
    height: 40px;
  }
`;
export const MoreInfoButton = styled(IconButton)`
  .MuiSvgIcon-fontSizeMedium {
    width: 30px;
    height: 30px !important;
  }
  width: 40px;
  height: 40px !important;

  margin-left: 2% !important;
  margin-top: 10px !important;
  :hover {
    background: white !important;
    color: red !important;
  }
`;

export const DialogStyled = styled(Dialog)`
  .MuiPaper-root {
    width: 60% !important;
    min-height: 20% !important;
  }
`;

export const FormContainer = styled.fieldset`
  width: 100%;
  min-width: 1200px;
  overflow: auto;
  white-space: nowrap;
`;

export const DialogFormContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: flex-start;
  padding: 30px 20px;
  flex-direction: column;

  .MuiFormLabel-root,
  .MuiInputBase-root:disabled,
  .Mui-disabled,
  .MuiInputLabel-root,
  .MuiOutlinedInput-input:disabled,
  .MuiInputBase-input:disabled {
    color: black !important;
  }

  .input {
    margin-left: 20px;
    border: none;
    width: 400px;
  }
  .select-contreparty {
    width: 400px;
    margin: 10px 10px;
  }
`;
