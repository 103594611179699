import { get, post, put } from "../../common/api";
import endpoint from "../../common/api/endpoint";
import _ from 'lodash';

export const submitPositionFxApi = (positionFx, accessToken) =>{
  const params = {
    "quarter" : positionFx.quarter,
    "yearFx" : positionFx.yearFx,
}
    return post(endpoint.POSITIONFX.SUBMIT, positionFx.listPositionFx, params, accessToken);
}

export const fetchtPositionFxApi = (payload,  accessToken) =>{
    const params = {
        "quarter" : payload.quarter,
        "yearFx" : payload.yearFx,
    }
    return get(endpoint.PERIODEFX.GET, params, accessToken);
}

export const updatePositionFxStatusApi = (payload,  accessToken) =>{
    const params = {
        "quarter" : payload.quarter,
        "yearFx" : payload.yearFx,
        "status" : payload?.status,
    }
    return put(endpoint.PERIODEFX.PUT, null,params, accessToken);
}