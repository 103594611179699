

export const selectRefreshTokenStatus = state => state.authentication.refreshingToken.status;
export const selectLoginStatus = state => state.authentication.login.status;
export const selectLoginReason = state => state.authentication.login.reason;
export const selectAccessToken = state => state.authentication.accessToken;
export const selectUserDetails = state => state.authentication.userDetails;


export const selectTokenId = state => state.authentication.tokenId;

export const selectRefreshToken = state => state.authentication.refreshToken;

export const selectChangePasswordStatus = state => state.authentication.changePassword.status;
export const selectChangePasswordError = state => state.authentication.changePassword.error;

