import { postMethod, get, post, put, deleteMethod } from "../../common/api";
import endpoint from "../../common/api/endpoint";
import _ from 'lodash';

export const submitBalanceApi = (balance, accessToken) =>{
    return post(endpoint.BALANCE.SUBMIT, balance, null, accessToken);
}

export const fetchtBalanceApi = (payload,  accessToken) =>{
    const params = {
        "quarter" : payload.quarter,
        "yearBalance" : payload.yearBalance,
        "devise" : payload?.devise,
    }
    return get(endpoint.PERIODE.GET, params, accessToken);
}

export const deleteBalanceApi = (balance, accessToken) =>{
    return deleteMethod(endpoint.BALANCE.DELETE, balance, null, accessToken);
}

export const updateBalanceStatusApi = (payload,  accessToken) =>{
    const params = {
        "quarter" : payload.quarter,
        "yearBalance" : payload.yearBalance,
        "status" : payload?.status,
        "currency" : payload?.currency,
    }
    return put(endpoint.PERIODE.PUT, null,params, accessToken);
}