import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccessToken,
  selectRefreshToken,
  selectShouldLoad,
  selectUserDetails,
} from "../../redux/authentication/authentication.selector";

import {
  loadFromSessionStorage,
  loginAction,
  logoutAction,
  logOutClear,
} from "../../redux/authentication/authentication.slice";
import { selectEligibleDate } from "../../redux/declaration/declaration.selector";
import { calculateEligibleDay } from "../../redux/declaration/declaration.slice";
import { getNotificationAction } from "../../redux/notification/notification.slice";
import { isNullOrUndefined, isTokenExpired } from "../../UtilFunctions";
import SockJsClient from "react-stomp";
import constants from "../constants";
import { selectNotificationStatusApi } from "../../redux/notification/notification.selector";

const SOCKET_URL = "http://localhost:8082/tresodeviseFO/api/ws-message";

export default function StartupHOC({ children }) {
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken);

  const userDetails = useSelector(selectUserDetails);
  const apiCallStatus = useSelector(selectNotificationStatusApi);

  const [cutOffTime, setCutOffTime] = useState(moment());
  const eligibleDate = useSelector(selectEligibleDate);

  /***
   *
   *
   */
  const [message, setMessage] = useState("You server message here.");

  let onConnected = () => {
    console.log("Connected!!");
  };

  let onMessageReceived = (msg) => {
    console.log(msg);
  };

  useEffect(() => {
    if (constants.API_CALL_STATUS.REJECTED == apiCallStatus) {
      dispatch(logOutClear());
      dispatch(logoutAction());
      window.location.reload();
    }
  }, [apiCallStatus]);

  useEffect(() => {
    if (!isNullOrUndefined(userDetails)) {
      setCutOffTime(moment(userDetails?.company?.cutoffTime));
    }
  }, [userDetails]);

  useEffect(() => {
    let timer = null;

    if (false === isNullOrUndefined(accessToken)) {
      if (isTokenExpired(accessToken)) {
        dispatch(logOutClear());
        window.location.reload();
      }

      timer = setInterval(recurrentLogics, 2000);
    }

    return () => clearInterval(timer);
  }, [accessToken, cutOffTime, eligibleDate]);

  const recurrentLogics = () => {
    if (isTokenExpired(accessToken)) {
      dispatch(logOutClear());
      window.location.reload();
    } else {
      dispatch(getNotificationAction());
      dispatch(
        calculateEligibleDay({
          cutOffTime,
          eligibleDate: isNullOrUndefined(eligibleDate)
            ? new Date()
            : eligibleDate,
        })
      );
    }
  };

  return children;
}

/***
 * 
 * 
 * (
    <>   <SockJsClient
        url={SOCKET_URL}
        topics={["/topic/message"]}
        headers={{
          Authorization: `${constants.ATHORIZATION_PREFIX}${accessToken}`,
        }}
        subscribeHeaders={{
          Authorization: `${constants.ATHORIZATION_PREFIX}${accessToken}`,
        }}
        options={{
          sessionId: () => "session-test",
        }}
        onConnect={onConnected}
        onDisconnect={console.log("Disconnected!")}
        onMessage={(msg) => onMessageReceived(msg)}
        debug={true}
      /> 
 * 
 */
