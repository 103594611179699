export const selectSelectedDayStatus = (state) =>
  state.declarations.selectedDeclaration.status;
export const selectSelectedDate = (state) =>
  state.declarations.selectedDeclaration.date;
export const selectAddDeclarationStatus = (state) =>
  state.declarations.addDeclarationStatus;
export const selectDeclarations = (state) => state.declarations.declarations;

export const selectSelectedMonthlyDeclaration = (state) =>
  state.declarations.selectedMonthlyDeclaration;
export const selectMonthlyDeclarations = (state) =>
  state.declarations.monthlyDeclaration;

export const selectEligibleDate = (state) => state.declarations.eligibleDay;
export const selectContreparties = (state) => state.declarations.contreparties;

export const selectPages = (state) => state.declarations.pages;
export const selectRowPerPages = (state) => state.declarations.rowPerPages;
