import "./Login.css";
import logo from "../../assets/logo-bankofafrica-220x50.svg";
import background from "../../assets/logo_accueil_patrimoine1.jpg";
import applogo from "../../assets/logoBOATreasury.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loginAction,
  ChangePasswordAction,
} from "../../redux/authentication/authentication.slice";
import {
  AlertContainer,
  ButtonStyled,
  FormCardContainer,
  FormContainer,
  InputField,
  LogoContainer,
  LogoImg,
  PageContainer,
  AppLogo,
  BackgroundImgContainer,
} from "./login.styles";

import {
  selectAccessToken,
  selectChangePasswordError,
  selectChangePasswordStatus,
  selectLoginReason,
  selectLoginStatus,
} from "../../redux/authentication/authentication.selector";
import LoginIcon from "@mui/icons-material/Login";
import CachedIcon from "@mui/icons-material/Cached";
import CloseIcon from "@mui/icons-material/Close";
import constants from "../../common/constants";
import { Alert, Button, IconButton, Input } from "@mui/material";
import LoadingComponent from "../../common/LoadingComponent";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [resetPwd, setresetPwd] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showError, setShowError] = useState(false);

  const loginStatus = useSelector(selectLoginStatus);
  const accessToken = useSelector(selectAccessToken);
  const rejectReason = useSelector(selectLoginReason);

  const changePasswordError = useSelector(selectChangePasswordError);
  const changePasswordStatus = useSelector(selectChangePasswordStatus);

  // Handshake to check if refresh token is valid

  useEffect(() => {
    if (changePasswordStatus === constants.API_CALL_STATUS.SUCCESS) {
      setresetPwd(false);
    }
  }, [changePasswordStatus]);

  useEffect(() => {
    if (rejectReason !== null) {
      const requiredActions = rejectReason?.message?.split("|");
      if (
        requiredActions?.filter((el) => el === "UPDATE_PASSWORD").length > 0
      ) {
        setShowError(false);
        setresetPwd(true);
      }
    }
  }, [rejectReason]);

  useEffect(() => {
    if (accessToken !== null) {
      navigate(from, { replace: true });
    }
  }, [accessToken]);

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get("username");
    const password = formData.get("password");
    const newPassword = formData.get("newPassword");
    const newPassword2 = formData.get("newPassword2");

    setShowError(true);
    if (resetPwd) {
      dispatch(
        ChangePasswordAction({
          username,
          oldPassword: password,
          newPassword,
          newPassword2,
        })
      );
    } else {
      dispatch(loginAction({ username, password }));
    }

    //  event.target.reset();
  }

  if (
    loginStatus === constants.API_CALL_STATUS.PENDING ||
    changePasswordStatus === constants.API_CALL_STATUS.PENDING
  )
    return <LoadingComponent />;

  return (
    <PageContainer>
      <FormCardContainer>
        <LogoContainer>
          <LogoImg className="appLogo" src={logo} />
        </LogoContainer>
        <FormContainer onSubmit={handleSubmit}>
          <AppLogo>
            <LogoImg className="appLogo" src={applogo} />
          </AppLogo>
          <InputField
            disableUnderline
            placeholder="Login ex: p.nom"
            name="username"
          />
          <InputField
            disableUnderline
            placeholder="Password"
            type={"password"}
            name="password"
          />

          {resetPwd && (
            <>
              <InputField
                disableUnderline
                placeholder="new password"
                type={"password"}
                name="newPassword"
              />
              <InputField
                disableUnderline
                placeholder="Confirm password"
                type={"password"}
                name="newPassword2"
              />
            </>
          )}

          <ButtonStyled
            startIcon={
              resetPwd ? (
                changePasswordStatus === constants.API_CALL_STATUS.PENDING ? (
                  <CachedIcon />
                ) : (
                  <CachedIcon />
                )
              ) : loginStatus === constants.API_CALL_STATUS.PENDING ? (
                <CachedIcon />
              ) : (
                <LoginIcon />
              )
            }
            disabled={
              loginStatus === constants.API_CALL_STATUS.PENDING ||
              changePasswordStatus === constants.API_CALL_STATUS.PENDING
            }
            type={"submit"}
            variant={"outlined"}
          >
            {resetPwd ? "Reset password" : "Sign in"}
          </ButtonStyled>
        </FormContainer>
      </FormCardContainer>
      {showError &&
        (loginStatus === constants.API_CALL_STATUS.REJECTED ||
          changePasswordStatus === constants.API_CALL_STATUS.REJECTED) && (
          <AlertContainer>
            <Alert
              action={
                <IconButton
                  onClick={(__) => setShowError(false)}
                  aria-label="delete"
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity="error"
            >
              {resetPwd
                ? changePasswordError
                : "Invalid credentials, please check your credentials"}
            </Alert>
          </AlertContainer>
        )}

      <BackgroundImgContainer>
        <img src={background} />
      </BackgroundImgContainer>
    </PageContainer>
  );
};

/* 

<div className={'login-page'}>
            <div className={'login-box'}>
                <img src={logo} className={'login-logo'}/>
                <div className={'login-title'}>
                    <h2 style={{color: '#fff', fontWeight: '500'}}>Treasury currency</h2>
                </div>
                <form className={'form-container'} onSubmit={handleSubmit} style={{textAlign: 'center'}}>
                    <Input placeholder="E-mail" className={'login-user'} name="username"/>
                    <Input placeholder="Password" className={'login-user'} type={'password'}/>
                    <Button  type={'submit'} variant={'outlined'} style={{color: 'white'}}>Sign in</Button>
                </form> 
                <div style={{color: 'red'}}>User ou Mot de passe erroné</div> 
            </div>
        </div>
*/
