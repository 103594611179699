import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { currencies } from "../declarations/declarations.slice";
import { deleteBalanceApi, fetchtBalanceApi, submitBalanceApi, updateBalanceStatusApi } from "./balance.api";

const SLICE_NAME = "balance";

export const initialState = {
  currency: currencies.USD,
  entries: [],
};
export const getBalanceAction = createAsyncThunk(
  "periode/by-period",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await fetchtBalanceApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response?.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);
export const submitBalanceAction = createAsyncThunk(
  "balance/submit-balance",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await submitBalanceApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);
export const deleteBalanceAction = createAsyncThunk(
  "balance/delete-balance",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await deleteBalanceApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);
export const updateBalanceStatusAction = createAsyncThunk(
  "periode/change-status",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await updateBalanceStatusApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

const balance = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setEntries: (state, { payload }) => {
      state.entries = payload;
    },
    addEntries: (state, { payload }) => {
      state.entries.push(...payload);
    },
    deleteEntries: (state, { payload }) => {
      state.entries = state.entries.filter(({ id }) => !payload.includes(id));
    },
    setCurrency: (state, { payload }) => {
      state.currency = payload;
    },
  },
});

export const { addEntries, deleteEntries, setEntries, setCurrency } =
  balance.actions;

export default balance.reducer;
