import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteBondPortfolioApi, fetchBondPortfolioApi, submitBondPortfolioApi, updateBondPortfolioStatusApi } from "./bond-portfolio.api";

const SLICE_NAME = "bondPortfolio";

const initialState = () => ({
});

export const getBondPortfolioAction = createAsyncThunk(
  "periodeBP/by-period",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await fetchBondPortfolioApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response?.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);
export const submitBondPortfolioAction = createAsyncThunk(
  "bond-portfolio/submit-bp",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await submitBondPortfolioApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);
export const updateBondPortfolioStatusAction = createAsyncThunk(
  "periodeBP/change-status",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await updateBondPortfolioStatusApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);
export const deleteBondPortfolioAction = createAsyncThunk(
  "bond-portfolio/delete-bp",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await deleteBondPortfolioApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);
const bondPortfolio = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
  }
});

export default bondPortfolio.reducer;
