import "./App.css";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import * as React from "react";
import LoginPage from "./pages/Login";

import GuardHoc from "./common/hoc/Guard.hoc";
import Dashboard from "./pages/Dashboard";

export default function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        {/*<Route path="/" element={<LoginPage />} />*/}
        <Route
          path="/"
          element={
            <GuardHoc>
              <Dashboard />
            </GuardHoc>
          }
        />
        <Route path="/login" element={<LoginPage />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <div className={"root-div"}>
      <Outlet />
    </div>
  );
}

/* function AuthStatus() {
    let user = localStorage.getItem('user')
    let accessToken = useSelector(selectAccessToken);
    let navigate = useNavigate();

    if (!user) {
        // return <p>You are not logged in.</p>;
    }
    if (user) {
        return (
        <header className={'header-style'}>
            <img src={logob} className={'img-logo'}/>
            <Button htmlType="submit" size={'small'} variant={'outlined'} className={'button-logout'}
                    onClick={() => {auth.signout(() => navigate("/"));}}>Sign out</Button>
            <span className={'welcome'}>Welcome {user}! {" "}</span>
        </header>
        );
    }

} */
