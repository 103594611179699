import { get, post } from "../../common/api";
import endpoint from "../../common/api/endpoint";

export const refreshTokenApi = (refreshToken, lastTokenId) => {
  return post(
    endpoint.AUTH.REFRESH_TOKEN,
    { refreshToken },
    { "token-id": lastTokenId }
  );
};
export const loginApi = (username, password) => {
  return post(endpoint.AUTH.LOGIN, { username, password });
};

export const changePasswordApi = (username, oldPassword, newPassword) => {
  return post(endpoint.AUTH.CHANGE_PASSWORD, {
    username,
    oldPassword,
    newPassword,
  });
};

export const logoutApi = (accessToken) => {
  return post(endpoint.AUTH.LOGOUT, null, null, accessToken);
};
