import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";

import BookmarkIcon from "@mui/icons-material/Bookmark";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import SendIcon from "@mui/icons-material/Send";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomButton,
  DetailContainer,
  DetailWrapper,
  StyledDataGrid,
  SubmitButton,
} from "./riskindicators.styles";

import { MainWrapper, StyledTitle } from "./riskindicators.styles";

import { CustomSelect, CustomTextField } from "./riskindicators.styles";

import MenuItem from "@mui/material/MenuItem";
import { useMemo } from "react";
import {
  useGetFxEntriesQuery,
  usePutFxEntriesMutation,
  useSubmitFxEntriesMutation,
} from "../../redux/declarations/declarations.api";
import { selectFxMutations } from "../../redux/declarations/declarations.selector";
import { currencies } from "../../redux/declarations/declarations.slice";
import {
  addMutation,
  getPositionFxAction,
  setAssetMutation,
  setLiabilityMutation,
  submitPositionFxAction,
  updatePositionFxStatusAction,
} from "../../redux/fx-position/fx-positions.slice";
import { unwrapResult } from "@reduxjs/toolkit";
import constants from "../../common/constants";

export function AssetLiabilityTable({ term, quarterData, selectedYear, updateStatusState, reload, onSetReasonReject }) {
  const dispatch = useDispatch();
  //const mutations = useSelector(selectFxMutations);
  const { data: currentData, error, isLoading } = useGetFxEntriesQuery();
  const [updateEntries, updateStatus] = usePutFxEntriesMutation();
  const [submitEntries, submitStatus] = useSubmitFxEntriesMutation();
  const [mutations, setMutations] = useState({
    CAD: [null, 0],
    CHF: [0, null],
    EUR: [null, 0],
    GBP: [0, null],
    JPY: [null, 0],
    MAD: [0, null],
    TND: [null, 0],
    USD: [0, null],
    XAF: [null, 0],
    XOF: [0, null],
  });

  const [dialog, setDialog] = useState(false);
  const gridApiRef = useRef();
  const [showButton, setShowButton] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    setMutations({
      CAD: [null, 0],
      CHF: [0, null],
      EUR: [null, 0],
      GBP: [0, null],
      JPY: [null, 0],
      MAD: [0, null],
      TND: [null, 0],
      USD: [0, null],
      XAF: [null, 0],
      XOF: [0, null],
    });
    dispatch(getPositionFxAction({ yearFx: selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)) }))
      .then(unwrapResult)
      .then((resp) => {
        if (resp?.positionsFx?.length > 0) {
          const resultObject = Object.fromEntries(
            resp?.positionsFx?.map(({ currency }) => [currency, [null, null]])
          );
          resp?.positionsFx?.forEach(({ currency, assets, liabilities }) => {
            if (assets !== undefined) {
              resultObject[currency][0] = assets;
            }
            if (liabilities !== undefined) {
              resultObject[currency][1] = liabilities;
            }
          });
          setMutations(resultObject);
          setShowButton(resp?.status === "ACCEPTED");
        }
        updateStatusState(resp?.status ? resp?.status : "DRAFT");
        setIsLoadingData(false);
        onSetReasonReject(resp?.rejectReason);
      });
  }, []);

  useEffect(() => {
    setMutations({
      CAD: [null, 0],
      CHF: [0, null],
      EUR: [null, 0],
      GBP: [0, null],
      JPY: [null, 0],
      MAD: [0, null],
      TND: [null, 0],
      USD: [0, null],
      XAF: [null, 0],
      XOF: [0, null],
    });
    setShowButton(false);
    setIsLoadingData(true);
    dispatch(getPositionFxAction({ yearFx: selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)) }))
      .then(unwrapResult)
      .then((resp) => {
        if (resp?.positionsFx?.length > 0) {
          const resultObject = Object.fromEntries(
            resp?.positionsFx?.map(({ currency }) => [currency, [null, null]])
          );
          resp?.positionsFx?.forEach(({ currency, assets, liabilities }) => {
            if (assets !== undefined) {
              resultObject[currency][0] = assets;
            }
            if (liabilities !== undefined) {
              resultObject[currency][1] = liabilities;
            }
          });
          setMutations(resultObject);
          setShowButton(resp?.status === "ACCEPTED");
        }
        updateStatusState(resp?.status ? resp?.status : "DRAFT");
        setIsLoadingData(false);
        onSetReasonReject(resp?.rejectReason);
      });
  }, [quarterData, selectedYear, reload]);

  function handleChangeAsset(e) {
    dispatch(setAssetMutation({ [e.target.name]: +e.target.value }));
  }
  function handleChangeLiability(e) {
    dispatch(setLiabilityMutation({ [e.target.name]: +e.target.value }));
  }

  const handleOnSave = () => {
    const entries = [...gridApiRef.current.getRowModels().values()];
    dispatch(submitPositionFxAction({ listPositionFx: entries, yearFx: selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)) }))
      .then(unwrapResult)
      .then((resp) => {
      });
  };

  const handleOnSubmit = () => {
    setIsLoadingData(true);
    /*     dispatch(updatePositionFxStatusAction({yearFx : selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)), status:constants.PROFITABILITY.STATUS.SUBMITTED }))
        .then(unwrapResult)
        .then((resp) => {
          setDialog(false);
          setShowButton(resp?.status === "SUBMITTED" || resp?.status === "REFUSED" || resp?.status === "ACCEPTED");
          setIsLoadingData(false);
          updateStatusState(resp?.status ? resp?.status : "DRAFT");
        });  */
    const entries = [...gridApiRef.current.getRowModels().values()];
    dispatch(submitPositionFxAction({ listPositionFx: entries, yearFx: selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)) }))
      .then(unwrapResult)
      .then((resp) => {
        setDialog(false);
        setIsLoadingData(false);
        if (resp?.length > 0) {
          const resultObject = Object.fromEntries(
            resp?.map(({ currency }) => [currency, [null, null]])
          );
          resp?.forEach(({ currency, assets, liabilities }) => {
            if (assets !== undefined) {
              resultObject[currency][0] = assets;
            }
            if (liabilities !== undefined) {
              resultObject[currency][1] = liabilities;
            }
          });
          setMutations(resultObject);
        }
      });
  };

  useEffect(() => {
    if (currentData) dispatch(addMutation(currentData));
  }, [currentData, dispatch]);

  const columns = useMemo(
    () => [
      {
        field: "currency",
        headerName: "Currency",
        bgColor: "transparent",
        type: "string",
        minWidth: 120,
        headerClassName: "grid--currency-header",
      },
      {
        field: "assets",
        headerName: "Assets",
        headerAlign: "right",
        editable: true,
        type: "number",
        minWidth: 170,
        cellClassName: ({ value }) =>
          value === null ? "grid--cell-empty" : "grid--cell-entry",
        // valueParser: (value) => (value.length ? +value : null),
      },
      {
        field: "liabilities",
        headerName: "Liabilities",
        headerAlign: "right",
        editable: true,
        type: "number",
        minWidth: 170,
        cellClassName: ({ value }) =>
          value === null ? "grid--cell-empty" : "grid--cell-entry",
        // valueParser: (value) => (value.length ? +value : null),
      },
    ],
    []
  );

  const rows = useMemo(
    () =>
      Object.entries(mutations).map(([currency, values]) => ({
        currency,
        [Object.keys(categories)[0]]: values[0],
        [Object.keys(categories)[1]]: values[1],
      })),
    [mutations]
  );

  function updateRow(newRow, oldRow) {
    for (const field in oldRow) {
      if (oldRow[field] === null && newRow[field] !== null) {
        const otherField = Object.keys(categories).find((f) => f !== field);
        newRow[otherField] = null;
        break;
      }
    }
    return newRow;
  }

  if (!isLoadingData)
    return (
      <>
        <DetailContainer>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            columnHeaderHeight={40}
            hideFooter
            showCellVerticalBorder
            showColumnVerticalBorder
            rowHeight={45}
            disableRowSelectionOnClick
            getRowId={({ currency }) => currency}
            // onCellEditStop={onUpdateEntry}
            processRowUpdate={updateRow}
          />
          <div style={{ textAlign: "center" }}>
            {showButton ? null :
              <>
                {/*           <CustomButton onClick={handleOnSave}>
            {updateStatus.isLoading ? <BookmarkIcon /> : <TurnedInNotIcon />}
            Save
          </CustomButton> */}
                <Dialog
                  open={dialog || submitStatus.isLoading}
                  onClose={() => setDialog(false)}
                >
                  <DialogTitle>
                    Submit <strong>FX Positions</strong> declaration ?
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        handleOnSubmit();
                        setDialog(false);
                      }}
                      sx={{
                        bgcolor: "primary.main",
                        color: "white",
                        ":hover": {
                          color: "primary.main",
                        },
                      }}
                    >
                      {submitStatus.isLoading ? <ScheduleSendIcon /> : <SendIcon />}
                      &nbsp; YES
                    </Button>
                  </DialogActions>
                </Dialog>

                <SubmitButton
                  variant="contained"
                  onClick={() => setDialog(true)}
                >
                  {submitStatus.isLoading ? <ScheduleSendIcon /> : <SendIcon />}
                  Submit
                </SubmitButton>
              </>
            }
          </div>
        </DetailContainer>
      </>
    );
}

function BondsTable({ term }) {
  return (
    <div>
      <DetailContainer>
        <DetailWrapper>
          <span>Bonds Portfolio</span>
          <div className="bloc">
            {term != "fxPositions" &&
              bondsPositions.map((asset) => {
                return (
                  asset.title !== null && (
                    <div key={`asset---${asset.title}`}>
                      <span>{asset.title}</span>
                      <Input
                        name={asset.title}
                      ></Input>
                    </div>
                  )
                );
              })}
            {term == "fxPositions" &&
              fxPositions.map((asset) => {
                return (
                  asset.title !== null && (
                    <div key={`asset---${asset.title}`}>
                      <span>{asset.title}</span>
                      <Input
                        name={asset.title}
                      ></Input>
                    </div>
                  )
                );
              })}
          </div>
          <div className="total">
            TOTAL LIABILITIES:<span></span>
          </div>
        </DetailWrapper>
      </DetailContainer>
    </div>
  );
}

function SubMenu4() {
  return (
    <MainWrapper>
      <StyledTitle>{/* <h1>Bonds Positions</h1> */}</StyledTitle>
      {/* <StyledDiv> */}

      {/* {<BondsTable term="bondsPositions" />} */}

      {/* <ColumnGroupingTable /> */}

      {/*   </StyledDiv> */}
    </MainWrapper>
  );
}

export const PopUpFormModel = ({
  form,
  open,
  onSubmit,
  onCancel,
  title,
  nameField = "name",
  titleField = "title",
  compact = false,
  defaultData = {},
}) => {
  const [errors, setErrors] = useState({});
  const [selectedOption, setSelectedOption] = useState('fixed');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Object.keys(errors).length) {
      const data = Object.fromEntries(
        Object.entries(e.target.elements)
          .filter(([key]) => /^[a-zA-Z].*$/.test(key))
          .map(([key, elem]) => [key, elem.value])
      );

      onSubmit(data);
    }
  };

  function handleValidation(e) {
    const { name, value } = e.target;
    if (validations[name] && !validations[name](value)) {
      setErrors((prev) => ({
        ...prev,
        [name]: `Invalid input for ${name}.`,
      }));
    } else {
      setErrors((prev) => {
        const newState = { ...prev };
        delete newState[name];
        return newState;
      });
    }
  }

  const validations = /bond/i.test(title)
    ? {}
    : {
      amount: (value) => /^\d+(\.\d+)?$/.test(value), // Numeric check
      duration: (value) => /^\d+$/.test(value), // Integer check
      currency: (value) => ["EUR", "USD"].includes(value), // Should be either 'EUR' or 'USD'
      heading: (value) =>
        typeof value === "string" && value.trim().length > 0, // String check
      maturity: (value) =>
        ["Long term", "Short term", "Medium term"].includes(value), // Should be one of the specified terms
      };
  return (
    // <DetailContainer>
    <DetailWrapper>
      <span>{title}</span>
      <form onSubmit={handleSubmit}>
        <div className={`bloc${compact ? " compact" : ""}`}>
          {form.map((entry, index) => {
            return (
              entry[titleField] !== null && (
                <div key={`entry---${entry[titleField]}-${index}`}>
                  <InputLabel id={entry[titleField]}>
                    {entry[titleField]}
                  </InputLabel>
                  {/currency|maturity|category/i.test(entry[nameField]) &&
                    !/bond/i.test(title) ? (
                    <CustomSelect
                      required
                      disabled
                      labelId={entry[titleField]}
                      label={entry[titleField]}
                      onChange={handleValidation}
                      // error={Boolean(errors[entry[titleField]])}
                      name={entry[nameField]}
                      variant="standard"
                      defaultValue={defaultData[entry[nameField]]}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {Object.entries(entry.options).map(([value, label]) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  ) : entry[titleField] === "Average rate" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <RowRadioButtonsGroup
                        groupName={`${entry[nameField]}Type`}
                        radioProps={Object.entries(entry.options).map(
                          ([name, label]) => ({
                            label,
                            value: name,
                            checked: selectedOption === name, 
                            onChange: handleRadioChange, 
                          })
                        )}
                      />
                      <CustomTextField
                        required={selectedOption === 'fixed'} 
                        name={entry[nameField]}
                        helperText={errors[entry[nameField]] ?? " "}
                        variant="standard"
                        aria-label="Rate"
                        InputLabelProps={{
                          sx: {
                            textAlign: "end",
                            fontSize: ".75em",
                            transform: "none",
                            right: 0,
                            bottom: 0,
                            top: ".4em",
                            left: "auto",
                          },
                        }}
                        />
                    </div>
                  ) : /date/i.test(entry[titleField]) ? (
                    <Input
                      type="date"
                      name={entry[nameField]}
                      sx={{
                        ".MuiInputBase-input.MuiInput-input": {
                          padding: 1,
                          minWidth: "15ch",
                          width: "auto",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  ) : entry[titleField] === "Portfolio type" ? (
                    <CustomSelect
                      required
                      labelId={entry[titleField]}
                      label={entry[titleField]}
                      onChange={handleValidation}
                      name={entry[nameField]}
                      variant="standard"
                      defaultValue={defaultData[entry[nameField]]}
                      sx={{ textTransform: "capitalize" }}
                    >
                      <MenuItem value="HTM">HTM</MenuItem>
                      <MenuItem value="AFS">AFS</MenuItem>
                    </CustomSelect>
                  ) : (
                    <CustomTextField
                      required
                      name={entry[nameField]}
                      onChange={handleValidation}
                      // error={Boolean(errors[entry[titleField]])}
                      helperText={errors[entry[nameField]] ?? " "}
                      variant="standard"
                      defaultValue={defaultData[entry[nameField]]}
                      type={
                        /duration|amount|price|ytm/i.test(entry[titleField])
                          ? "number"
                          : "text"
                      }
                      inputProps={{
                        sx: /Heading/i.test(entry[titleField])
                          ? {
                            "&.MuiInput-input.MuiInputBase-input": {
                              width: "11em",
                              marginRight: "1.75em",
                            },
                          }
                          : null,
                        step: !/duration/i.test(entry[titleField])
                          ? "0.01"
                          : "1",
                        min: 0,
                      }}
                    />
                  )}
                </div>
              )
            );
          })}
        </div>

        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <CustomButton
            style={{
              color: "#25bbe6",
              borderColor: "#25bbe6",
            }}
            variant="outlined"
            type="submit"
          >
            {open ? <BookmarkIcon /> : <TurnedInNotIcon />}
            Save
          </CustomButton>
          <CustomButton
            style={{
              color: "#25bbe6",
              borderColor: "#25bbe6",
            }}
            variant="outlined"
            onClick={onCancel}
            type="button"
          >
            {open ? <BookmarkIcon /> : <TurnedInNotIcon />}
            Cancel
          </CustomButton>
        </div>
      </form>
    </DetailWrapper>
    // </DetailContainer>
  );
};

export const maturities = {
  short: "Short term",
  medium: "Medium term",
  long: "Long term",
};
export const categories = { assets: "Assets", liabilities: "Liabilities" };
export const usdBalance = [
  {
    title: "Average duration (in days)",
    name: "duration",
  },

  {
    title: "Heading",
    name: "heading",
  },
  {
    title: "Average rate",
    name: "rate",
    options: { fixed: "Fixed", floating: "Floating" },
  },
  {
    title: "Amount",
    name: "amount",
  },
  {
    title: "Maturity",
    name: "maturity",
    options: maturities,
  },
  {
    title: "Category",
    name: "category",
    options: categories,
  },
  {
    title: "Currency",
    name: "currency",
    options: currencies,
  },
].reverse();

const fxPositions = [
  {
    title: "EUR",
  },
  {
    title: "USD",
  },
  {
    title: "GBP",
  },
  {
    title: "CHF",
  },
  {
    title: "JPY",
  },
  {
    title: "CAD",
  },
  {
    title: "XOF",
  },
  {
    title: "XAF",
  },
  {
    title: "MAD",
  },
  {
    title: "TND",
  },
  {
    title: "CNY",
  },
];

export const bondsPositions = [
  { title: "ISIN", name: "ISIN", minWidth: 80 },
  { title: "ISSUER", name: "ISSUER", minWidth: 80 },
  { title: "COUNTRY", name: "COUNTRY", minWidth: 80 },
  { title: "CURRENCY", name: "CURRENCY", minWidth: 80 },
  { title: "TRANSACTION DATE", name: "TRANSACTION DATE", minWidth: 80 },
  { title: "VALUE DATE", name: "VALUE DATE", minWidth: 80 },
  { title: "MATURITY DATE", name: "MATURITY DATE", minWidth: 80 },
  { title: "FACE AMOUNT", name: "FACE AMOUNT", minWidth: 80 },
  { title: "CLEAN PRICE", name: "CLEAN PRICE", minWidth: 80 },
  { title: "YTM", name: "YTM", minWidth: 80 },
  { title: "PORTFOLIO TYPE", name: "PORTFOLIO TYPE", minWidth: 80 },
];

export function ButtonDeletionDialog({
  onConfirm,
  onClick,
  canOpen,
  ...otherProps
}) {
  const [open, setOpen] = React.useState(false);
  const fullScreen = useMediaQuery("(max-width:540px)");

  const handleClickOpen = (e) => {
    onClick?.(e);
    canOpen && setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = (e) => {
    onConfirm?.(e);
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen} {...otherProps} />
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="Confirm Deletion"
      >
        <DialogTitle
          color="white"
          id="Confirm Deletion"
          bgcolor="#d32f2fce"
          textAlign="center"
          padding={1}
        >
          Alert
        </DialogTitle>
        <DialogContent>
          <DialogContentText marginTop={2}>
            Are sure you want to delete the selected rows ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="error" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const RowRadioButtonsGroup = ({
  groupName,
  groupLabel,
  radioProps = [],
}) => (
  <FormControl
    required
    sx={{
      ".MuiFormLabel-asterisk": {
        display: "none",
      },
    }}
  >
    <FormLabel id={groupLabel} sx={{ fontSize: ".75rem" }}>
      {groupLabel}
    </FormLabel>
    <RadioGroup row aria-labelledby={groupLabel} name={groupName}>
      {radioProps.map(({ label, value, ...otherProps }) => (
        <FormControlLabel
          key={label}
          value={value}
          control={<Radio />}
          label={label}
          sx={{
            margin: "0 0 0 1rem",
            "& .MuiFormControlLabel-label": {
              fontSize: ".8rem",
            },
          }}
          {...otherProps}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export function NoRowsOverlay() {
  return (
    <div>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </div>
  );
}
