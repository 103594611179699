import { createDraftSafeSelector as createSelector } from "@reduxjs/toolkit";
import { categories } from "../../pages/riskIndicators";

export const selectEntries = (state) => state.balance.entries;
export const selectCurrency = (state) => state.balance.currency;

export const selectAssets = createSelector(selectEntries, (entries) =>
  entries.filter((e) => e.category === categories.assets)
);

export const selectLiabilities = createSelector(selectEntries, (entries) =>
  entries.filter((e) => e.category === categories.liabilities)
);
