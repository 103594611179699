import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import constants from "../../common/constants";
import { not } from "../../common/utils";
import {
  changeStatuskpiApi,
  fetchtKpiApi,
  submitkpiApi,
} from "../../redux/kpi/kpi.api";
import _groupBy from "lodash/groupBy";
import { dateToString } from "../../UtilFunctions";
import moment from "moment";

export const initialState = {
  submitKpiStatus: null,

};


export const submitKpiAction = createAsyncThunk(
  "kpi/submit-kpi",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();
    try {
      const response = await submitkpiApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data); 
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const changeStatusKpiAction = createAsyncThunk(
  "kpi/submit-status",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
       const response = await changeStatuskpiApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data); 
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);


export const fetchKpiAction = createAsyncThunk(
  "kpi/by-period",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();
    try {
       const response = await fetchtKpiApi(
        payload,
        accessToken,
      );

      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data); 
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

const kpi = createSlice({
  name: "kpi",
  initialState,
  reducers: {

  },
  extraReducers: {
   
    /*** submitDeclarationsAction */
    [submitKpiAction.pending]: (state) => {
      state.submitKpiStatus = constants.API_CALL_STATUS.PENDING;
    },
    [submitKpiAction.fulfilled]: (state, { payload }) => {
      state.submitKpiStatus = constants.API_CALL_STATUS.SUCCESS;
    },
    [submitKpiAction.rejected]: (state, __) => {
      state.submitKpiStatus = constants.API_CALL_STATUS.REJECTED;
    },
    /*** submitDeclarationsAction */
   
  },
});


/* export const {
  changeDeclareDate,
  changeMonthlyDeclarationDate,
  calculateEligibleDay,
  pages,
  rowPerPages,
} = declarations.actions; */

export default kpi.reducer;
