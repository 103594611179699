import {
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logob from "../../assets/logo-bk-220x50.svg";
import logoBOA from "../../assets/logo-bankofafrica-220x50.svg";
import logoBKT from "../../assets/logoBOATreasury.svg";
import HelpIcon from "@mui/icons-material/HelpOutline";
import {
  selectAccessToken,
  selectUserDetails,
} from "../../redux/authentication/authentication.selector";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import {
  logoutAction,
  logOutClear,
} from "../../redux/authentication/authentication.slice";
import Logout from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  selectNotifications,
  selectUnSeenNotificationCount,
} from "../../redux/notification/notification.selector";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import styledComponents, { keyframes } from "styled-components";
import { formatTime, isNullOrUndefined } from "../../UtilFunctions";
import { not } from "../../common/utils";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MenuIconMat from "@mui/icons-material/Menu";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";
import { selectEligibleDate } from "../../redux/declaration/declaration.selector";
import moment from "moment";
export default function NavBar({ onSeeNotification, showSider, setShowSider }) {
  const accessToken = useSelector(selectAccessToken);
  const userDetails = useSelector(selectUserDetails);
  const {
    company: { cutoffTime },
  } = userDetails;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const eligibleDate = useSelector(selectEligibleDate);

  const matches = useMediaQuery("(min-width:720px)");
  const matches600 = useMediaQuery("(max-width:600px)");
  const matches1150 = useMediaQuery("(max-width:1150px)");

  if (accessToken !== null) {
    const isEligibleDay = () => {
      return (
        moment(eligibleDate).isValid() &&
        moment(eligibleDate).isSame(moment(), "day")
      );
    };

    const downlaodDoc = (event) => {};

    return (
      <NavbarContainer>
        <WidgetContainer>
          {!matches600 && (
            <>
              <LogoBmcek className="capital-app" src={logob} />
              <LogoBmcek className="logo-app" src={logoBKT} />
            </>
          )}
          {matches600 && (
            <IconButton onClick={() => setShowSider((old) => !old)}>
              {showSider ? (
                <CloseIcon sx={{ width: 34, height: 34, color: "white" }} />
              ) : (
                <MenuIconStyled sx={{ width: 34, height: 34 }} />
              )}
            </IconButton>
          )}
        </WidgetContainer>
        <WidgetContainer>
          {/*  {matches600 && <LogoBmcek classNaùe="logo-mobile" src={logoBKT} />} */}
          <CutOffTime
            breath={!isEligibleDay()}
            color={isEligibleDay() ? "#0ab4aa" : "#ec605b"}
          >
            {isEligibleDay() ? (
              <span>
                <LockOpenIcon fontSize="small" />
                {formatTime(cutoffTime)}
              </span>
            ) : (
              <span className="palteform-closed">
                <LockIcon fontSize="small" />
                Plateform is closed
              </span>
            )}
          </CutOffTime>
        </WidgetContainer>
        <WidgetContainer className="last-widget">
          {!matches600 && (
            <LogoBmcek style={{ width: "50%", height: "44%" }} src={logoBOA} />
          )}
          <div></div>
          <MenuContainer>
            <NotificationMenu
              matches={matches600}
              onClick={() => setShowSider(false)}
              onSeeNotification={onSeeNotification}
            />
            {!matches600 && (
              <span style={{ color: "white", margin: "0px 5px" }}>|</span>
            )}
            <a
              style={{ textDecoration: "none" }}
              download
              target="_blank"
              href={`${process.env.PUBLIC_URL}/Document_fonctionnel_BK_treso.pdf`}
              rel="noreferrer"
            >
              <IconButtonStyled size="small" type="button">
                <HelpIcon sx={{ width: 17, height: 17, mr: "5px" }}></HelpIcon>
                {!matches600 && <span>{"User guide".toUpperCase()}</span>}
              </IconButtonStyled>
            </a>

            {!matches600 && (
              <span style={{ color: "white", margin: "0px 5px" }}>|</span>
            )}

            {/*   <UserMenu
              username={userDetails.fullname}
              onClick={() => setShowSider(false)}
              onLogout={() => {
                window.location.reload();
                dispatch(logoutAction());
                dispatch(logOutClear());
              }}
            /> */}

            <UserMenu
              matches={matches600}
              matches1={matches1150}
              matches1150
              onLogout={() => {
                window.location.reload();
                dispatch(logoutAction());
                dispatch(logOutClear());
              }}
            />
          </MenuContainer>
        </WidgetContainer>
      </NavbarContainer>
    );
  }

  return null;
}

const NotificationMenu = ({ matches, onSeeNotification, onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const notificationsCount = useSelector(selectUnSeenNotificationCount);
  const notifications = useSelector(selectNotifications);
  const matches450 = useMediaQuery("(max-width:480px)");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onClick();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <MenuIconNotif sx={{ float: "right" }}>
        <IconButtonStyled
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <StyledBadge badgeContent={notificationsCount}>
            <NotifIcon
              notificationscount={`${notificationsCount}`}
              sx={{ width: 17, height: 17, mr: "5px" }}
            ></NotifIcon>
            {!matches && "Notifications".toUpperCase()}
          </StyledBadge>
        </IconButtonStyled>
      </MenuIconNotif>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: matches450 ? 80 : 15,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <NotificationsList
          notifications={notifications}
          onSeeNotification={onSeeNotification}
        />
      </Menu>
    </React.Fragment>
  );
};

const UserMenu = ({ matches, onLogout, matches1 }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const userDetails = useSelector(selectUserDetails);
  const handleLogout = () => {
    onLogout();
    setOpenConfirmation(false);
  };

  return (
    <>
      {/* <IconButtonStyled size="small" onClick={() => setOpenConfirmation(true)}>
        <ManageAccountsIcon
          sx={{ width: 17, height: 17, mr: "5px" }}
        ></ManageAccountsIcon>
        {!matches && <span>{` ${userDetails?.fullname} `} </span>}
      </IconButtonStyled>
      {!matches && <span style={{ color: "white", margin: "0px 5px" }}>|</span>} */}

      <IconButtonStyled size="small" onClick={() => setOpenConfirmation(true)}>
        <PowerSettingsNewIcon
          sx={{ width: 17, height: 17, mr: "5px" }}
        ></PowerSettingsNewIcon>

        {matches1 ? (
          !matches ? (
            <Tooltip title={`${userDetails?.fullname}`}>
              <span>{`LOGOUT`} </span>
            </Tooltip>
          ) : null
        ) : (
          <span>{`LOGOUT ( ${userDetails?.fullname} )`} </span>
        )}
      </IconButtonStyled>
      <AlertDialog
        fullname={userDetails?.fullname}
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        onlogout={handleLogout}
      />
    </>
  );
};

const AlertDialog = ({ open, setOpen, onlogout, fullname }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{ height: 320 }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ height: 400, padding: 5, textAlign: "center" }}>
        <WarningAmberIcon
          sx={{ fontSize: "4em", marginBottom: "20px" }}
          color="warning"
        />
        <DialogContentText id="alert-dialog-description">
          {fullname}, Are you sure you want to exit ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>No</Button>
        <Button onClick={onlogout} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NotificationsList = ({ notifications, onSeeNotification }) => {
  return (
    <NotifMainContainer>
      {notifications?.map((el) => (
        <NotificationContainer
          key={`notifications--${el.id}`}
          visited={not(isNullOrUndefined(el.lastSeen))}
        >
          <MenuItem onClick={(__) => onSeeNotification(el)}>
            {el.subject === "QUOTATION" ? (
              <>
                <RequestQuoteIcon color="primary" />
                <NotificationText>{el.message}</NotificationText>
              </>
            ) : el.declarationStatus !== "ACCEPTED" ? (
              <>
                <BlockIcon color="error" />
                <NotificationText>
                  {` The ${el.declarationType} cash requirement of the date : ${el.declarationDate} is rejected`}
                </NotificationText>
              </>
            ) : (
              <>
                <CheckIcon color="success" />
                <NotificationText>
                  {` The ${el.declarationType} cash requirement of the date : ${el.declarationDate} is accepted`}
                </NotificationText>
              </>
            )}
          </MenuItem>
        </NotificationContainer>
      ))}
    </NotifMainContainer>
  );
};

const breathAnimation = (color) => keyframes`
    0% { color: ${color};  } 
    50% { color: #00294d; } 
    100% { color:  ${color};  }
`;

const NavbarContainer = styledComponents.div`
  height:90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px; 

  .last-widget{
    align-items: flex-end;
    flex:1;

    &>img{ 
      display: flex;
      max-width : 250px;
      max-height: 45px;
      position: absolute;
      right:0;
      top:0;
      display: block !important;
    }
  }

  
`;

const CutOffTime = styledComponents.div`
  z-index: 900;
 
  position: absolute;
  top : 0;
  left : 0;
  right: 0;
  margin : 10px auto;
  text-align: center;
  letter-spacing: 0.25em; 
  font-size :0.65em;

  
 
  @media (max-width: 600px) {
    display: none;
  }

  &>span{
    
    padding : 5px 25px 10px 25px;
    color: ${({ color }) => color}; 

    @media (max-width: 760px) {
      padding : 5px 2px 10px 2px;
    }

    animation-name: ${({ color, breath }) =>
      breath ? breathAnimation(color) : null};
    animation-duration: 4s;
    animation-iteration-count: infinite; 

    &>.MuiSvgIcon-root{
      margin-right: 5px;
      font-size : 1.2em;
    }

  }
 
  .palteform-closed{  
    
  }
`;

const WidgetContainer = styledComponents.div`
 
  display : flex; 
  width : 30%; 
  flex-direction: column;
  justify-content: space-between; 
  
  @media (max-width: 600px) { 
    justify-content: center;
  }

  .logo-app{
    border: 1px solid white;
    height: 35%;
    width : 115px;
    border-radius : 10px 0px;
    background: white;
    margin : 0px 0px 10px 40px;
  }

  .capital-app{
    transform: scale(1.8, 1.5) translateX(30px); 
  }
 
  .logo-mobile{
    padding: 0px !important;
    width: 80px;
    height: 60px;
    border: 1px solid red;
  }
 

`;

const MenuContainer = styledComponents.div`
  display : flex;  
  z-index: 1000;
  padding-bottom: 5px;
  margin : 0px 20px 0px 0px; 
`;

const LogoBmcek = styledComponents.img` 
  width: 100px;
  height: 50%;
  
  z-index:1000;  
 
  @media (max-width: 720px) { 
    width: 60px;
    height:  50%;
    padding:10px;
  }

`;

const MenuIconStyled = styledComponents(MenuIconMat)`
 
  color:white;
   
`;

const UserMenuIcon = styledComponents(Avatar)`
  background: white !important; 
  color: #00294d !important;
  border : ${({ notificationscount }) =>
    notificationscount > 0 ? "2px solid red" : "none"} ; 
  ${({ notificationscount }) => {
    return notificationscount > 0
      ? `
    animation-name: breath-animation;
    animation-duration: 4s;
    animation-iteration-count: infinite;


    @keyframes breath-animation {
      0% { border-color: rgb(244, 160, 160);   opacity:0.7 }
      25% { border-color: rgb(243, 127, 127); }
      50% { border-color: #f84141; opacity:1  }
      75% { border-color: rgb(243, 127, 127);   }
      100% { border-color: rgb(244, 160, 160); opacity:0.7 }
   }
    `
      : null;
  }} ;  
`;

const NotifIcon = styledComponents(NotificationsIcon)`
  
  color: white!important;
  border-radius: 50px; 
/*   border : ${({ notificationscount }) =>
  Number(notificationscount) > 0 ? "3px solid red" : "none"} ;  */


  
 /*  ${({ notificationscount }) => {
   return Number(notificationscount) > 0
     ? `
    animation-name: breath-animation;
    animation-duration: 4s;
    animation-iteration-count: infinite;


    @keyframes breath-animation {
      0% { border-color: rgb(244, 160, 160);   opacity:0.7 }
      25% { border-color: rgb(243, 127, 127); }
      50% { border-color: #f84141; opacity:1  }
      75% { border-color: rgb(243, 127, 127);   }
      100% { border-color: rgb(244, 160, 160); opacity:0.7 }
   }
    `
     : null;
 }} ;   */
`;

const MenuIcon = styledComponents(Box)`
  background: transparent;  
  padding-right: 10px; 
  .MuiIconButton-root{
    z-index:1000;
  }
`;

const MenuIconNotif = styledComponents(Box)`
  background: transparent;  
  .MuiIconButton-root{
    z-index:1000;
  }
`;

const NotifMainContainer = styledComponents.div`
  max-height: 300px;
  display: block;
  overflow-y: auto;
`;
const NotificationContainer = styledComponents.div` 
  margin : 5px 0px; 
  background: ${(props) => (props.visited ? "transparent" : "#dee5eb")}; 
  max-height: 300px;
`;
const NotificationText = styledComponents.span`
  font-size : 0.7em; 
  margin-left : 5px;
`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 0,
    top: -3,
    padding: "0 2px",
    fontWeight: "bold",
    color: "white",
    background: "red",
    fontSize: "1em",
  },
}));

const IconButtonStyled = styledComponents(Button)`
  .BaseBadge-root,.MuiSvgIcon-root, &>span {
    align-items: center;
    color: white !important;
    font-size: 0.7em;
    letter-spacing: 0.10em;
   

  }
  margin: 0px 5px;
  :hover{ 
    border-raduis : 0px;
    background: #17b1ca !important;
  }
`;
