import { Button, Alert, Checkbox } from "@mui/material";
import styled from "styled-components";
import Tab from "@mui/material/Tab";
import { createSvgIcon } from "@mui/material/utils";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { DataGrid } from "@mui/x-data-grid";

const color = (severity) => {
  switch (severity) {
    case "success":
      return "#0ab4aa";
    case "error":
      return "#f72626";
    case "info":
      return "#0498fc";
    case "warning":
      return "#ff5a18";
    default:
      return "#0f0f0f";
  }
};

export const CustomButton = styled(Button)`
  margin: 0px 14px !important;
  width: ${(props) => (props.islarge ? "220px" : "170px")};
  border-radius: 10px !important;

  .MuiSvgIcon-root {
    margin: 0px 10px !important;
  }
`;

export const SubmitButton = styled(Button)({
  "&.MuiButton-root": {
    margin: "2em 1em",
    backgroundColor: "#fafafa",
    color: "#0d9ee0",
    minWidth: "180px",
    borderRadius: "10px",
    border: "none",
    fontWeight: "bold",
    padding: "10px 15px",
    "& svg": {
      margin: "0 .25em",
    },
  },
  "&.MuiButton-root:hover": {
    borderColor: "transparent",
    color: "#fafafa",
    backgroundColor: "#0d9ee0",
  },
});

export const CustomButtonRisk = styled(Button)`
  margin: 0px 14px !important;
  width: ${(props) => (props.islarge ? "160px" : "110px")};
  border-radius: 10px !important;

  .MuiSvgIcon-root {
    margin: 0px 10px !important;
  }
`;

export const PlusIcon = createSvgIcon(
  // credit: plus icon from https://heroicons.com/
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Add"
);

export const DetailContainer = styled.div`
  width: calc(100vw - 240px);
  max-width: calc(100vw - 240px);
  min-width: 1000px;
  height: fit-content;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly; */
  margin: 0;
  font-size: 0.8em;
  margin-bottom: 50px;
  padding-bottom: 50px;

  ${CustomButton} {
    color: #0ab4aa;
    border-color: #0ab4aa;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 10px -5px,
      rgba(10, 37, 64, 0.35) 0px -1px 3px 0px inset;

    &:hover {
      color: #0ab4aa !important;
      border-color: #0ab4aa !important;
    }
  }
`;

export const DetailPopUpContainer = styled.div`
  /*  width: calc(100vw - 240px); */
  /*  max-width: calc(100vw - 240px); */
  min-width: 1000px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px 0px;
  font-size: 0.8em;
  /*  margin-bottom: 50px;
  padding-bottom: 70px; */
`;

export const DetailWrapper = styled.div`
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  min-height: 400px;
  /* margin: 5px 10px; */

  > span {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: larger;
    letter-spacing: 0.16em;
    background: #0ab4aa;
    color: white;
    text-transform: uppercase;

    height: 50px;
    padding-top: 12px;
  }
  .bloc {
    padding: 30px 20px;
    > div {
      display: flex;
      gap: 1rem;
      align-items: center;
      padding: 5px 10px;
      margin-bottom: 5px;
      background-color: #0ab4a90f;
      border-radius: 2px;

      & .MuiInputBase-root.MuiInput-root::after {
        border-bottom: 2px solid #0ab4aa;
      }
    }

    &.compact {
      > div {
        min-height: 0;
        max-height: 3rem;
        align-items: baseline;
      }
    }
  }

  form > div > div {
    display: flex;
    justify-content: space-between;

    :focus-within {
      font-weight: 700;
    }

    > span {
      min-width: 120px;
    }
    .MuiInput-input {
      width: 100px;
      min-width: 100px;
      text-align: start;
      font-size: 0.8em;
    }
  }
`;

export const MainContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: calc(100vw - 240px);
  min-width: 1000px;
  max-width: calc(100vw - 240px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .total {
    padding: 10px 20px;
    padding-bottom: 10px;
    font-size: 1.1em;
    & > span {
      margin-left: 10px;
      font-weight: 600;
    }
  }
`;
export const StyledDiv = styled.div`
  display: flex;
  height: 80vh;
`;

export const StyledTitle = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;

  color: #333; // Dark grey text

  h1 {
    /* text-decoration: underline; */
    font-size: 1.5rem; // Adjust the font size
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const CompactTableCell = styled(TableCell)`
  /*  border-right: 1px solid #ccc; */
  &.MuiTableCell-root {
    padding: 0 5px;
    text-align: center;
    line-height: "0.1em";
  }
`;

export const StyledTableCell = styled(CompactTableCell)`
  /*  border-right: 1px solid #ccc; */
  border-left: 1px solid #ccc;
`;

export const ThickBorderTableCell = styled(CompactTableCell)`
  border-left: 2px solid #ccc;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  border-radius: 15px; // Add rounded corners
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
`;

export const StyledTableContainer = styled(TableContainer)`
  max-height: 840px;

  /* Add any additional styles for TableContainer here */
`;

export const StyledTab = styled(Tab)`
  background-color: "red";
`;

export const DetailTableWrapper = styled.div`
  border-radius: 15px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  width: 93%;
  min-height: 440px;
  margin: 5px 10px;

  > span {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: larger;
    letter-spacing: 0.16em;
    background: #0ab4aa;
    color: white;
    text-transform: uppercase;

    border-radius: 15px 15px 0px 0px;
    height: 50px;
    padding-top: 12px;
  }
  .bloc {
    padding: 30px 20px;
  }

  > div > div {
    display: flex;
    justify-content: space-between;

    :focus-within {
      font-weight: 700;
    }

    > span {
      min-width: 120px;
    }
    .MuiInput-input {
      width: 100px;
      min-width: 100px;
      text-align: right;
      font-size: 0.8em;
    }
  }
`;

export const CustomTextField = styled(TextField)({
  variant: "standard",
  "& .MuiInputBase-root": {
    height: "30px", // Adjust the height as needed
    marginTop: 0,
    marginRight: 0,
    marginLeft: "auto",
    maxWidth: "min-content",
    textAlign: "end",
    /*  width: "135px", */
  },
  "& .MuiOutlinedInput-input": {
    padding: "4px 10px", // Adjust the padding for a tighter fit
  },
  "& .MuiInputLabel-outlined": {
    transform: "translate(14px, 9px) scale(1)", // Adjust the label position
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)", // Adjust the shrunk label position
  },
  "& .MuiFormHelperText-root": {
    color: "red",
    fontWeight: 300,
    fontSize: ".85em",
  },
  "& .MuiInput-input.MuiInputBase-input": {
    marginLeft: 0,
    minWidth: "2em",
    textAlign: "start",
  },
});

export const CustomSelect = styled(Select)({
  "& .MuiInputBase-root": {
    height: "1.5rem", // Make sure this matches the height in CustomTextField
  },
  "& .MuiInputBase-input": {
    padding: "4px 10px", // Adjust padding to match CustomTextField
    "&.Mui-disabled": {
      WebkitTextFillColor: "initial",
      // color: "#0ab4aa",
      color: "#60706f",
      fontWeight: "bold",
    },
  },
  "& .MuiInputLabel-outlined": {
    transform: "translate(14px, 9px) scale(1)",
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)",
  },
});

export const AlertStyled = styled(Alert)`
  margin: 10px;
  border-radius: 15px !important;
  padding: 4px 16px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  max-width: 100%;
  border: none !important;

  .MuiSvgIcon-root {
    color: ${(props) => color(props.severity)} !important;
  }
  .paragraph {
    color: ${(props) => color(props.severity)} !important;
    align-self: center;
    margin-top: 10px;
    margin-left: 20px;
    /*  font-size: 1.1em; */
    font-weight: 500 !important;
    max-width: 600px;
  }
`;

export const StyledDataGrid = styled(DataGrid)`
  border-collapse: separate;
  border: none;
  width: max-content;
  margin: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 15px 30px -25px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -20px;

  & .grid--cell-empty {
    background-color: #fdfdfd;
  }

  & .grid--cell-entry {
    background-color: #0ab4a91c;
  }

  /* --DataGrid-overlayHeight: 300px; */
  & .MuiDataGrid-columnHeader {
    min-width: max-content;
    svg {
      color: white;
    }

    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: larger;
    letter-spacing: 0.16em;
    background-color: #0ab4aa;
    color: white;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    height: 50px;
    padding: 12px 20px;
  }

  .grid--currency-header {
    background-color: #fff;
    color: black;
    min-width: max-content;
    svg {
      color: black;
    }
  }

  &.MuiDataGrid-root.MuiDataGrid-withBorderColor * {
    border-color: #0ab4a914;
  }

  &.table-bonds.MuiDataGrid-root {
    width: 100%;
    /* overflow-x: scroll; */

    .grid--bonds__cell,
    .grid--bonds__header {
      width: max-content;
      /* min-width: max-content !important; */
    }

    .MuiDataGrid-footerContainer {
      justify-content: start;
    }
  }
`;

export const TableTitle = styled("h4")`
  text-align: center;
  background-color: #0ab4aa;
  color: white;
  font-family: Arial;
  font-weight: bold;
  margin: 0;
  padding: 20px 30px;
  border-radius: 5px 5px 0 0;
`;

export const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked,
  &.MuiCheckbox-indeterminate {
    color: white;
    & > svg {
      color: white;
    }
  }
`;
