

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";   
 import orderBy from "lodash/orderBy"
import constants from "../../common/constants";
import { not } from "../../common/utils";
 
import { getNotificationApi, markNotificationAsSeenApi } from "./notification.api";

export const initialState = { 
  notifications: [],
  unSeenNotificationCount: 0,
  status : null
};


export const getNotificationAction = createAsyncThunk(
  'notification/get',
  async (__, {rejectWithValue, fulfillWithValue, getState}) => {
    const { authentication : { accessToken } } = getState(); 
    try{ 
/*       const response = await getNotificationApi(accessToken);
      if(response.status !== 200){
          return rejectWithValue(response);
      }
      return fulfillWithValue( response.data );  */
    }catch(err){
      throw rejectWithValue(err);
    } 
  },
  {
    condition: (__, { getState }) => {
      const { notification : { status } } = getState(); 
      return not(status === constants.API_CALL_STATUS.PENDING ); 
    },
  } 
);

export const markNotificationAsSeenAction = createAsyncThunk(
  'notification/mark-notification-as-seen',
  async ({id }, {rejectWithValue, fulfillWithValue, getState}) => {
    const { authentication : { accessToken } } = getState(); 
    try{ 
      const response = await markNotificationAsSeenApi(id, accessToken);
      if(response.status !== 204){
          return rejectWithValue(response);
      }
      return fulfillWithValue();
    }catch(err){
      throw rejectWithValue(err);
    } 
  } 
);
 

const notification = createSlice({
  name: "notification",
  initialState, 
  reducers: { 
    clearNotification: (state,  ) => {
      state.notifications =   [];
      state.unSeenNotificationCount = 0;
    },
  },
  extraReducers: {
        /*** refreshTokenAction */
  [getNotificationAction.pending]: (state) => { 

    state.status =  constants.API_CALL_STATUS.PENDING; 

  },
  [getNotificationAction.fulfilled]: (state, { payload }) => { 
    state.notifications =   orderBy(payload, ['id'],['desc'] );
    state.unSeenNotificationCount = payload?.filter(({lastSeen})=> lastSeen === null ||  lastSeen === undefined).length;
    state.status =  constants.API_CALL_STATUS.SUCCESS;
  },
  [getNotificationAction.rejected]: (state, { payload }) => { 

    state.status =  constants.API_CALL_STATUS.REJECTED;
  } 
  }
});
 
export const {   clearNotification } = notification.actions;
export default notification.reducer;
