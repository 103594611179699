import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import constants from "../../common/constants";
import { not } from "../../common/utils";
import {
  changeStatusProfitabilityyApi,
  fetchtProfitabilitApi,
  submitProfitabilityApi,
} from "./profitability.api";
import _groupBy from "lodash/groupBy";
import { dateToString } from "../../UtilFunctions";
import moment from "moment";

export const initialState = {
  submitProfitabilityStatus: null,

};


export const submitProfitabilityAction = createAsyncThunk(
  "profitability/submit-profitability",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await submitProfitabilityApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const changeStatusProfitabilityAction = createAsyncThunk(
  "profitability/submit-status",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await changeStatusProfitabilityyApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);


export const fetchProfitabilityAction = createAsyncThunk(
  "profitability/by-period",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();
    try {
      const response = await fetchtProfitabilitApi(
        payload,
        accessToken,
      );

      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

const profitability = createSlice({
  name: "profitability",
  initialState,
  reducers: {

  },
  extraReducers: {
   
    /*** submitDeclarationsAction */
    [submitProfitabilityAction.pending]: (state) => {
      state.submitProfitabilityStatus = constants.API_CALL_STATUS.PENDING;
    },
    [submitProfitabilityAction.fulfilled]: (state, { payload }) => {
      state.submitProfitabilityStatus = constants.API_CALL_STATUS.SUCCESS;
    },
    [submitProfitabilityAction.rejected]: (state, __) => {
      state.submitProfitabilityStatus = constants.API_CALL_STATUS.REJECTED;
    },
    /*** submitDeclarationsAction */
   
  },
});


/* export const {
  changeDeclareDate,
  changeMonthlyDeclarationDate,
  calculateEligibleDay,
  pages,
  rowPerPages,
} = declarations.actions; */

export default profitability.reducer;
