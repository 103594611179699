import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import constants from "../../common/constants";
import { not } from "../../common/utils";
import {
  performActionApi,
  addDeclarationApi,
  addMonthlyDeclarationApi,
  deleteDeclarationsApi,
  fetchDeclarationsApi,
  fetchMonthlyDeclarationsApi,
  submitDeclarationsApi,
  submitMonthlyDeclarationApi,
} from "./declaration.api";
import _groupBy from "lodash/groupBy";
import { dateToString } from "../../UtilFunctions";
import moment from "moment";

export const initialState = {
  selectedDeclaration: {
    status: constants.DECLARATION.STATUS.DRAFT,
    date: new Date(),
  },
  selectedMonthlyDeclaration: {
    status: null,
    date: new Date(),
  },
  eligibleDay: null,
  addDeclarationStatus: null,
  addMonthlyDeclarationStatus: null,
  submitDeclarationsStatus: null,
  submitMonthlyDeclarationsStatus: null,
  deleteDeclarationsStatus: null,
  fetchDeclarationsStatus: null,
  fetchMonthlyDeclarationsStatus: null,
  pendingExecuteAction: false,
  declarations: {
    LOAN: [],
    DEPOSIT: [],
  },
  monthlyDeclaration: {},
  contreparties: [
    {
      id: 1,
      name: "Barklays",
    },
    {
      id: 2,
      name: "Société Générale",
    },
    {
      id: 3,
      name: "BNP Paribas",
    },
    {
      id: 4,
      name: "BBVA",
    },
    {
      id: 5,
      name: "Banco Sabadell",
    },
    {
      id: 6,
      name: "Banque Transatlantique London",
    },
    {
      id: 7,
      name: "Lloyds Bank",
    },
  ],
  pages: 0,
  rowPerPages: 10,
};

export const addDeclarationAction = createAsyncThunk(
  "declarations/add-declaration",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
      declarations: {
        selectedDeclaration: { date },
      },
    } = getState();

    try {
      const response = await addDeclarationApi(
        {
          ...payload,
          declarationDate: dateToString(date),
        },
        accessToken
      );
      if (response.status !== 201) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  },
  {
    condition: (__, { getState }) => {
      const {
        declarations: { addDeclarationStatus },
      } = getState();
      return not(addDeclarationStatus === constants.API_CALL_STATUS.PENDING);
    },
  }
);

export const submitDeclarationsAction = createAsyncThunk(
  "declarations/submit-declarations",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await submitDeclarationsApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  },
  {
    condition: (__, { getState }) => {
      const {
        declarations: { submitDeclarationsStatus },
      } = getState();
      return not(
        submitDeclarationsStatus === constants.API_CALL_STATUS.PENDING
      );
    },
  }
);

export const deleteDeclarationsAction = createAsyncThunk(
  "declarations/delete-declarations",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await deleteDeclarationsApi(payload, accessToken);
      if (response.status !== 204) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  },
  {
    condition: (__, { getState }) => {
      const {
        declarations: { deleteDeclarationsStatus },
      } = getState();
      return not(
        deleteDeclarationsStatus === constants.API_CALL_STATUS.PENDING
      );
    },
  }
);

export const fetchDeclarationsAction = createAsyncThunk(
  "declarations/fetch-declarations",
  async (__, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
      declarations: {
        selectedDeclaration: { date },
      },
    } = getState();
    try {
      const response = await fetchDeclarationsApi(
        typeof date === "string" ? date : dateToString(date),
        accessToken
      );

      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  },
  {
    condition: (__, { getState }) => {
      const {
        declarations: { fetchDeclarationsStatus },
      } = getState();
      return not(fetchDeclarationsStatus === constants.API_CALL_STATUS.PENDING);
    },
  }
);

export const addMonthlyDeclarationAction = createAsyncThunk(
  "declarations/add-monthly-declaration",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
      declarations: {
        selectedMonthlyDeclaration: { date },
      },
    } = getState();

    try {
      const response = await addMonthlyDeclarationApi(
        {
          declarations: {
            ...payload,
          },
          declarationDate: dateToString(date),
        },
        accessToken
      );
      if (response.status !== 201) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  },
  {
    condition: (__, { getState }) => {
      const {
        declarations: { addMonthlyDeclarationStatus },
      } = getState();
      return not(
        addMonthlyDeclarationStatus === constants.API_CALL_STATUS.PENDING
      );
    },
  }
);

export const fetchMonthlyDeclarationsAction = createAsyncThunk(
  "declarations/fetch-monthly-declarations",
  async (__, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
      declarations: {
        selectedMonthlyDeclaration: { date },
      },
    } = getState();
    try {
      const response = await fetchMonthlyDeclarationsApi(
        dateToString(date),
        accessToken
      );

      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data[0]);
    } catch (err) {
      throw rejectWithValue(err);
    }
  },
  {
    condition: (__, { getState }) => {
      const {
        declarations: { fetchMonthlyDeclarationsStatus },
      } = getState();
      return not(
        fetchMonthlyDeclarationsStatus === constants.API_CALL_STATUS.PENDING
      );
    },
  }
);

export const submitMonthlyDeclarationAction = createAsyncThunk(
  "declarations/submit-monthly-declaration",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
      declarations: {
        selectedMonthlyDeclaration: { date },
      },
    } = getState();

    try {
      const response = await submitMonthlyDeclarationApi(
        {
          ...payload,
          declarationDate: dateToString(date),
        },
        accessToken
      );
      if (response.status !== 204) {
        return rejectWithValue(response);
      }
      return fulfillWithValue();
    } catch (err) {
      throw rejectWithValue(err);
    }
  },
  {
    condition: (__, { getState }) => {
      const {
        declarations: { submitMonthlyDeclarationsStatus },
      } = getState();
      return not(
        submitMonthlyDeclarationsStatus === constants.API_CALL_STATUS.PENDING
      );
    },
  }
);

export const performAction = createAsyncThunk(
  "declarations/perform-action",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await performActionApi(
        {
          ...payload,
        },
        accessToken
      );
      if (response.status !== 204) {
        return rejectWithValue(response);
      }
      return fulfillWithValue();
    } catch (err) {
      throw rejectWithValue(err);
    }
  },
  {
    condition: (__, { getState }) => {
      const {
        declarations: { pendingExecuteAction },
      } = getState();
      return not(pendingExecuteAction);
    },
  }
);

const declarations = createSlice({
  name: "declarations",
  initialState,
  reducers: {
    changeDeclareDate: (state, { payload }) => {
      state.selectedDeclaration.date = payload.declareDate;
    },
    changeMonthlyDeclarationDate: (state, { payload }) => {
      state.selectedMonthlyDeclaration.date = payload.declareDate;
    },
    calculateEligibleDay: (
      state,
      { payload: { eligibleDate, cutOffTime } }
    ) => {
      const cutOffDate = moment().utc(true);
      console.log(
        ">>>>>>>>>>>>>>",
        cutOffDate.get("hour"),
        cutOffTime?.get("hour")
      );
      cutOffDate.set("hour", cutOffTime?.get("hour"));
      cutOffDate.set("minute", cutOffTime?.get("minute"));

      cutOffDate.utcOffset(
        cutOffDate?.utcOffset() - moment(eligibleDate)?.utcOffset()
      );
      if (cutOffDate.isSameOrBefore(moment(eligibleDate))) {
        console.log("here");
        state.eligibleDay = moment(eligibleDate).add(1, "day").toDate();
      } else {
        state.eligibleDay = eligibleDate;
      }
    },
    pages: (state, { pages }) => {
      console.log(pages.pages);
      state.pages = pages.pages;
    },
    rowPerPages: (state, { rowPerPages }) => {
      console.log(rowPerPages.rowPerPages);
      state.rowPerPages = rowPerPages.rowPerPages;
    },
  },
  extraReducers: {
    /*** refreshTokenAction */
    [addDeclarationAction.pending]: (state) => {
      state.addDeclarationStatus = constants.API_CALL_STATUS.PENDING;
    },
    [addDeclarationAction.fulfilled]: (state, ___) => {
      state.addDeclarationStatus = constants.API_CALL_STATUS.SUCCESS;
    },
    [addDeclarationAction.rejected]: (state, ___) => {
      state.addDeclarationStatus = constants.API_CALL_STATUS.REJECTED;
    },
    /*** fetchDeclarationsAction */
    [fetchDeclarationsAction.pending]: (state) => {
      state.fetchDeclarationsStatus = constants.API_CALL_STATUS.PENDING;
    },
    [fetchDeclarationsAction.fulfilled]: (state, { payload }) => {
      state.fetchDeclarationsStatus = constants.API_CALL_STATUS.SUCCESS;
      const result = _groupBy(payload, "type");
      state.declarations = result;
      if (!result.hasOwnProperty("LOAN")) state.declarations["LOAN"] = [];
      if (!result.hasOwnProperty("DEPOSIT")) state.declarations["DEPOSIT"] = [];

      state.selectedDeclaration.status =
        payload?.length <= 0
          ? constants.DECLARATION.STATUS.DRAFT
          : payload?.filter(
              (el) => el.status === constants.DECLARATION.STATUS.SUBMITTED
            ).length > 0
          ? constants.DECLARATION.STATUS.SUBMITTED
          : payload[0].status;
    },
    [fetchDeclarationsAction.rejected]: (state, __) => {
      state.fetchDeclarationsStatus = constants.API_CALL_STATUS.REJECTED;
    },
    /*** submitDeclarationsAction */
    [submitDeclarationsAction.pending]: (state) => {
      state.submitDeclarationsStatus = constants.API_CALL_STATUS.PENDING;
    },
    [submitDeclarationsAction.fulfilled]: (state, { payload }) => {
      state.submitDeclarationsStatus = constants.API_CALL_STATUS.SUCCESS;
    },
    [submitDeclarationsAction.rejected]: (state, __) => {
      state.submitDeclarationsStatus = constants.API_CALL_STATUS.REJECTED;
    },
    /*** submitDeclarationsAction */
    [deleteDeclarationsAction.pending]: (state) => {
      state.deleteDeclarationsStatus = constants.API_CALL_STATUS.PENDING;
    },
    [deleteDeclarationsAction.fulfilled]: (state, { payload }) => {
      state.deleteDeclarationsStatus = constants.API_CALL_STATUS.SUCCESS;
    },
    [deleteDeclarationsAction.rejected]: (state, __) => {
      state.deleteDeclarationsStatus = constants.API_CALL_STATUS.REJECTED;
    },
    /*** addMonthlyDeclarationAction */
    [addMonthlyDeclarationAction.pending]: (state) => {
      state.addMonthlyDeclarationStatus = constants.API_CALL_STATUS.PENDING;
    },
    [addMonthlyDeclarationAction.fulfilled]: (state, { payload }) => {
      state.addMonthlyDeclarationStatus = constants.API_CALL_STATUS.SUCCESS;
    },
    [addMonthlyDeclarationAction.rejected]: (state, __) => {
      state.addMonthlyDeclarationStatus = constants.API_CALL_STATUS.REJECTED;
    },
    /*** fetchMonthlyDeclarationsAction */
    [fetchMonthlyDeclarationsAction.pending]: (state) => {
      state.fetchMonthlyDeclarationsStatus = constants.API_CALL_STATUS.PENDING;
    },
    [fetchMonthlyDeclarationsAction.fulfilled]: (state, { payload }) => {
      state.fetchMonthlyDeclarationsStatus = constants.API_CALL_STATUS.SUCCESS;
      if (
        payload === null ||
        payload === undefined ||
        payload === "undefined"
      ) {
        state.selectedMonthlyDeclaration.status =
          constants.DECLARATION.STATUS.DRAFT;
        state.monthlyDeclaration = {};
        return;
      }
      if (payload.hasOwnProperty("declarations")) {
        const entries = Object.entries(payload?.declarations);
        payload.declarations = entries?.reduce((acc, curr) => {
          const temp = curr[0].split("<>");
          return {
            ...acc,
            [temp.length === 2
              ? `${temp[0]}<>${temp[1].toUpperCase()}`
              : curr[0]]: curr[1],
          };
        }, {});
      }
      state.monthlyDeclaration = payload;
      state.selectedMonthlyDeclaration.status = payload?.status;
    },
    [fetchMonthlyDeclarationsAction.rejected]: (state, __) => {
      state.fetchMonthlyDeclarationsStatus = constants.API_CALL_STATUS.REJECTED;
    },
    /*** submitMonthlyDeclarationAction */
    [submitMonthlyDeclarationAction.pending]: (state) => {
      state.submitMonthlyDeclarationsStatus = constants.API_CALL_STATUS.PENDING;
    },
    [submitMonthlyDeclarationAction.fulfilled]: (state, { payload }) => {
      state.submitMonthlyDeclarationsStatus = constants.API_CALL_STATUS.SUCCESS;
    },
    [submitMonthlyDeclarationAction.rejected]: (state, __) => {
      state.submitMonthlyDeclarationsStatus =
        constants.API_CALL_STATUS.REJECTED;
    },
    [performAction.pending]: (state) => {
      state.pendingExecuteAction = true;
    },
    [performAction.fulfilled]: (state, { payload }) => {
      state.pendingExecuteAction = false;
    },
    [performAction.rejected]: (state, __) => {
      state.pendingExecuteAction = false;
    },
  },
});

export const {
  changeDeclareDate,
  changeMonthlyDeclarationDate,
  calculateEligibleDay,
  pages,
  rowPerPages,
} = declarations.actions;

export default declarations.reducer;
