import { deleteMethod, get, post, put } from "../../common/api";
import endpoint from "../../common/api/endpoint";
import _ from 'lodash';

export const fetchDeclarationsApi = (declarationDate,  accessToken) =>{

    const params = {
        "declaration-date" : declarationDate
    }
    return get(endpoint.DECLARATION.GET, params, accessToken);
}

export const addDeclarationApi = (declaration, accessToken) =>{
   
    return post(endpoint.DECLARATION.ADD, {...declaration}, null, accessToken);
}

export const submitDeclarationsApi = (declarations, accessToken) =>{
   
    return put(endpoint.DECLARATION.SUBMIT, [...declarations], null, accessToken);
}

export const deleteDeclarationsApi = (declarations, accessToken) =>{
   
    return deleteMethod(endpoint.DECLARATION.DELETE, [...declarations], null, accessToken);
}


export const addMonthlyDeclarationApi = (declaration, accessToken) =>{
   
    return post(endpoint.DECLARATION.ADD_MONTHLY, {...declaration}, null, accessToken);
}


export const fetchMonthlyDeclarationsApi = (declarationDate,  accessToken) =>{
    const params = {
        "declaration-date" : declarationDate
    }
    return get(endpoint.DECLARATION.GET_MONTHLY, params, accessToken);
}


export const submitMonthlyDeclarationApi = (declaration, accessToken) =>{
   
    return put(endpoint.DECLARATION.SUBMIT_MONTHLY, declaration, null, accessToken);
}

export const performActionApi = (payload, accessToken) =>{
   
    return post(endpoint.DECLARATION.EXECUTE_ACTION, {...payload}, null, accessToken);
}
