import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepare } from "../fx-position/fx-positions.slice";

const defaultStates = {
  bondsPortfolio: {
    data: [
    ],
  },
};
// Define a service using a base URL and expected endpoints
export const declarationsApi = createApi({
  reducerPath: "riskIndicatorsApi",
  tagTypes: ["FX", "BAL"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL_API}/declarations`,
    // credentials: {}
  }),
  endpoints: (builder) => ({
    getFxEntries: builder.query({
      query: () => "/fx-positions",
      providesTags: ["FX"],
    }),
    putFxEntries: builder.mutation({
      query: (declarations) => {
        declarations = declarations.map(prepare);

        return {
          url: "/fx-positions",
          method: "PUT",
          body: {
            declarations,
            submit: false,
          },
        };
      },
      invalidatesTags: ["FX"],
    }),
    submitFxEntries: builder.mutation({
      query: (declarations) => {
        declarations = declarations.map(prepare);

        return {
          url: "/fx-positions",
          method: "POST",
          body: {
            declarations,
            submit: true,
          },
        };
      },
      invalidatesTags: ["FX"],
    }),

    getBalanceEntries: builder.query({
      query: () => "/balance",
      providesTags: ["BAL"],
    }),
    addBalanceEntries: builder.mutation({
      query: (entries) => {
        entries = entries.map(prepare);
        return {
          url: "/balance",
          method: "PUT",
          body: {
            entries,
          },
        };
      },
      invalidatesTags: ["BAL"],
    }),
    deleteBalanceEntries: builder.mutation({
      query: (entries) => {
        entries = entries.map(prepare);
        return {
          url: "/balance",
          method: "DELETE",
          body: {
            entries,
          },
        };
      },
      invalidatesTags: ["BAL"],
    }),
    submitBalanceEntries: builder.mutation({
      query: (declarations) => ({
        url: "/balance",
        method: "POST",
        body: {
          declarations,
          submit: true,
        },
      }),
      invalidatesTags: ["BAL"],
    }),

    getBondsEntries: builder.query({
      // query: () => "/bonds-portfolio",
      // TEMPORARY
/*       queryFn: (_, { getState }, ___, baseQuery) => {
        return baseQuery("/bonds-portfolio").then(({ data, error }) => {
          return error
            ? {
                data: defaultStates.bondsPortfolio.data,
              }
            : { data };
        });
      },
      providesTags: (data, error) =>
        data === defaultStates.bondsPortfolio.data
          ? [{ type: "BP", id: "error" }]
          : [{ type: "BP", id: "success" }],  */
    }),
    addBondsEntries: builder.mutation({
/*       queryFn: (entries, { getState }, __, baseQuery) => {
        console.log("getState()", getState());
        return baseQuery({
          url: "/bonds-portfolio",
          method: "PUT",
          body: {
            entries,
          },
        }).then(({ data, error }) => ({
          data: entries,
        }));
      },
      invalidatesTags: [{ type: "BP", id: "success" }], */
    }),
    deleteBondsEntries: builder.mutation({
/*       queryFn: (entryIds, { getState }, __, baseQuery) => {
        console.log("getState()", getState());
        return baseQuery({
          url: "/bonds-portfolio",
          method: "DELETE",
          body: {
            entryIds,
          },
        }).then(({ data, error }) => ({
          data: entryIds,
        }));
      },
      invalidatesTags: [{ type: "BP", id: "success" }], */
    }),
    submitBondsEntries: builder.mutation({
/*       query: () => ({
        url: "/bonds-portfolio",
        method: "POST",
        body: {
          submit: true,
        },
      }),
      invalidatesTags: [{ type: "BP", id: "success" }], */
    }),
  }),
});

export const {
  useGetBalanceEntriesQuery,
  useGetFxEntriesQuery,
  usePutBalanceEntriesMutation,
  usePutFxEntriesMutation,
  useSubmitBalanceEntriesMutation,
  useSubmitFxEntriesMutation,
  useLazyGetBalanceEntriesQuery,
  useLazyGetFxEntriesQuery,
  useGetBondsEntriesQuery,
  useLazyGetBondsEntriesQuery,
  useAddBondsEntriesMutation,
  useDeleteBondsEntriesMutation,
  useSubmitBondsEntriesMutation,
} = declarationsApi;
