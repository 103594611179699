import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-GB';

export default {
    ATHORIZATION_PREFIX : "Bearer ",
    API_CALL_STATUS : {
        PENDING : 'pending',
        SUCCESS : 'success',
        REJECTED : 'rejected'
    },
    DECLARATION : {
        STATUS:{
            DRAFT : "DRAFT",
            SUBMITTED : "SUBMITTED",
            APPROVED : "APPROVED",
            ACCEPTED : "ACCEPTED",
            REJECTED : "REFUSED" 
        }
    },
    PROFITABILITY : {
        STATUS:{
            DRAFT : "DRAFT",
            SUBMITTED : "SUBMITTED",
            APPROVED : "APPROVED",
            ACCEPTED : "ACCEPTED",
            REJECTED : "REFUSED" 
        }
    },
    MONTHLY_DECLARATION_TYPES : {
        LIABILITY  : "LIABILITY",
        ASSET : "ASSET" 
    },
    MONTHLY_PROFITABILITY_TYPES : {
        LIABILITY  : "LIABILITY",
        ASSET : "ASSET" 
    },
    localeMap: {
        en: enLocale,
        fr: frLocale,
    }
}