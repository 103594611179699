import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchtPositionFxApi, submitPositionFxApi, updatePositionFxStatusApi } from "./fx-positions.api";

const SLICE_NAME = "fxPositions";

const initialState = () => ({
  //mutations: getInitialMutations(),
});

export function prepare(payload) {
  const { currency, assets, liabilities } = payload;
  payload = currency
    ? {
        [currency]: [assets, liabilities],
      }
    : payload;

  return payload;
}
export const getPositionFxAction = createAsyncThunk(
  "periodeFx/by-period",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await fetchtPositionFxApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response?.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);
export const submitPositionFxAction = createAsyncThunk(
  "positionFx/submit-positionFx",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await submitPositionFxApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);
export const updatePositionFxStatusAction = createAsyncThunk(
  "periodeFx/change-status",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await updatePositionFxStatusApi(payload, accessToken);
      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

const fxPositions = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAssetMutation: {
      prepare,
      reducer: (state, { payload }) => {
        Object.entries(payload).forEach(([key, value]) => {
          state.mutations[key][0] = value;
        });
      },
    },
    setLiabilityMutation: {
      prepare,
      reducer: (state, { payload }) => {
        Object.entries(payload).forEach(([key, value]) => {
          state.mutations[key][1] = value;
        });
      },
    },
    addMutation: {
      prepare,
      reducer: (state, { payload }) => {
        state.mutations = { ...state.mutations, ...payload };
      },
    },
  },
});
export const { setAssetMutation, setLiabilityMutation, addMutation } =
  fxPositions.actions;

export default fxPositions.reducer;
