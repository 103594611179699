import { combineReducers } from "@reduxjs/toolkit";
import authenticationSlice from "../authentication/authentication.slice";
import declarationSlice from "../declaration/declaration.slice";
import notificationSlice from "../notification/notification.slice";
import balanceSlice from "../balance/balance.slice";
import { declarationsApi } from "../declarations/declarations.api.js";
import fxPositionsSlice from "../fx-position/fx-positions.slice.js";
import bondPortfolioSlice from "../bond-portfolio/bond-portfolio.slice.js";

export default combineReducers({
  authentication: authenticationSlice,
  declarations: declarationSlice,
  notification: notificationSlice,
  balance: balanceSlice,
  fxPositions: fxPositionsSlice,
  bondPortfolio:bondPortfolioSlice,
  [declarationsApi.reducerPath]: declarationsApi.reducer,
});
