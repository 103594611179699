import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import constants from "../../common/constants";
import { not } from "../../common/utils";
import { decodeJwt, writeInStorage } from "../../UtilFunctions";
import {
  changePasswordApi,
  loginApi,
  logoutApi,
  refreshTokenApi,
} from "./authentication.api";

export const initialState = {
  userDetails: null,
  accessToken: null,
  refreshToken: null,
  expiresIn: 0,
  refreshExpiresIn: 0,
  tokenId: null,
  login: {
    status: null,
    reason: null,
  },
  changePassword: {
    status: null,
    error: null,
  },
};

export const logoutAction = createAsyncThunk(
  "authentication/logout-action",
  async (payload, { rejectWithValue, fulfillWithValue, getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    try {
      const response = await logoutApi(accessToken);
      if (response.status !== 204) {
        return rejectWithValue(response);
      }
      return fulfillWithValue();
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const loginAction = createAsyncThunk(
  "authentication/login",
  async (
    { username, password, refreshToken, tokenId },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = refreshToken
        ? await refreshTokenApi(refreshToken, tokenId)
        : await loginApi(username, password);

      if (response.status !== 200) {
        return rejectWithValue(response);
      }
      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err?.response?.data);
    }
  },
  {
    condition: (__, { getState }) => {
      const {
        authentication: { login },
      } = getState();
      return not(login.status === constants.API_CALL_STATUS.PENDING);
    },
  }
);

export const ChangePasswordAction = createAsyncThunk(
  "authentication/change-password",
  async (
    { username, oldPassword, newPassword, newPassword2 },
    { rejectWithValue, fulfillWithValue, getState }
  ) => {
    try {
      if (newPassword.length < 6 || newPassword !== newPassword2) {
        return rejectWithValue({
          response: {
            data: {
              message: "New password is invalid, please type a valid password",
            },
          },
        });
      }
      const response = await changePasswordApi(
        username,
        oldPassword,
        newPassword
      );

      if (response.status !== 200) {
        return rejectWithValue(response);
      }

      return fulfillWithValue(response.data);
    } catch (err) {
      throw rejectWithValue(err?.response?.data.message);
    }
  },
  {
    condition: (__, { getState }) => {
      const {
        authentication: { changePassword },
      } = getState();
      return not(changePassword.status === constants.API_CALL_STATUS.PENDING);
    },
  }
);

const authentication = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logOutClear: (state, { payload }) => {
      sessionStorage.removeItem("userDetails");
      sessionStorage.removeItem("refresh_token");
      sessionStorage.removeItem("tokenId");
    },
    clearUpdatePasswordError: (state, { payload }) => {
      state.changePassword.error = null;
      state.changePassword.status = null;
    },
    loadFromSessionStorage: (state, { payload }) => {
      //  state.userDetails = sessionStorage.getItem('userDetails') ? JSON.parse(sessionStorage.getItem('userDetails')) : {};
      state.refreshToken = sessionStorage.getItem("refresh_token")
        ? sessionStorage.getItem("refresh_token")
        : null;
      state.tokenId = sessionStorage.getItem("tokenId")
        ? sessionStorage.getItem("tokenId")
        : null;

      //  state.shouldLoad = false;
    },
  },
  extraReducers: {
    /*** loginAction */
    [loginAction.pending]: (state) => {
      state.login.status = constants.API_CALL_STATUS.PENDING;
      state.login.reason = null;
      state.changePassword.status = null;
      state.changePassword.error = null;
      state.refreshToken = undefined;
      state.accessToken = null;
      state.expiresIn = 0;
      state.refreshExpiresIn = 0;
    },
    [loginAction.fulfilled]: (state, { payload }) => {
      state.login.status = constants.API_CALL_STATUS.SUCCESS;
      state.accessToken = payload["access_token"];
      state.refreshToken = payload["refresh_token"];
      state.expiresIn = payload["expires_in"];
      state.refreshExpiresIn = payload["refresh_expires_in"];
      state.userDetails = payload?.userDetails;
      state.login.reason = null;

      //  writeInStorage('userDetails',  JSON.stringify( payload.userDetails));
      writeInStorage("refresh_token", payload["refresh_token"]);
      writeInStorage("tokenId", decodeJwt(payload["access_token"])?.jti);
    },
    [loginAction.rejected]: (state, { payload }) => {
      state.login.status = constants.API_CALL_STATUS.REJECTED;
      state.login.reason = payload;
      state.changePassword.status = null;
      state.changePassword.error = null;
      state.refreshToken = null;
      state.accessToken = null;
      state.expiresIn = 0;
      state.refreshExpiresIn = 0;
    },
    [ChangePasswordAction.pending]: (state) => {
      state.changePassword.status = constants.API_CALL_STATUS.PENDING;
      state.changePassword.error = null;
      state.login.status = null;
      state.login.reason = null;
    },
    [ChangePasswordAction.fulfilled]: (state, { payload }) => {
      state.changePassword.status = constants.API_CALL_STATUS.SUCCESS;
    },
    [ChangePasswordAction.rejected]: (state, { payload }) => {
      state.changePassword.status = constants.API_CALL_STATUS.REJECTED;

      state.changePassword.error = payload?.response?.data?.message;
    },
  },
});

export const { logOutClear, loadFromSessionStorage, clearUpdatePasswordError } =
  authentication.actions;

export default authentication.reducer;
