import { LocalizationProvider } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  Slide,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import constants from "../../common/constants";
import {
  DatePickerStyled,
  DialogStyled,
  MoreInfoButton,
  ReloadButton,
  ToolbarContainer,
} from "../DailyCash/dailyCash.styles";
import { AlertStyled } from "./riskindicators.styles";
import SyncIcon from "@mui/icons-material/Sync";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  fetchDeclarationsAction,
  fetchMonthlyDeclarationsAction,
} from "../../redux/declaration/declaration.slice";
import { useDispatch } from "react-redux";
import { getMomentDateFromNormal } from "../../UtilFunctions";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import React, { useState } from "react";
import { forwardRef } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { DatePicker } from "@mui/lab";

export default function HeaderAlertGlobalMarket({
  status,
  locale,
  isMonthly,
  comment,
  onSaveQuarterData,
  onSaveSelectedYear,
  onReload, 
  showReloadBoutton,
  name,
  reasonReject,
}) {
  const dispatch = useDispatch();
  const [showComment, setShowComment] = useState(false);
  const [selectedYear, setSelectedYear] = useState(() => {
    const date = new Date();
    if (date.getMonth() < 3) date.setFullYear(date.getFullYear() - 1);
    return date;
  });

  const [quarter, setQuarter] = useState(
    // Previous quarter
    () => "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) - 1 || 4)
  );

  const handleYearChange = (year) => {
    setSelectedYear(year);
    onSaveSelectedYear(year);
  };

  const dispatchOnReload = () => {
    onReload();
  };

  const onShowDetails = () => {
    setShowComment((old) => !old);
  };
  const quarters = ["Q1", "Q2", "Q3", "Q4"];

  const handleQuarterChange = (event) => {
    setQuarter(event.target.value);
    onSaveQuarterData(event.target.value);
  };
  const alert = (
    <ToolbarContainer>
      <AlertStyled variant="outlined" severity={severityStatus(status)} style={{ textAlign: 'left' }}>
      { status === constants.DECLARATION.STATUS.DRAFT && (
          <p className="paragraph">
            {`You still didn't declare your quartly ${
            name === 'submenu1' ? 'Balance' :
            name === 'submenu2' ? 'FX positions' :
            name === 'submenu3' ? 'Bonds portfolio' :
            name === 'Profitability Indicators' || name === 'KPI GM' ? name : ''
            } for this quarter`}
          </p>
        )}
        {status === constants.DECLARATION.STATUS.SUBMITTED && (
          <p className="paragraph">
            {`Your quartly declaration was submitted and is waiting for approval`}
          </p>
        )}
        {(status === constants.DECLARATION.STATUS.APPROVED ||
          status === constants.DECLARATION.STATUS.ACCEPTED) && (
          <p className="paragraph">
            {`Your quartly declaration was approved and cannot be changed`}
          </p>
        )}
        {status == constants.DECLARATION.STATUS.REJECTED && (
          <>
          <p className="paragraph">
            {`Your quartly declaration was rejected `}
            <br/>
            {`Reason : ${reasonReject}`}
          </p>
          </>
        )} 

        {comment && comment !== null && (
          <MoreInfoButton size="large" onClick={onShowDetails}>
            <StickyNote2Icon />
          </MoreInfoButton>
        )}

        <CommentDialog
          selectedValue={comment}
          open={showComment}
          onClose={onShowDetails}
        />
      </AlertStyled>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={constants.localeMap[locale]}
        style={{ textAlign: 'right' }}
      >
        <FormControl
          variant="outlined"
          style={{ width: "100px", marginRight: "20px" }}
        >
          <InputLabel id="quarter-label" style={{ fontSize: "1rem" }}>
            Quarter
          </InputLabel>
          <Select
            labelId="quarter-label"
            id="quarter"
            value={quarter}
            onChange={handleQuarterChange}
            label="Quarter"
            style={{ height: "50px", fontSize: "1rem" }}
          >
            {quarters.map((q, index) => (
              <MenuItem key={index} value={q}>
                {q}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DatePicker
          views={["year"]}
          label="Year"
          value={selectedYear}
          onChange={handleYearChange}
          style={{ marginRight: "20px"}}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                style: {
                  height: "50px", // Adjust the height as needed
                  padding: "4px 10px", // Adjust the padding for a tighter fit
                  marginRight: "5px"
                },
              }}
            />
          )}
        />
   
          <ReloadButton size="medium" onClick={dispatchOnReload}>
          <SyncIcon />
        </ReloadButton> 
        
      </LocalizationProvider>
    </ToolbarContainer>
  );

  return isMonthly ? (
    alert
  ) : (
    <div className={"date-today"} style={{ marginTop: 20, marginRight: 25 }}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={constants.localeMap[locale]}
      >
        <FormControl variant="outlined">
          <InputLabel id="quarter-label">Quarter</InputLabel>
          <Select
            labelId="quarter-label"
            id="quarter"
            value={quarter}
            onChange={handleQuarterChange}
            label="Quarter"
          >
            {quarters.map((q, index) => (
              <MenuItem key={index} value={q}>
                {q}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ReloadButton size="large" onClick={onReload}>
          <SyncIcon />
        </ReloadButton>
      </LocalizationProvider>
    </div>
  );
}

const CommentDialog = ({ onClose, selectedValue, open }) => {
  return (
    <DialogStyled
      TransitionComponent={Transition}
      onClose={onClose}
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        The rejection reason details
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {selectedValue}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </DialogStyled>
  );
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const severityStatus = (status) => {
  if (status == constants.DECLARATION.STATUS.DRAFT) return "warning";
  if (status == constants.DECLARATION.STATUS.SUBMITTED) return "info";
  if (
    status == constants.DECLARATION.STATUS.APPROVED ||
    status == constants.DECLARATION.STATUS.ACCEPTED
  )
    return "success";
  if (status == constants.DECLARATION.STATUS.REJECTED) return "error";
  return "warning";
};
