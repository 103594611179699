import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDeclarationAction,
  changeDeclareDate,
  deleteDeclarationsAction,
  fetchDeclarationsAction,
  submitDeclarationsAction,
} from "../../redux/declaration/declaration.slice";
import constants from "../../common/constants";
import {
  selectAddDeclarationStatus,
  selectDeclarations,
  selectEligibleDate,
  selectSelectedDate,
  selectSelectedDayStatus,
} from "../../redux/declaration/declaration.selector";
import { selectUserDetails } from "../../redux/authentication/authentication.selector";
import HeaderAlertComponent from "./HeaderAlert.component";

import TableDeclaration from "./TableDeclaration";
import YesterdayApprovedDialog from "./YesterdayApprovedDialog";
import {
  dateToString,
  formattingNumber,
  getMaturityText,
  isNullOrUndefined,
  toMoment,
} from "../../UtilFunctions";
import {
  AccordionSummaryStyled,
  FormContainer,
  ReloadButton,
} from "./dailyCash.styles";
import { not } from "../../common/utils";
import moment from "moment";

const draftDeclaration = {
  declaration_date: new Date(),
  due_date: new Date(),
  valueDate: new Date(),
  currency: "",
  quantity: 0,
  type: "",
};

export default function DailyCash() {
  const dispatch = useDispatch();

  const [locale, setLocale] = React.useState("en");
  const [declaration, setDeclaration] = React.useState(draftDeclaration);
  const [expanded, setExpanded] = React.useState("panel1");
  const [disableForm, setDisableForm] = React.useState(false);
  const [initalState, setInitalState] = React.useState(true);
  const [labelButton, setLabelButton] = React.useState("DATE");
  const [editMode, setEditMode] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(false);

  const [selectedElements, setSelectedElements] = React.useState({
    LOAN: [],
    DEPOSIT: [],
  });

  const [confirm, setconfirm] = React.useState(false);

  const status = useSelector(selectSelectedDayStatus);
  const declareDate = useSelector(selectSelectedDate);
  const userDetails = useSelector(selectUserDetails);
  const declarations = useSelector(selectDeclarations);
  const addDeclarationsStatus = useSelector(selectAddDeclarationStatus);
  const eligibleDate = useSelector(selectEligibleDate);
  const [isEligible, setIsEligible] = React.useState(false);

  React.useEffect(() => {
    setIsEligible(isEligibleDay());
  }, [eligibleDate, declareDate, declarations]);
  React.useEffect(() => {
    dispatch(
      fetchDeclarationsAction({
        dateDeclaration: dateToString(declareDate),
      })
    );
  }, []);

  React.useEffect(() => {
    setCanSubmit(
      !(selectedElements.LOAN.length < 1 && selectedElements.DEPOSIT.length < 1)
    );
  }, [selectedElements, declarations]);

  React.useEffect(() => {
    clearSelection();
  }, [declarations]);

  React.useEffect(() => {
    clearSelection();
    dispatch(fetchDeclarationsAction());
  }, [declareDate]);

  React.useEffect(() => {
    setDeclaration({
      ...declaration,
      currency: userDetails.company?.defaultCurrency?.id,
    });
  }, [userDetails]);

  const clearSelection = () => {
    setSelectedElements({
      LOAN: [],
      DEPOSIT: [],
    });
    setDeclaration(draftDeclaration);
    setLabelButton("DATE");
  };

  const onChangeDeclareDate = (data) => {
    dispatch(
      changeDeclareDate({
        declareDate: data,
      })
    );
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangedValues = (e) => {
    setDeclaration({
      ...declaration,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangedDate = (e, name) => {
    setDeclaration((oldDeclaration) => ({
      ...oldDeclaration,
      [name ? name : e.target.name]: name ? e : e.target.value,
    }));
  };
  const changeNumber = (e) => {
    const text = e.target.value.replace(/\s/g, "");
    if (!isNaN(text)) {
      if (Number(text) < 1000000000)
        setDeclaration({ ...declaration, [e.target.name]: text });
    } else {
      setDeclaration({
        ...declaration,
        [e.target.name]: text.substring(0, text.length - 1),
      });
    }
  };

  const changeMaturityValue = (e) => {
    let text = e.target.value.replace(/\s/g, "");
    let value = 0;

    if (!isNaN(text)) {
      if (Number(text) < 1000000000) value = text;
    } else {
      value = text.substring(0, text.length - 1);
    }
    if (text.length > 0) {
      if (text[text.length - 1].toLowerCase() === "d") {
        setDeclaration({
          ...declaration,
          expiration_type: "Days",
          [e.target.name]: value,
        });
      } else if (text[text.length - 1].toLowerCase() === "m") {
        setDeclaration({
          ...declaration,
          expiration_type: "Months",
          [e.target.name]: value,
        });
      } else if (text[text.length - 1].toLowerCase() === "w") {
        setDeclaration({
          ...declaration,
          expiration_type: "Weeks",
          [e.target.name]: value,
        });
      } else {
        setDeclaration({
          ...declaration,
          [e.target.name]: value,
        });
      }
    } else
      setDeclaration({
        ...declaration,
        [e.target.name]: value,
      });
  };

  const changeLabelButton = () => {
    if (labelButton === "DATE") setLabelButton("NUMERIC");
    else setLabelButton("DATE");
    setDeclaration({
      ...declaration,
      expiration_time: "",
      due_date: undefined,
      expiration_type: "Days",
    });
  };

  const clear = () => {
    setDeclaration({
      ...declaration,
      expiration_time: "",
      due_date: new Date(),
      expiration_type: "",
      quantity: 0,
      currency: "",
      type: "",
      id: null,
    });
  };

  const cancel = () => {
    clear();
    setEditMode(false);
  };

  const save = (comment3) => {
    if (!declaration.due_date && !declaration.expiration_type) {
      declaration.expiration_type = "Days";
    }

    if (
      declaration.currency &&
      declaration.quantity &&
      declaration.type &&
      declaration.valueDate &&
      ((declaration.due_date &&
        toMoment(declaration.valueDate).isSameOrBefore(
          toMoment(declaration.due_date)
        )) ||
        (declaration.expiration_type && declaration.expiration_time))
    ) {
      setInitalState(false);
      dispatch(
        addDeclarationAction({
          ...declarationApiAdapter(declaration),
          comment3,
        })
      )
        .then(unwrapResult)
        .then(() => {
          setconfirm(false);
          dispatch(fetchDeclarationsAction());
          cancel();
          setInitalState(true);
          clearSelection();
        });

      return;
    }
    setInitalState(!initalState);
  };

  const edit = (comment) => {
    save(comment);
  };

  const setEditRow = (row) => {
    const newDeclaration = {
      ...row,
      [row.maturity?.type === "DATE" ? "due_date" : "expiration_time"]:
        row.maturity?.type === "DATE"
          ? toMoment(row.maturity?.dueDate)
          : row.maturity?.expirationTime,
      valueDate: toMoment(row.valueDate),
    };
    if (row.maturity?.unit) newDeclaration.expiration_type = row.maturity?.unit;
    setLabelButton(row.maturity?.type === "DATE" ? "DATE" : "NUMERIC");
    setDeclaration(newDeclaration);
    setEditMode(true);
  };

  const onDelete = (type) => {
    dispatch(
      deleteDeclarationsAction(
        selectedElements[type].map((id) => ({
          id: id,
        }))
      )
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchDeclarationsAction());
        cancel();
        setInitalState(true);
        clearSelection();
      });
  };

  const submit = () => {
    dispatch(
      submitDeclarationsAction(
        [...selectedElements.DEPOSIT, ...selectedElements.LOAN].map((id) => ({
          id: id,
        }))
      )
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchDeclarationsAction());
        cancel();
        setInitalState(true);
        clearSelection();
      });
  };

  const setSelected = (arr, target) => {
    setSelectedElements((old) => ({
      ...old,
      [target]: arr,
    }));
  };

  const isEligibleDay = () => {
    return (
      toMoment(eligibleDate).isValid() &&
      toMoment(eligibleDate).isSame(toMoment(declareDate), "day")
    );
  };

  return (
    <div>
      <Stack sx={{ width: "100%", padding: 1 }} spacing={2}>
        <HeaderAlertComponent
          status={status}
          declareDate={declareDate}
          locale={locale}
          onChangeDeclareDate={onChangeDeclareDate}
          comment={
            status === constants.DECLARATION.STATUS.REJECTED
              ? declarations?.DEPOSIT?.length > 0
                ? declarations?.DEPOSIT[0].comment
                : declarations?.LOAN[0].comment
              : null
          }
        />
        <Accordion
          expanded={
            ((isEligible && !editMode) ||
              (editMode === true &&
                moment().isSameOrBefore(
                  toMoment(declaration?.valueDate),
                  "day"
                ))) &&
            expanded === "panel1"
          }
        >
          <AccordionSummaryStyled
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography>
              {isEligible
                ? `${
                    editMode === true ? "Edit" : "Add"
                  } declaration of daily cash flow`
                : declarations["LOAN"].length === 0 &&
                  declarations["DEPOSIT"].length === 0
                ? "No declaration registred for this day"
                : editMode === true &&
                  moment().isSameOrBefore(
                    toMoment(declaration?.valueDate),
                    "day"
                  )
                ? "Edit declaration"
                : "Closed day"}
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetails>
            <Box
              sx={{
                overflow: "auto",
                "& .MuiTextField-root": {
                  m: 1,
                  width: "50ch",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <FormContainer disabled={disableForm}>
                <FormControl sx={{ m: 1, width: "14ch" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Currency *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    value={
                      declaration.currency === null
                        ? userDetails.company?.defaultCurrency?.id
                        : declaration.currency
                    }
                    label="Currency *"
                    name="currency"
                    error={!declaration.currency && !initalState}
                    onChange={handleChangedValues}
                    disabled={disableForm}
                  >
                    {userDetails.company?.currencies
                      ?.filter((c) => c.label === "USD")
                      .map((currency) => (
                        <MenuItem
                          key={`form-currency-${currency.id}`}
                          value={currency.id}
                        >
                          {currency.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    {userDetails.company?.currencies
                      ?.filter((c) => c.label !== "USD")
                      .map((currency) => (
                        <MenuItem
                          key={`form-currency-${currency.id}`}
                          value={currency.id}
                        >
                          {currency.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "20ch" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Transaction type *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    value={declaration.type}
                    label="Transaction type *"
                    name="type"
                    error={!declaration.type && !initalState}
                    onChange={handleChangedValues}
                    disabled={disableForm}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"DEPOSIT"}>Deposit</MenuItem>
                    <MenuItem value={"LOAN"}>Loan</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Amount *"
                  placeholder="Amount *"
                  style={{ width: "14ch" }}
                  value={formattingNumber(declaration.quantity | 0)}
                  onChange={changeNumber}
                  name="quantity"
                  error={!declaration.quantity && !initalState}
                />

                <FormControl>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={constants.localeMap[locale]}
                  >
                    <DatePicker
                      disablePast
                      name="valueDate"
                      value={declaration.valueDate}
                      onChange={(value) =>
                        handleChangedDate(value, "valueDate")
                      }
                      renderInput={(params) => (
                        <TextField style={{ width: "20ch" }} {...params} />
                      )}
                      label={"Value date"}
                    ></DatePicker>
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "40ch" }}>
                  <div style={{ display: "flex" }}>
                    <div className={"label-maturity"}>Maturity * </div>
                    <div style={{ width: "80px" }}>
                      <Button
                        variant="outlined"
                        className={"button-type"}
                        onClick={changeLabelButton}
                      >
                        {labelButton}
                      </Button>
                    </div>
                    {labelButton === "DATE" && (
                      <div className={"date-echeance"}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={constants.localeMap[locale]}
                        >
                          <DatePicker
                            disablePast
                            name="due_date"
                            minDate={declaration.valueDate}
                            value={declaration.due_date}
                            onChange={(value) =>
                              handleChangedDate(value, "due_date")
                            }
                            renderInput={(params) => <TextField {...params} />}
                            label={"Due date"}
                          ></DatePicker>
                        </LocalizationProvider>
                      </div>
                    )}
                    {labelButton !== "DATE" && (
                      <div className={"text-echeance"}>
                        <Select
                          style={{ width: "95px", fontSize: "0.9em" }}
                          name="expiration_type"
                          labelId="demo-simple-select-helper-label"
                          value={declaration?.expiration_type || "Days"}
                          error={!declaration.expiration_type && !initalState}
                          onChange={handleChangedValues}
                        >
                          <MenuItem value={"Days"}>Days</MenuItem>
                          <MenuItem value={"Weeks"}>Weeks</MenuItem>
                          <MenuItem value={"Months"}>Months</MenuItem>
                        </Select>
                        <Tooltip title="Expiration time (to set unit enter : d (Days), m (Months) or w (Weeks))">
                          <TextField
                            name="expiration_time"
                            label={"Expiration time *"}
                            value={`${declaration.expiration_time}`}
                            onChange={changeMaturityValue}
                            style={{
                              width: "70px !important",
                              marginRight: "5px !important",
                            }}
                            error={!declaration.expiration_time && !initalState}
                          ></TextField>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </FormControl>
                {!editMode && !disableForm && (
                  <Button
                    variant="outlined"
                    style={{
                      marginTop: "15px",
                      backgroundColor: "#6c757d",
                      color: "white",
                      borderColor: "#6c757d",
                      float: "right",
                    }}
                    onClick={clear}
                    disabled={
                      !isEligibleDay() ||
                      addDeclarationsStatus ===
                        constants.API_CALL_STATUS.PENDING
                    }
                  >
                    Clear
                  </Button>
                )}
                {editMode && (
                  <Button
                    variant="outlined"
                    style={{
                      marginTop: "15px",
                      backgroundColor: "#6c757d",
                      color: "white",
                      borderColor: "#6c757d",
                      float: "right",
                    }}
                    onClick={cancel}
                  >
                    Cancel
                  </Button>
                )}
                {!editMode && !disableForm && (
                  <Button
                    variant="contained"
                    style={{
                      marginTop: "15px",
                      marginRight: "10px",
                      float: "right",
                    }}
                    disabled={
                      !isEligible ||
                      addDeclarationsStatus ===
                        constants.API_CALL_STATUS.PENDING
                    }
                    onClick={() => {
                      if (
                        declaration.currency &&
                        declaration.quantity &&
                        declaration.type &&
                        declaration.valueDate &&
                        ((declaration.due_date &&
                          toMoment(declaration.valueDate).isSameOrBefore(
                            toMoment(declaration.due_date)
                          )) ||
                          (declaration.expiration_type &&
                            declaration.expiration_time))
                      ) {
                        setconfirm((old) => !old);
                        return;
                      }
                      setInitalState(!initalState);
                    }}
                  >
                    ADD
                  </Button>
                )}
                {editMode && (
                  <Button
                    variant="contained"
                    style={{
                      marginTop: "15px",
                      marginRight: "10px",
                      float: "right",
                    }}
                    onClick={() => {
                      if (
                        declaration.currency &&
                        declaration.quantity &&
                        declaration.type &&
                        declaration.valueDate &&
                        ((declaration.due_date &&
                          toMoment(declaration.valueDate).isSameOrBefore(
                            toMoment(declaration.due_date)
                          )) ||
                          (declaration.expiration_type &&
                            declaration.expiration_time))
                      ) {
                        setconfirm((old) => !old);
                        return;
                      }
                      setInitalState(!initalState);
                    }}
                    disabled={
                      declaration?.status !==
                        constants.DECLARATION.STATUS.DRAFT &&
                      declaration?.status !==
                        constants.DECLARATION.STATUS.SUBMITTED
                    }
                  >
                    EDIT
                  </Button>
                )}
              </FormContainer>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Stack>
      <TableDeclaration
        onDelete={onDelete}
        selectedElements={selectedElements}
        setSelectedIds={(arr) => setSelected(arr, "DEPOSIT")}
        type={"DEPOSIT"}
        list={declarations["DEPOSIT"]
          ?.map(declarationDisplayAdapter)
          ?.map((el) => ({
            ...el,
            expirationDate: getMaturityText(el.maturity),
          }))}
        //  reloadList={addDeclarationCb}
        setEditRow={setEditRow}
        isEligible={isEligible}
      ></TableDeclaration>
      <TableDeclaration
        onDelete={onDelete}
        selectedElements={selectedElements}
        setSelectedIds={(arr) => setSelected(arr, "LOAN")}
        type={"LOAN"}
        list={declarations["LOAN"]
          ?.map(declarationDisplayAdapter)
          ?.map((el) => ({
            ...el,
            expirationDate: getMaturityText(el.maturity),
          }))}
        //  reloadList={addDeclarationCb}
        setEditRow={setEditRow}
        isEligible={isEligible}
      ></TableDeclaration>

      <YesterdayApprovedDialog />
      <ConfirmDeclaration
        open={confirm}
        commentProps={declaration?.comment3}
        handleClose={(comment) => (editMode ? save(comment) : edit(comment))}
      />
      <div style={{ textAlign: "center", marginTop: "-10px" }}>
        {(selectedElements.LOAN.length !== 0 ||
          selectedElements.DEPOSIT.length !== 0) &&
          not(isAcceptedOrRefused(selectedElements)) && (
            <Button
              disabled={!isEligible || !canSubmit}
              variant="contained"
              color="success"
              onClick={submit}
            >
              Submit
            </Button>
          )}
      </div>
    </div>
  );
}

const declarationApiAdapter = (declaration) => ({
  id: declaration?.id,
  currency: {
    id: declaration.currency,
  },
  amount: declaration.quantity,
  type: declaration.type,
  declarationDate: dateToString(declaration.declarationDate),
  valueDate: dateToString(declaration.valueDate),
  maturityTime: declaration.expiration_time,
  maturityUnit: declaration.expiration_type,
  maturityDate: dateToString(declaration.due_date),
});

const declarationDisplayAdapter = (declaration) => ({
  ...declaration,
  id: declaration?.id,
  currency: declaration.currency.id,
  currencyLabel: declaration.currency.label,
  quantity: declaration.amount,
  valueDate: declaration.valueDate,
  maturity: {
    type: isNullOrUndefined(declaration.maturityDate) ? "NUMERIC" : "DATE",
    dueDate: declaration.maturityDate,
    expirationTime: declaration.maturityTime,
    unit: declaration.maturityUnit,
  },
});

const isAcceptedOrRefused = (declarations) => {
  return (
    declarations.LOAN.filter(isAcceptedOrRefusedPredicate).length > 0 ||
    declarations.DEPOSIT.filter(isAcceptedOrRefusedPredicate).length > 0
  );
};

const isAcceptedOrRefusedPredicate = (el) =>
  el.status === "ACCEPTED" || el.status === "REFUSED";

const ConfirmDeclaration = ({ handleClose, open, commentProps }) => {
  const [comment, setcomment] = React.useState("");

  React.useEffect(() => {
    setcomment(commentProps);
  }, [commentProps]);

  const onClick = () => {
    handleClose(comment);
    setcomment("");
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>Please enter a comment</DialogContentText>
        <FormControl sx={{ m: 1, width: "50ch" }} variant="outlined">
          <Input
            autoFocus
            margin="dense"
            id="comment3"
            label="Comment"
            fullWidth
            multiline
            rows={3}
            variant="standard"
            onChange={(e) => setcomment(e.target.value)}
          />
        </FormControl>
        <DialogActions>
          <Button onClick={onClick}>Confirm</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
