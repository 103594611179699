import { Alert, Button, Input, Stack, TextField } from "@mui/material";

import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import constants from "../../common/constants";
import HeaderAlertComponent from "../DailyCash/HeaderAlert.component";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import {
  CustomButton,
  DetailContainer,
  DetailWrapper,
  MainContainer,
  StyledTitle,
} from "./kpi.styles";
import {
  formattingNumber,
  isNullOrUndefined,
  toMoment,
} from "../../UtilFunctions";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { not } from "../../common/utils";
import HeaderAlertGlobalMarket from "../riskIndicators/HeaderAlertGlobalMarket.component";
import {
  changeStatusKpiAction,
  fetchKpiAction,
  submitKpiAction,
} from "../../redux/kpi/kpi.slice";
import {
  selectEligibleDate,
  selectMonthlyDeclarations,
  selectSelectedMonthlyDeclaration,
} from "../../redux/declaration/declaration.selector";
import { CustomTextField } from "../riskIndicators/riskindicators.styles";

export default function KPI() {
  const dispatch = useDispatch();
  const [locale, setLocale] = React.useState("en");
  const [declaration, setDeclaration] = React.useState({});
  const declareData = useSelector(selectSelectedMonthlyDeclaration);
  const MonthlyDeclaration = useSelector(selectMonthlyDeclarations);
  const [canSubmit, setCanSubmit] = useState(false);
  const eligibleDate = useSelector(selectEligibleDate);
  const [saved, setSaved] = useState(true);
  const [status, setStatus] = useState(null);
  const [resultG, setresultG] = useState({
    ASSET: 0,
    LIABILITY: 0,
  });
  const [isEligible, setIsEligible] = useState(false);
  const [reasonReject, setReasonReject] = useState("");

  const [quarterData, setQuarterData] = useState(
    () => "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) - 1 || 4)
  );
  const [selectedYear, setSelectedYear] = useState(() => {
    const date = new Date();
    if (date.getMonth() < 3) date.setFullYear(date.getFullYear() - 1);
    return date;
  });

  React.useEffect(() => {
    setIsEligible(isEligibleMonth());
  }, [eligibleDate, declareData, declaration]);

  React.useEffect(() => {
    if (isNullOrUndefined(declaration)) return () => { };
    const result = Object.keys(declaration).reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr.split("<>")[1]]:
            acc[curr.split("<>")[1]] + Number(declaration[curr]),
        };
      },
      {
        ASSET: 0,
        LIABILITY: 0,
      }
    );
    setresultG(result);
    setCanSubmit(
      result[constants.MONTHLY_DECLARATION_TYPES.ASSET] ===
      result[constants.MONTHLY_DECLARATION_TYPES.LIABILITY]
    );
  }, [declaration]);

  React.useEffect(() => {
    dispatch(
      fetchKpiAction({
        yearKpi: selectedYear.getFullYear(),
        quarter: parseInt(quarterData.substring(1)),
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setDeclaration(resp);
        setStatus(resp?.status ? resp?.status : "DRAFT");
        setReasonReject(resp?.rejectReason);
      });
  }, []);

  const isEligibleMonth = () => {
    return true;
  };



  /*   const onChangeHandler = (e, target) => {
      setSaved(false);
      let text = e.target.value.trim();
      const validName = validNames[e.target.name];
      
      let sanitizedText = text.replace(/[^\d-]/g, '');
    
      if (sanitizedText.startsWith('-')) {
          sanitizedText = sanitizedText.slice(0, 11); 
      } else {
          sanitizedText = sanitizedText.slice(0, 10); 
      }
    
      if (sanitizedText === '' || sanitizedText === '-') {
        setDeclaration({
              ...declaration,
              [`${validName}${target}`]: null, 
          });
      } else {
          const numericValue = parseInt(sanitizedText, 10);
          setDeclaration({
              ...declaration,
              [`${validName}${target}`]: numericValue,
          });
      }
    };
     */

  
  const onChangeHandler = (e, target) => {
    setSaved(false);
    let text = e.target.value.trim();
    const validName = validNames[e.target.name];
  
    let sanitizedText = text.replace(/[^0-9,.-]/g, '');
  
    if (sanitizedText.startsWith('-')) {
      sanitizedText = '-' + sanitizedText.slice(1).replace(/-/g, '');
    }
  
    const decimalIndex = sanitizedText.indexOf('.');
    if (decimalIndex !== -1) {
      const integerPart = sanitizedText.slice(0, decimalIndex);
      const decimalPart = sanitizedText.slice(decimalIndex + 1, decimalIndex + 4); 
      sanitizedText = integerPart + '.' + decimalPart;
    }
      if (sanitizedText.endsWith('.')) {
        sanitizedText += '1';
      }
    const maxDigitsBeforeDecimal = sanitizedText.startsWith('-') ? 11 : 10;
    if (sanitizedText.includes('.')){
      const parts = sanitizedText.split('.');
    sanitizedText = parts[0].slice(0, maxDigitsBeforeDecimal) + (parts[1] ? '.' + parts[1] : '');
    } else {
      const parts = sanitizedText.split(',');
      sanitizedText = parts[0].slice(0, maxDigitsBeforeDecimal) + (parts[1] ? ',' + parts[1] : '');
    }

  
    if (sanitizedText === '') {
      setDeclaration({
        ...declaration,
        [`${validName}${target}`]: null,
      });
    } else if (sanitizedText === '-') {
      setDeclaration({
        ...declaration,
        [`${validName}${target}`]: '-0',
      });
    } else {
      const numericValue = parseFloat(sanitizedText.replace(',', '.'));
      setDeclaration({
        ...declaration,
        [`${validName}${target}`]: numericValue,
      });
    }
  };
  
  const onSubmit = () => {
    dispatch(
      changeStatusKpiAction({
        kpiId: declaration.id,
        status: constants.PROFITABILITY.STATUS.SUBMITTED,
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setDeclaration(resp);
        setStatus(resp?.status ? resp?.status : "DRAFT");
      });
  };
  const onSaveQuarterData = (selectedQuarter) => {
    setQuarterData(selectedQuarter);
    dispatch(
      fetchKpiAction({
        yearKpi: selectedYear.getFullYear(),
        quarter: parseInt(selectedQuarter.substring(1)),
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setDeclaration(resp);
        setStatus(resp?.status ? resp?.status : "DRAFT");
        setReasonReject(resp?.rejectReason);
      });
  };
  const onSaveSelectedYear = (selectedYear) => {
    setSelectedYear(selectedYear);
    dispatch(
      fetchKpiAction({
        yearKpi: selectedYear.getFullYear(),
        quarter: parseInt(quarterData.substring(1)),
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setDeclaration(resp);
        setStatus(resp?.status ? resp?.status : "DRAFT");
        setReasonReject(resp?.rejectReason);
      });
  };

  const onSave = () => {
    dispatch(
      submitKpiAction({
        ...declaration,
        yearKpi: selectedYear.getFullYear(),
        quarter: parseInt(quarterData.substring(1)),
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setSaved(true);
        setDeclaration(resp);
      });
  };

  const onReload = () => {
    dispatch(
      fetchKpiAction({
        yearKpi: selectedYear.getFullYear(),
        quarter: parseInt(quarterData.substring(1)),
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setDeclaration(resp);
        setStatus(resp?.status ? resp?.status : "DRAFT");
        setReasonReject(resp?.rejectReason);
      });
  };

  return (
    <MainContainer>
      <HeaderAlertGlobalMarket
        status={status}
        isMonthly={true}
        declareDate={"declareData.date"}
        locale={locale}
        //onChangeDeclareDate={changeDeclareDate}
        onSaveQuarterData={onSaveQuarterData}
        onSaveSelectedYear={onSaveSelectedYear}
        onReload={onReload}
        name={"KPI GM"}
        reasonReject={reasonReject}
      />
      <StyledTitle>
        <h1>KPI Global Market (USD) </h1>
      </StyledTitle>
      <DetailContainer>
        <DetailWrapper>
          <span>Year to date (N)</span>
          <div className="bloc">
            {kpi.map((asset) => {
              const validName = validNames[asset.title];
              return (
                asset.title !== null && (
                  <div key={`asset---${asset.title}`}>
                    <span>{asset.title}</span>
                    {validName === "ratioKpi" ? (
                      <CustomTextField
                        disabled={status === constants.DECLARATION.STATUS.ACCEPTED}
                        name={asset.title}
                        onChange={(e) =>
                          onChangeHandler(
                            e,
                            constants.MONTHLY_DECLARATION_TYPES.ASSET
                          )
                        }
                        value={
                          declaration[
                            `${validName}${constants.MONTHLY_DECLARATION_TYPES.ASSET}`
                          ] != null
                            ? formattingNumber(
                              declaration[
                              `${validName}${constants.MONTHLY_DECLARATION_TYPES.ASSET}`
                              ]
                            ).toString()
                            : ""
                        }
                        variant="standard"
                        aria-label="Rate"
                        label="%"
                        InputLabelProps={{
                          sx: {
                            textAlign: "end",
                            fontSize: ".75em",
                            transform: "none",
                            right: 0,
                            bottom: 0,
                            top: ".4em",
                            left: "auto",
                          },
                        }}
                        inputProps={{
                          sx: {
                            "&.MuiInput-input.MuiInputBase-input": {
                              width: "8em",
                              marginRight: "1.75em",
                            },
                          },
                          color: "primary",
                          type: "text",
                          step: ".01",
                        }}
                      />
                    ) : (
                      <Input
                        disabled={status === constants.DECLARATION.STATUS.ACCEPTED}
                        value={
                          declaration[
                            `${validName}${constants.MONTHLY_DECLARATION_TYPES.ASSET}`
                          ] != null
                            ? formattingNumber(
                              declaration[
                              `${validName}${constants.MONTHLY_DECLARATION_TYPES.ASSET}`
                              ]
                            ).toString()
                            : ""
                        }
                        name={asset.title}
                        onChange={(e) =>
                          onChangeHandler(
                            e,
                            constants.MONTHLY_DECLARATION_TYPES.ASSET
                          )
                        }
                      />
                    )}
                  </div>
                )
              );
            })}
          </div>
        </DetailWrapper>

        {/*         <DetailWrapper>
          <span>Year to date (N)</span>
          <div className="bloc">
            {kpi.map((asset) => {
              const validName = validNames[asset.title];
              return (
                asset.title !== null && (
                  <div key={`asset---${asset.title}`}>
                    <span>{asset.title}</span>
                    <Input
                      disabled={
                        (
                          status === constants.DECLARATION.STATUS.ACCEPTED) === true
                      }
                      value={declaration[`${validName}${constants.MONTHLY_DECLARATION_TYPES.ASSET}`] != null ? formattingNumber(declaration[`${validName}${constants.MONTHLY_DECLARATION_TYPES.ASSET}`]).toString() : ''}
                      name={asset.title}
                      onChange={(e) =>
                        onChangeHandler(
                          e,
                          constants.MONTHLY_DECLARATION_TYPES.ASSET
                        )
                      }
                    ></Input>
                                        

                  </div>
                )
              );
            })}
          </div>
        </DetailWrapper> */}
        <DetailWrapper>
          <span>Year to date (N-1)</span>
          <div className="bloc">
            {kpi.map((liability) => {
              const validName = validNames[liability.title];
              return (
                liability.title !== null && (
                  <div key={`liability---${liability.title}`}>
                    <span>{liability.title}</span>
                    {validName === "ratioKpi" ? (
                      <CustomTextField
                        disabled={
                          (
                            status === constants.DECLARATION.STATUS.ACCEPTED) === true
                        }
                         name={liability.title}
                        onChange={(e) =>
                          onChangeHandler(
                            e,
                            constants.MONTHLY_DECLARATION_TYPES.LIABILITY
                          )
                        }
                        value={declaration[`${validName}${constants.MONTHLY_DECLARATION_TYPES.LIABILITY}`] != null ? formattingNumber(declaration[`${validName}${constants.MONTHLY_DECLARATION_TYPES.LIABILITY}`]).toString() : ''}
                        variant="standard"
                        aria-label="Rate"
                        label="%"
                        InputLabelProps={{
                          sx: {
                            textAlign: "end",
                            fontSize: ".75em",
                            transform: "none",
                            right: 0,
                            bottom: 0,
                            top: ".4em",
                            left: "auto",
                          },
                        }}
                        inputProps={{
                          sx: {
                            "&.MuiInput-input.MuiInputBase-input": {
                              width: "8em",
                              marginRight: "1.75em",
                            },
                          },
                          color: "primary",
                          type: "text",
                          step: ".01",
                        }}
                      />
                    ) : (
                      <Input
                        disabled={
                          (
                            status === constants.DECLARATION.STATUS.ACCEPTED) === true
                        }
                        /*                       value={formattingNumber(
                                                declaration[
                                                  `${validName}${constants.MONTHLY_DECLARATION_TYPES.LIABILITY}`
                                                ] | 0
                                              )} */
                        value={declaration[`${validName}${constants.MONTHLY_DECLARATION_TYPES.LIABILITY}`] != null ? formattingNumber(declaration[`${validName}${constants.MONTHLY_DECLARATION_TYPES.LIABILITY}`]).toString() : ''}
                        name={liability.title}
                        onChange={(e) =>
                          onChangeHandler(
                            e,
                            constants.MONTHLY_DECLARATION_TYPES.LIABILITY
                          )
                        }
                      ></Input>
                    )}
                  </div>
                )
              );
            })}
          </div>
          {/* <div className="total">
            TOTAL :
            <span>
              {formattingNumber(
                resultG[constants.MONTHLY_DECLARATION_TYPES.LIABILITY]
              )}
            </span>
          </div> */}
        </DetailWrapper>
      </DetailContainer>
      <div style={{ textAlign: "center" }}>
        {/*    {isEligible &&
          (declaration.status === undefined ||
            declaration.status === null ||
            declaration.status === constants.DECLARATION.STATUS.DRAFT) && (
            <CustomButton
              style={{
                color: "#25bbe6",
                borderColor: "#25bbe6",
              }}
              variant="outlined"
              islarge={not(
                isEligible &&
                  Object.keys(declaration).length !== 0 &&
                  declaration.status === constants.DECLARATION.STATUS.DRAFT
              )}
              onClick={onSave}
            >
              {saved ? <BookmarkIcon /> : <TurnedInNotIcon />}
              Save
            </CustomButton>
          )} */}
        {(declaration.status === undefined ||
          declaration.status === null ||
          declaration.status === constants.DECLARATION.STATUS.REJECTED
          || declaration.status === constants.DECLARATION.STATUS.SUBMITTED) && (
            <CustomButton
              variant="outlined"
              style={{
                color: "#00a19a",
                borderColor: "#00a19a",
              }}
              color="success"
              onClick={onSave}
            >
              <SendIcon />
              Submit
            </CustomButton>
          )}
      </div>
    </MainContainer>
  );
}

const liabilities = [
  {
    title: "Customer deposits",
  },
  {
    title: "Interbank MM deposits",
  },
  {
    title: "Interbank trade finance deposit",
  },
  {
    title: "Others interbank deposits",
  },
  {
    title: "Deposits from head office",
  },
  {
    title: "Deposits from subsidiaries",
  },
  {
    title: "Collateralized deposit (Repo, TRS Loans,…)",
  },
  {
    title: "Long term deposit with financial institutions",
  },
  {
    title: "Customer long term deposit",
  },
  {
    title: "Foreign exchange position",
  },
  {
    title: "Others liabilities",
  },
];

const kpi = [
  {
    title: "FX volume with SDM",
  },
  {
    title: "Ratio (FX volume with SDM/Total FX volume)",
  },
  {
    title: "Bond repo or security landing with SDM",
  },
  {
    title: "CMDR Ratio",
  },
];

const validNames = {
  "FX volume with SDM": "fxKpi",
  "Ratio (FX volume with SDM/Total FX volume)": "ratioKpi",
  "Bond repo or security landing with SDM": "bondKpi",
  "CMDR Ratio": "cdmrKpi",
};
