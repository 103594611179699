import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

export default function YesterdayApprovedDialog() {
  let open = false;
  let dispatch = useDispatch();

  function handleClose() {
    dispatch({ type: "YESTERDAY_APPROVED_TOGGLE" });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You cannot submit for today, because yesterdays declaration is not yet
          approved by the admin
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}
