import { deleteMethod, get, post, put } from "../../common/api";
import endpoint from "../../common/api/endpoint";
import _ from 'lodash';

export const getNotificationApi = (  accessToken) =>{
 
    return get(endpoint.NOTIFICATION.GET, null, accessToken);
}


export const markNotificationAsSeenApi = (id, accessToken) =>{
   
    return put(endpoint.NOTIFICATION.MARK_AS_SEEN, null, { id }, accessToken);
}