import { Alert, Button, Input, Stack, TextField } from "@mui/material";

import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import constants from "../../common/constants";
import HeaderAlertComponent from "../DailyCash/HeaderAlert.component";
import { useDispatch, useSelector } from "react-redux";
import {
  addMonthlyDeclarationAction,
  changeMonthlyDeclarationDate,
  fetchMonthlyDeclarationsAction,
  submitMonthlyDeclarationAction,
} from "../../redux/declaration/declaration.slice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  selectEligibleDate,
  selectMonthlyDeclarations,
  selectSelectedMonthlyDeclaration,
  selectSelectedDate,
} from "../../redux/declaration/declaration.selector";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import {
  CustomButton,
  DetailContainer,
  DetailWrapper,
  MainContainer,
  StyledTitle,
} from "./ProfitabilityIndicators.styles";
import {
  formattingNumber,
  isNullOrUndefined,
  toMoment,
} from "../../UtilFunctions";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { not } from "../../common/utils";
import HeaderAlertGlobalMarket from "../riskIndicators/HeaderAlertGlobalMarket.component";
import {
  changeStatusProfitabilityAction,
  fetchProfitabilityAction,
  submitProfitabilityAction,
} from "../../redux/profitability/profitability.slice";

export default function ProfitabilityIndicators() {
  const dispatch = useDispatch();
  const [locale, setLocale] = React.useState("en");
  const [profits, setProfits] = React.useState({});
  const declareData = useSelector(selectSelectedMonthlyDeclaration);
  const MonthlyDeclaration = useSelector(selectMonthlyDeclarations);
  const selectedDate = useSelector(selectSelectedDate);
  const [canSubmit, setCanSubmit] = useState(false);
  const eligibleDate = useSelector(selectEligibleDate);
  const [saved, setSaved] = useState(true);
  const [status, setStatus] = React.useState("DRAFT");
  const [resultG, setresultG] = useState({
    ASSET: 0,
    LIABILITY: 0,
  });
  const [isEligible, setIsEligible] = useState(false);
  const [quarterData, setQuarterData] = useState(
    () => "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) - 1 || 4)
  );
  const [selectedYear, setSelectedYear] = useState(() => {
    const date = new Date();
    if (date.getMonth() < 3) date.setFullYear(date.getFullYear() - 1);
    return date;
  });
  const [reasonReject, setReasonReject] = useState("");

  React.useEffect(() => {
    setIsEligible(isEligibleMonth());
  }, [eligibleDate, declareData, profits]);

  React.useEffect(() => {
    if (isNullOrUndefined(profits)) return () => { };
    const result = Object.keys(profits).reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr.split("<>")[1]]:
            acc[curr.split("<>")[1]] + Number(profits[curr]),
        };
      },
      {
        ASSET: 0,
        LIABILITY: 0,
      }
    );
    setresultG(result);
    setCanSubmit(
      result[constants.MONTHLY_PROFITABILITY_TYPES.ASSET] ===
      result[constants.MONTHLY_PROFITABILITY_TYPES.LIABILITY]
    );
  }, [profits]);

  React.useEffect(() => {
    dispatch(
      fetchProfitabilityAction({
        yearProfitability: selectedYear.getFullYear(),
        quarter: parseInt(quarterData.substring(1)),
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setProfits(resp);
        setStatus(resp?.status ? resp?.status : "DRAFT");
        setReasonReject(resp?.rejectReason);
      });
  }, []);

  React.useEffect(() => {
    dispatch(fetchMonthlyDeclarationsAction());
  }, [declareData, dispatch]);

  React.useEffect(() => {
    dispatch(fetchMonthlyDeclarationsAction());
  }, [dispatch]);

  const changeDeclareDate = (date) => {
    dispatch(
      changeMonthlyDeclarationDate({
        declareDate: date,
      })
    );
  };

  const isEligibleMonth = () => {
    return true;
  };

 
/*    const onChangeHandler = (e, target) => {
    setSaved(false);
    let text = e.target.value.trim();
    const validName = validNames[e.target.name];
    console.log("text ==> ", text);
    if (/^-?\d{0,10}$/.test(text)) {
      const numericValue = parseInt(text, 10);
      setProfits({
        ...profits,
        [`${validName}${target}`]: numericValue,
      });
    } else {
      const numericValue = parseInt(text.replace(/[^\d-]/g, ''), 10);
      setProfits({
        ...profits,
        [`${validName}${target}`]: numericValue,
      });
    }
  };   
 */

const onChangeHandler = (e, target) => {
  setSaved(false);
  let text = e.target.value.trim();
  const validName = validNames[e.target.name];
  
  let sanitizedText = text.replace(/[^\d-]/g, '');

  if (sanitizedText.startsWith('-')) {
      sanitizedText = sanitizedText.slice(0, 11); 
  } else {
      sanitizedText = sanitizedText.slice(0, 10); 
  }

  if (sanitizedText === '' || sanitizedText === '-') {
      setProfits({
          ...profits,
          [`${validName}${target}`]: '-0', 
      });
  } else {
      const numericValue = parseInt(sanitizedText, 10);
      setProfits({
          ...profits,
          [`${validName}${target}`]: numericValue,
      });
  }
};



  const onSubmit = () => {
    dispatch(
      changeStatusProfitabilityAction({
        profitabilityId: profits.id,
        status: constants.PROFITABILITY.STATUS.SUBMITTED,
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setProfits(resp);
      });
  };
  const onSaveQuarterData = (selectedQuarter) => {
    setQuarterData(selectedQuarter);
    dispatch(
      fetchProfitabilityAction({
        yearProfitability: selectedYear.getFullYear(),
        quarter: parseInt(selectedQuarter.substring(1)),
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setProfits(resp);
        setStatus(resp?.status ? resp?.status : "DRAFT");
        setReasonReject(resp?.rejectReason);
      });
  };
  const onSaveSelectedYear = (selectedYear) => {
    setSelectedYear(selectedYear);
    dispatch(
      fetchProfitabilityAction({
        yearProfitability: selectedYear.getFullYear(),
        quarter: parseInt(quarterData.substring(1)),
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setProfits(resp);
        setStatus(resp?.status ? resp?.status : "DRAFT");
        setReasonReject(resp?.rejectReason);
      });
  };

  const onReload = () => {
    setSelectedYear(selectedYear);
    dispatch(
      fetchProfitabilityAction({
        yearProfitability: selectedYear.getFullYear(),
        quarter: parseInt(quarterData.substring(1)),
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setProfits(resp);
        setStatus(resp?.status ? resp?.status : "DRAFT");
        setReasonReject(resp?.rejectReason);
      });
  };

  const onSave = () => {
    dispatch(
      submitProfitabilityAction({
        ...profits,
        yearProfitability: selectedYear.getFullYear(),
        quarter: parseInt(quarterData.substring(1)),
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setSaved(true);
        setProfits(resp);
      });
  };
  return (
    <MainContainer>
      <HeaderAlertGlobalMarket
        status={status}
        isMonthly={true}
        declareDate={"declareData.date"}
        locale={locale}
        onChangeDeclareDate={changeDeclareDate}
        onSaveQuarterData={onSaveQuarterData}
        onSaveSelectedYear={onSaveSelectedYear}
        onReload={onReload}
        name={"Profitability Indicators"}
        reasonReject={reasonReject}
      />
      <StyledTitle>
        <h1>Profitability Indicators (USD) </h1>
      </StyledTitle>
      <DetailContainer>
        <DetailWrapper>
          <span>Year to date (N)</span>
          <div className="bloc">
            {profitability.map((asset) => {
              const validName = validNames[asset.title];
              return (
                asset.title !== null && (
                  <div key={`asset---${asset.title}`}>
                    <span>{asset.title}</span>
                    <Input
                      disabled={
                        (
                          status === constants.DECLARATION.STATUS.ACCEPTED) === true
                      }
        /*          value={formattingNumber(
                        profits[
                        `${validName}${constants.MONTHLY_PROFITABILITY_TYPES.ASSET}`
                        ] | 0
                      )}   */
                      value={profits[`${validName}${constants.MONTHLY_PROFITABILITY_TYPES.ASSET}`] != null ? formattingNumber(profits[`${validName}${constants.MONTHLY_PROFITABILITY_TYPES.ASSET}`]).toString() : ''}
                      name={asset.title}
                      onChange={(e) =>
                          onChangeHandler(
                            e,
                            constants.MONTHLY_PROFITABILITY_TYPES.ASSET
                          )
                        } 
                    ></Input>
                  </div>
                )
              );
            })}
          </div>
          {/* <div className="total">
            TOTAL :
            <span>
              {formattingNumber(
                resultG[constants.MONTHLY_PROFITABILITY_TYPES.ASSET]
              )}
            </span>
          </div> */}
        </DetailWrapper>
        <DetailWrapper>
          <span>Year to date (N-1)</span>
          <div className="bloc">
            {profitability.map((liability) => {
              const validName = validNames[liability.title];
              return (
                liability.title !== null && (
                  <div key={`liability---${liability.title}`}>
                    <span>{liability.title}</span>
                    <Input
                      disabled={
                        (
                          status === constants.DECLARATION.STATUS.ACCEPTED) === true
                      }
              /*         value={formattingNumber(
                        profits[
                        `${validName}${constants.MONTHLY_PROFITABILITY_TYPES.LIABILITY}`
                        ] | 0
                      )} */
                      value={profits[`${validName}${constants.MONTHLY_PROFITABILITY_TYPES.LIABILITY}`] != null ? formattingNumber(profits[`${validName}${constants.MONTHLY_PROFITABILITY_TYPES.LIABILITY}`]).toString() : ''}
                      name={liability.title}
                      onChange={(e) =>
                        onChangeHandler(
                          e,
                          constants.MONTHLY_PROFITABILITY_TYPES.LIABILITY
                        )
                      }
                    ></Input>
                  </div>
                )
              );
            })}
          </div>
          {/* <div className="total">
            TOTAL :
            <span>
              {formattingNumber(
                resultG[constants.MONTHLY_PROFITABILITY_TYPES.LIABILITY]
              )}
            </span>
          </div> */}
        </DetailWrapper>
      </DetailContainer>
      <div style={{ textAlign: "center" }}>
        {/*         {isEligible &&
          (profits.status === undefined ||
            profits.status === null ||
            profits.status === constants.PROFITABILITY.STATUS.DRAFT) && (
            <CustomButton
              style={{
                color: "#25bbe6",
                borderColor: "#25bbe6",
              }}
              variant="outlined"
              islarge={not(
                isEligible &&
                  Object.keys(profits).length !== 0 &&
                  profits.status === constants.PROFITABILITY.STATUS.DRAFT
              )}
              onClick={onSave}
            >
              {saved ? <BookmarkIcon /> : <TurnedInNotIcon />}
              Save
            </CustomButton>
          )} */}
        {(profits.status === undefined ||
          profits.status === null ||
          profits.status === constants.PROFITABILITY.STATUS.REJECTED
          || profits.status === constants.PROFITABILITY.STATUS.SUBMITTED) &&
          <CustomButton
            variant="outlined"
            style={{
              color: "#00a19a",
              borderColor: "#00a19a",
            }}
            color="success"
            onClick={onSave}
          >
            <SendIcon />
            Submit
          </CustomButton>
        }
      </div>
    </MainContainer>
  );
}

const liabilities = [
  {
    title: "Customer deposits",
  },
  {
    title: "Interbank MM deposits",
  },
  {
    title: "Interbank trade finance deposit",
  },
  {
    title: "Others interbank deposits",
  },
  {
    title: "Deposits from head office",
  },
  {
    title: "Deposits from subsidiaries",
  },
  {
    title: "Collateralized deposit (Repo, TRS Loans,…)",
  },
  {
    title: "Long term deposit with financial institutions",
  },
  {
    title: "Customer long term deposit",
  },
  {
    title: "Foreign exchange position",
  },
  {
    title: "Others liabilities",
  },
];

const profitability = [
  {
    title: "FX P&L",
  },
  {
    title: "RWA (FX activity)",
  },
  {
    title: "bonds P&L",
  },
  {
    title: "RWA (bonds activity)",
  },
];

const validNames = {
  "FX P&L": "fxPL",
  "RWA (FX activity)": "fxActivity",
  "bonds P&L": "boundPL",
  "RWA (bonds activity)": "boundActivity",
};
