import { postMethod, get, post, put } from "../../common/api";
import endpoint from "../../common/api/endpoint";
import _ from 'lodash';

export const submitkpiApi = (kpi, accessToken) =>{
    return post(endpoint.KPI.SUBMIT, kpi, null, accessToken);
}

export const fetchtKpiApi = (payload,  accessToken) =>{
    const params = {
        "quarter" : payload.quarter,
        "yearKpi" : payload.yearKpi,
    }
    return get(endpoint.KPI.GET, params, accessToken);
}

export const changeStatuskpiApi = (payload, accessToken) =>{
    const params = {
        "kpiId" : payload.kpiId,
        "status" : payload.status,
    }
    return put(endpoint.KPI.PUT, null, params, accessToken);
}


