import { get, post, put, deleteMethod } from "../../common/api";
import endpoint from "../../common/api/endpoint";
import _ from 'lodash';

export const submitBondPortfolioApi = (bondPortfolio, accessToken) =>{
  const params = {
    "quarter" : bondPortfolio.quarter,
    "yearBondPortfolio" : bondPortfolio.yearBondPortfolio,
}
    return post(endpoint.BONDPORTFOLIO.SUBMIT, bondPortfolio.data, params, accessToken);
}

export const fetchBondPortfolioApi = (payload,  accessToken) =>{
    const params = {
        "quarter" : payload.quarter,
        "yearBondPortfolio" : payload.yearBondPortfolio,
    }
    return get(endpoint.PERIODEBP.GET, params, accessToken);
}

export const updateBondPortfolioStatusApi = (payload,  accessToken) =>{
    const params = {
        "quarter" : payload.quarter,
        "yearBondPortfolio" : payload.yearBondPortfolio,
        "status" : payload?.status,
    }
    return put(endpoint.PERIODEBP.PUT, null,params, accessToken);
}

export const deleteBondPortfolioApi = (bp, accessToken) =>{
    return deleteMethod(endpoint.BONDPORTFOLIO.DELETE, bp, null, accessToken);
}