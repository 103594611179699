import enLocale from "date-fns/locale/en-GB";
import frLocale from "date-fns/locale/fr";
import moment from "moment";

export function dateTransform(backendDate) {
  if (!backendDate) return undefined;
  const elements = backendDate.split("-");
  return elements[2] + "-" + elements[1] + "-" + elements[0];
}

export const localeMap = {
  en: enLocale,
  fr: frLocale,
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getMaturityText = (maturity) => {
  if (maturity?.type === "DATE") return maturity?.dueDate;
  return maturity?.expirationTime + " " + maturity?.unit;
};

export const getMomentDateFromNormal = (normalDate) => {
  if (isNullOrUndefined(normalDate)) return null;
  if (!normalDate.getFullYear || !normalDate.getMonth || !normalDate.getDate)
    return toMoment(normalDate);
  return moment({
    year: normalDate.getFullYear(),
    month: normalDate.getMonth(),
    day: normalDate.getDate(),
  });
};

export const dateToString = (normalDate) => {
  const m = getMomentDateFromNormal(normalDate);
  if (isNullOrUndefined(m)) return m;

  return m.format("DD/MM/YYYY");
};

export const toMoment = (normalDate) => {
  if (isNullOrUndefined(normalDate)) return moment();
  return moment(normalDate, "DD/MM/YYYY");
};

export const isNullOrUndefined = (value) =>
  value === null ||
  value === undefined ||
  value === "undefined" ||
  value === "";

export const decodeJwt = (token) => {
  var base64Url = token.split(".")[1];

  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const isTokenExpired = (token) => {
  const decodedToken = decodeJwt(token);
  var dateNow = new Date();

  return decodedToken.exp * 1000 < dateNow.getTime() + 100;
};

export const formattingNumber = (number) => {
  return Number(number).toLocaleString("fr-FR");
};

export const formatTime = (time) => {
  if (isNullOrUndefined(time)) return "";
  const converted = moment(time);
  return `${converted.format("HH:mm")}:00 `;
};
//(GMT${converted.format('Z').substring(0, 1)}${converted.format('Z').substring(2, 3)})
export const writeInStorage = (key, value) => {
  if (window.sessionStorage) {
    sessionStorage.setItem(key, value);
  }
};

export const readFromStorage = (key) => {
  if (window.sessionStorage) {
    return sessionStorage.getItem(key);
  }
  return null;
};

export const removeFromStorage = (key) => {
  if (window.sessionStorage) {
    sessionStorage.removeItem(key);
  }
};

export const clearStorage = () => {
  if (window.sessionStorage) {
    sessionStorage.clear();
  }
};

/**
 *
 * @param {string|Function} fields field, fields or function that return the field to group by with
 * @param {Object[]} Array of objects subjected to grouping
 * @returns {Object} Object with of which keys represent the grouped values
 */
export function groupBy1(array, fields) {
  if (!Array.isArray(fields)) fields = [fields];
  else if (fields.length === 0) return array;

  const groupBySingleField = (array, field) =>
    array.reduce((acc, elem) => {
      const accKey = elem[field];
      acc = { ...acc, [accKey]: [...(acc[accKey] ?? []), elem] };
      return acc;
    }, {});

  let _result = groupBySingleField(array, fields[0]);
  for (const key in _result) {
    _result[key] = groupBy(_result[key], fields.slice(1));
  }
  return _result;
}

export function groupBy2(arr, fields) {
  let field = fields[0]; // one field at a time
  if (!field) return arr;
  let retArr = Object.values(
    arr.reduce((obj, current) => {
      if (!obj[current[field]])
        obj[current[field]] = { field: field, value: current[field], rows: [] };
      obj[current[field]].rows.push(current);
      return obj;
    }, {})
  );

  // recurse for each child's rows if there are remaining fields
  if (fields.length) {
    retArr.forEach((obj) => {
      obj.count = obj.rows.length;
      obj.rows = groupBy(obj.rows, fields.slice(1));
    });
  }
  return retArr;
}

export function groupBy(rows, groups) {
  const grouped = {};

  rows.forEach(function (a) {
    groups
      .reduce(function (o, g, i) {
        // take existing object,
        o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {}); // or generate new obj, or
        return o[a[g]]; // at last, then an array
      }, grouped)
      .push(a);
  });

  return grouped;
}
