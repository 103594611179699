import {
  configureStore,
  getDefaultMiddleware,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import { logOutClear } from "./authentication/authentication.slice";
import reducers from "./reducers";
import { declarationsApi } from "./declarations/declarations.api";
import { setupListeners } from "@reduxjs/toolkit/query";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: logOutClear,
  effect: async (action, listenerApi) => {
    console.log(listenerApi, listenerApi.getOriginalState());
    console.log(action);
    await listenerApi.delay(5000);
    console.log(listenerApi.getState());
  },
});

const store = configureStore({
  //devTools: not( process.env.NODE_ENV === 'production' ),
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(declarationsApi.middleware),

  reducer: reducers,
});

setupListeners(store.dispatch);

export default store;
