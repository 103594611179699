import RemoveIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import SendIcon from "@mui/icons-material/Send";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  TableFooter,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import { createSvgIcon } from "@mui/material/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AssetLiabilityTable,
  ButtonDeletionDialog,
  NoRowsOverlay,
  PopUpFormModel,
  bondsPositions,
  maturities,
  usdBalance,
} from ".";
import { groupBy } from "../../UtilFunctions";
import {
  selectCurrency,
  selectEntries,
} from "../../redux/balance/balance.selector";
import { deleteBalanceAction, getBalanceAction, setCurrency, submitBalanceAction, updateBalanceStatusAction } from "../../redux/balance/balance.slice";
import { changeMonthlyDeclarationDate } from "../../redux/declaration/declaration.slice";
import {
  useAddBondsEntriesMutation,
  useDeleteBondsEntriesMutation,
  useGetBondsEntriesQuery,
  useSubmitBalanceEntriesMutation,
} from "../../redux/declarations/declarations.api";
import { currencies } from "../../redux/declarations/declarations.slice";
import { submenus } from "../Dashboard";
import HeaderAlertGlobalMarket from "./HeaderAlertGlobalMarket.component";
import {
  CompactTableCell,
  MainContainer,
  MainWrapper,
  PlusIcon,
  StyledCheckbox,
  StyledDataGrid,
  StyledTableCell,
  StyledTableContainer,
  StyledTitle,
  SubmitButton,
} from "./riskindicators.styles";
import { unwrapResult } from "@reduxjs/toolkit";
import constants from "../../common/constants";
import { deleteBondPortfolioAction, getBondPortfolioAction, submitBondPortfolioAction, updateBondPortfolioStatusAction } from "../../redux/bond-portfolio/bond-portfolio.slice";

export default function RiskIndicatorsPage({ onSubClick, subMenuValue }) {
  const dispatch = useDispatch();
  const currency = useSelector(selectCurrency);
  const [locale, setLocale] = useState("en");
  const [isEligible, setIsEligible] = useState(false);
  const [status, setStatus] = useState();
  const [quarterData, setQuarterData] = useState(() => "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) - 1 || 4)
  );
  const [selectedYear, setSelectedYear] = useState(() => {
    const date = new Date();
    if (date.getMonth() < 3) date.setFullYear(date.getFullYear() - 1);
    return date;
  });
  const [reload, setReload] = useState(false);
  const [reasonReject, setReasonReject] = useState("");

  const onSaveQuarterData = (selectedQuarter) => {
    setQuarterData(selectedQuarter);
  };
  const onSaveSelectedYear = (selectedYear) => {
    setSelectedYear(selectedYear);
  };
  const onSetReasonReject = (reason) => {
    setReasonReject(reason);
  };
  const changeDeclareDate = (date) => {
    dispatch(
      changeMonthlyDeclarationDate({
        declareDate: date,
      })
    );
  };

  const updateStatusState = (value) => {
    setStatus(value);
  };

  const handleChange = (event, newValue) => {
    dispatch(setCurrency(newValue));
  };
  const PlusIcon = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>,
    "Plus"
  );
  const columnsRaw = useMemo(
    () => [
      { field: "maturity", label: "Maturity", minWidth: 80 },
      { field: "heading", label: "Heading", minWidth: 80 },
    ],
    []
  );
  const columns = useMemo(() => {
    const _columns = usdBalance
      .filter(({ name }) => /amount|rateType|rate|duration/i.test(name))
      .reverse()
      .map(({ title, name, options }) => ({
        field: name,
        label: title,
        minWidth: 80,
        align: "center",
        format:
          name === "rate" ? (value) => value && `${value}` : (value) => value,
      }));

    _columns.splice(
      _columns.findIndex(({ field }) => field === "rate"),
      0,
      {
        field: "rateType",
        label: "Rate Type",
        minWidth: 80,
        align: "center",
        format: (value) => value,
      }
    );

    return _columns;
  }, []);

  const onReload = () => {
    setReload(!reload);
  };
  return (
    <MainContainer>
      <HeaderAlertGlobalMarket
        status={status}
        isMonthly={true}
        declareDate={"declareData.date"}
        locale={locale}
        onChangeDeclareDate={changeDeclareDate}
        onSaveQuarterData={onSaveQuarterData}
        onSaveSelectedYear={onSaveSelectedYear}
        showReloadBoutton='false'
        onReload={onReload}
        name={subMenuValue}
        reasonReject={reasonReject}
      />
      {subMenuValue === submenus.SUBMENU1 && <Balance quarterData={quarterData} selectedYear={selectedYear} updateStatusState={updateStatusState} reload={reload} onSetReasonReject={onSetReasonReject} />}

      {subMenuValue === submenus.SUBMENU2 && <FxPositions quarterData={quarterData} selectedYear={selectedYear} updateStatusState={updateStatusState} reload={reload} onSetReasonReject={onSetReasonReject} />}

      {subMenuValue === submenus.SUBMENU3 && (
        <BondsPortfolio quarterData={quarterData} selectedYear={selectedYear} isEligible={isEligible} updateStatusState={updateStatusState} reload={reload} onSetReasonReject={onSetReasonReject} />
      )}
    </MainContainer>
  );
}

function Balance(props) {
  const dispatch = useDispatch();
  const currency = useSelector(selectCurrency);
  const [
    submitDeclarations,
    { isLoading: isPendingSubmit, isSuccess: isSubmitted },
  ] = useSubmitBalanceEntriesMutation();
  const [dialog, setDialog] = useState(false);
  const [rows, setRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [handleSubmit, setHandleSubmit] = useState(false);
  const hasDataByMaturity = useMemo(() => {
    const dataByMaturity = {};
    Object.keys(maturities).forEach((maturity) => {
      const assetsData = rows.some(row => row.maturity === maturity && row.category === "assets");
      const liabilitiesData = rows.some(row => row.maturity === maturity && row.category === "liabilities");
      dataByMaturity[maturity] = {
        assets: assetsData,
        liabilities: liabilitiesData,
      };
    });
    return dataByMaturity;
  }, [rows]);

  useEffect(() => {
    setIsLoadingData(true);
    dispatch(getBalanceAction({ yearBalance: props.selectedYear.getFullYear(), quarter: parseInt(props.quarterData.substring(1)), devise: currency }))
      .then(unwrapResult)
      .then((resp) => {
        setRows(resp && resp.balances ? resp.balances : []);
        setShowButton(resp?.status === "ACCEPTED");
        props.updateStatusState(resp?.status ? resp?.status : "DRAFT");
        setIsLoadingData(false);
        props.onSetReasonReject(resp?.rejectReason);
      });
  }, [props.quarterData, props.selectedYear, handleSubmit, props.reload]);
  useEffect(() => {
    dispatch(getBalanceAction({ yearBalance: props.selectedYear.getFullYear(), quarter: parseInt(props.quarterData.substring(1)), devise: currency }))
      .then(unwrapResult)
      .then((resp) => {
        setRows(resp && resp.balances ? resp.balances : []);
        setShowButton(resp?.status === "ACCEPTED");
        props.updateStatusState(resp?.status ? resp?.status : "DRAFT");
        setIsLoadingData(false);
        props.onSetReasonReject(resp?.rejectReason);
      });
  }, []);


  const handleChange = (event, newValue) => {
    setIsLoadingData(true);
    dispatch(getBalanceAction({ yearBalance: props.selectedYear.getFullYear(), quarter: parseInt(props.quarterData.substring(1)), devise: newValue }))
      .then(unwrapResult)
      .then((resp) => {
        setRows(resp && resp.balances ? resp.balances : []);
        dispatch(setCurrency(newValue));
        props.updateStatusState(resp?.status ? resp?.status : "DRAFT");
        setShowButton(resp?.status === "ACCEPTED");
        setIsLoadingData(false);
        props.onSetReasonReject(resp?.rejectReason);
      });
  };

  /*   const columns = useMemo(() => {
      const _columns = usdBalance
        .filter(({ name }) => /amount|rateType|rate|duration/i.test(name))
        .map(({ title, name, options }) => ({
          field: name,
          label: title,
          minWidth: 80,
          align: "center",
          format:
            name === "rate" ? (value) => value && `${value} %` : (value) => value,
        }));
  
      _columns.splice(
        _columns.findIndex(({ field }) => field === "rate"),
        0,
        {
          field: "rateType",
          label: "Rate Type",
          minWidth: 80,
          align: "center",
          format: (value) => value,
        }
      );
      _columns.reverse();
  
      return _columns;
    }, []);  */



  const columns = useMemo(() => {
    const _columns = usdBalance
      .filter(({ name }) => /amount|rateType|rate|duration/i.test(name))
      .map(({ title, name, options }) => ({
        field: name,
        label: title,
        minWidth: 80,
        align: "center",
        format: value => {
          if (name === "rate" && value !== "-") {
            return `${value}`;
          }
          return value;
        }
      }));


    _columns.splice(
      _columns.findIndex(({ field }) => field === "rate"),
      0,
      {
        field: "rateType",
        label: "Rate Type",
        minWidth: 80,
        align: "center",
        format: (value) => value,
      }
    );
    _columns.reverse();

    return _columns;
  }, []);
  const handleSubmitBalance = () => {
    setIsLoadingData(true);
    dispatch(updateBalanceStatusAction({ yearBalance: props.selectedYear.getFullYear(), quarter: parseInt(props.quarterData.substring(1)), status: constants.PROFITABILITY.STATUS.SUBMITTED, currency: currency }))
      .then(unwrapResult)
      .then((resp) => {
        setDialog(false);
        setShowButton(resp?.status === "ACCEPTED");
        setIsLoadingData(false);
        setHandleSubmit(true);
      });
  };

  if (!isLoadingData)
    return (
      <MainWrapper>
        <>
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={currency}
            onChange={handleChange}
            sx={{
              marginTop: "1rem",
            }}
          >
            <Tab
              value={currencies.USD}
              label={currencies.USD}
              sx={{ fontSize: "1.25rem" }}
            />
            <Tab
              value={currencies.EUR}
              label={currencies.EUR}
              sx={{ fontSize: "1.25rem" }}
            />
          </Tabs>
          <StyledTitle>
            <h1>
              <span style={{ color: "#1976d2", textTransform: "uppercase" }}>
                {currency}
              </span>{" "}
              Balance Sheet Situation
            </h1>
          </StyledTitle>
          <div
            style={{
              maxWidth: "100%",
              padding: "5px",
            }}
          >
            {Object.keys(maturities).map((maturity, i) => (
              <Accordion
                key={maturity}
                sx={{
                  "& .MuiAccordionSummary-root": {
                    "&, & *": {
                      color: "#3c6fbb",
                      bgcolor: "white",
                      fontSize: "1.25rem",
                    },
                  },
                  "& .MuiCollapse-root": {
                    bgcolor: "white",
                  },
                }}
                defaultExpanded={hasDataByMaturity[maturity].assets || hasDataByMaturity[maturity].liabilities}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    "&.MuiAccordionSummary-root": {
                      margin: 0,
                      bgcolor: "#f5f8fd",
                      "& *": { bgcolor: "transparent" },
                    },
                  }}
                >
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {maturities[maturity]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction={"row"} gap={2} padding={2}>
                    <MaturityCategoryTable
                      maturity={maturity}
                      category="assets"
                      columns={columns}
                      rows={rows?.filter(
                        (row) =>
                          row.maturity === maturity && row.category === "assets"
                      )}
                      currency={currency}
                      quarterData={props.quarterData}
                      selectedYear={props.selectedYear}
                      showButton={showButton}
                    />
                    <MaturityCategoryTable
                      maturity={maturity}
                      category="liabilities"
                      columns={columns}
                      rows={rows?.filter(
                        (row) =>
                          row.maturity === maturity &&
                          row.category === "liabilities"
                      )}
                      currency={currency}
                      quarterData={props.quarterData}
                      selectedYear={props.selectedYear}
                      showButton={showButton}
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </>
        {showButton ? null :
          <>
            <Dialog open={dialog || isPendingSubmit} onClose={() => setDialog(false)}>
              <DialogTitle>
                Submit Balance declarations ?
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => handleSubmitBalance()}
                  sx={{
                    bgcolor: "primary.main",
                    color: "white",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  {isPendingSubmit ? <ScheduleSendIcon /> : <SendIcon />}
                  &nbsp; YES
                </Button>
              </DialogActions>
            </Dialog>
            <SubmitButton
              disabled={isSubmitted}
              variant="contained"
              onClick={() => setDialog(true)}
            >
              {isPendingSubmit ? <ScheduleSendIcon /> : <SendIcon />}
              Submit
            </SubmitButton>
          </>
        }
      </MainWrapper>
    );


}

function FxPositions({ quarterData, selectedYear, updateStatusState, reload, onSetReasonReject }) {

  return (
    <MainWrapper>
      <StyledTitle>
        <h1>FX Positions</h1>
      </StyledTitle>
      <AssetLiabilityTable term="fxPositions" quarterData={quarterData} selectedYear={selectedYear} updateStatusState={updateStatusState} reload={reload} onSetReasonReject={onSetReasonReject} />
    </MainWrapper>
  );
}

function BondsPortfolio({ isEligible, quarterData, selectedYear, updateStatusState, reload, onSetReasonReject }) {
  const dispatch = useDispatch();
  const dialogStates = React.useRef({
    ADD: "add",
    REMOVE: "remove",
    SUBMIT: "submit",
    NONE: "none",
  }).current;

  const [dialog, setDialog] = useState(false);
  const { data: rows = [], isLoading } = useGetBondsEntriesQuery();
  const [addRows, { data: addedRows = [], isLoading: isAddLoading }] =
    useAddBondsEntriesMutation();
  const [deleteRows, { data: deletedRows = [], isLoading: isDeleteLoading }] =
    useDeleteBondsEntriesMutation();
  const [allRows, setAllRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [handleSubmit, setHandleSubmit] = useState(false);
  const gridApiRef = useRef();
  useEffect(() => {
    dispatch(getBondPortfolioAction({ yearBondPortfolio: selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)) }))
      .then(unwrapResult)
      .then((resp) => {
        setAllRows(resp?.bondPortfolios ? Array.from(resp?.bondPortfolios) : []);
        setShowButton(resp?.status === "SUBMITTED" || resp?.status === "ACCEPTED");
        updateStatusState(resp?.status ? resp?.status : "DRAFT");
        onSetReasonReject(resp?.rejectReason);
      });
    setIsLoadingData(false);
  }, []);

  useEffect(() => {
    setIsLoadingData(true);
    setAllRows([]);
    dispatch(getBondPortfolioAction({ yearBondPortfolio: selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)) }))
      .then(unwrapResult)
      .then((resp) => {
        setAllRows(resp?.bondPortfolios ? Array.from(resp?.bondPortfolios) : []);
        setShowButton(resp?.status === "ACCEPTED");
        updateStatusState(resp?.status ? resp?.status : "DRAFT");
        onSetReasonReject(resp?.rejectReason);
      });
    setIsLoadingData(false);
  }, [quarterData, selectedYear, handleSubmit, reload]);

  function close() {
    setDialog(dialogStates.NONE);
  }

  function openForm() {
    setDialog(dialogStates.ADD);
  }

  function handleAdd(data) {
    dispatch(submitBondPortfolioAction({ data: data, yearBondPortfolio: selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)) }))
      .then(unwrapResult)
      .then((resp) => {
        setIsLoadingData(true);
        setAllRows([]);
        dispatch(getBondPortfolioAction({ yearBondPortfolio: selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)) }))
          .then(unwrapResult)
          .then((resp) => {
            setAllRows(Array.from(resp?.bondPortfolios));
            updateStatusState(resp?.status ? resp?.status : "DRAFT");
            onSetReasonReject(resp?.rejectReason);
          });
        setIsLoadingData(false);
        close();
      });
  }
  async function handleRemove() {
    const selectedEntries = [...gridApiRef.current.getSelectedRows().values()];
    dispatch(deleteBondPortfolioAction(selectedEntries.map(item => item.id)))
      .then(unwrapResult)
      .then((resp) => {
        setIsLoadingData(true);
        setAllRows([]);
        dispatch(getBondPortfolioAction({ yearBondPortfolio: selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)) }))
          .then(unwrapResult)
          .then((resp) => {
            setAllRows(Array.from(resp.bondPortfolios));
            updateStatusState(resp?.status ? resp?.status : "DRAFT");
            onSetReasonReject(resp?.rejectReason);
          });
        setIsLoadingData(false);
        setDialog(false);
      });

  }
  function handleOnSubmit() {
    dispatch(updateBondPortfolioStatusAction({ yearBondPortfolio: selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)), status: constants.PROFITABILITY.STATUS.SUBMITTED }))
      .then(unwrapResult)
      .then((resp) => {
        setShowButton(resp?.status === "ACCEPTED");
        updateStatusState(resp?.status ? resp?.status : "DRAFT");
        setHandleSubmit(true);
      });
  }

  const columns = useMemo(
    () =>
      [
        {
          field: "isin",
          headerName: "ISIN",
          type: "string",
          // headerClassName: "grid--currency-header",
        },
        {
          field: "issuer",
          headerName: "ISSUER",
          type: "string",
        },
        {
          field: "country",
          headerName: "Country",
          type: "string",
        },
        {
          field: "currency",
          headerName: "Currency",
          type: "string",
        },
        {
          field: "transactionDate",
          headerName: "Transaction date",
          type: "date",
          width: 170,
          valueGetter: (params) => {
            if (params.value instanceof Date) {
              return params.value;
            } else {
              const date = new Date(params.value);
              return isNaN(date.getTime()) ? null : date;
            }
          },
        },
        {
          field: "valueDate",
          headerName: "Value date",
          type: "date",
          width: 170,
          valueGetter: (params) => {
            if (params.value instanceof Date) {
              return params.value;
            } else {
              const date = new Date(params.value);
              return isNaN(date.getTime()) ? null : date;
            }
          },
        },
        {
          field: "maturityDate",
          headerName: "Maturity date",
          type: "date",
          width: 170,
          valueGetter: (params) => {
            if (params.value instanceof Date) {
              return params.value;
            } else {
              const date = new Date(params.value);
              return isNaN(date.getTime()) ? null : date;
            }
          },
        },
        {
          field: "faceAmount",
          headerName: "Face amount",
          type: "number",
          width: 170,
        },
        {
          field: "ytm",
          headerName: "YTM (%)",
          type: "number",
        },
        {
          field: "portfolioType",
          headerName: "Portfolio type",
          type: "string",
          width: 170,
        },
      ].map((column) => ({
        ...column,
        headerAlign: column.type === "number" ? "center" : "center",
        align: column.type === "number" ? "center" : "center",
        bgColor: "transparent",
        maxWidth: 250,
        minWidth: 120,
        cellClassName: "grid--bonds__cell",
        headerClassName: "grid--bonds__header",
        // editable
      })),
    []
  );
  if (!isLoadingData)
    return (
      <>
        <StyledTitle>
          <h1>Bonds Portfolio</h1>
        </StyledTitle>

        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "14px",
            alignSelf: "flex-end",
            marginRight: "10px",
          }}
        >
          {showButton ? null :
            <>
              <Button
                variant="contained"
                startIcon={<PlusIcon />}
                style={{ marginRight: "8px" /* , backgroundColor: "#1976d2" */ }}
                onClick={() => openForm()}
                color="primary"
              >
                Add
              </Button>
              <Dialog
                open={dialog === dialogStates.ADD || isAddLoading}
                onClose={close}
              >
                <PopUpFormModel
                  open={dialog === dialogStates.ADD}
                  compact
                  title="Add Bond"
                  nameField="field"
                  titleField="headerName"
                  form={columns}
                  onSubmit={handleAdd}
                  onCancel={close}
                />
              </Dialog>
              <Button
                variant="contained"
                color="error"
                startIcon={<RemoveIcon />}
                // style={{ backgroundColor: "#6c757d" }}
                onClick={() => setDialog(dialogStates.REMOVE)}
              >
                Remove
              </Button>
              <Dialog
                open={dialog === dialogStates.REMOVE || isDeleteLoading}
                onClose={close}
              >
                <DialogTitle>
                  <strong>Delete</strong> declaration entry ?
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={handleRemove}
                    sx={{
                      bgcolor: "primary.main",
                      color: "white",
                      ":hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {
                /* submitStatus.isLoading ? <ScheduleSendIcon /> : */ <SendIcon />
                    }
                    &nbsp; YES
                  </Button>
                </DialogActions>
              </Dialog>
            </>}
        </div>
        <StyledDataGrid
          apiRef={gridApiRef}
          className="table-bonds"
          columns={columns}
          rows={allRows}
          autoHeight
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          showCellVerticalBorder
          showColumnVerticalBorder
          columnHeaderHeight={40}
          rowHeight={45}
          slots={{
            noRowsOverlay: NoRowsOverlay,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 7 } },
          }}
          pageSizeOptions={[7, 15, 25, 45]}
          /*         getRowId={({ isin, issuer, country, currency }) =>
                    `${isin} ${issuer} ${country} ${currency}`
                  } */
          getRowId={({ id }) => id}

          loading={isLoading}
        />
        <Dialog
          open={dialog === dialogStates.SUBMIT /* || submitStatus.isLoading */}
          onClose={close}
        >
          <DialogTitle>
            Submit <strong>Bonds Portfolio</strong> declaration ?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                handleOnSubmit();
                setDialog(false);
              }}
              sx={{
                bgcolor: "primary.main",
                color: "white",
                ":hover": {
                  color: "primary.main",
                },
              }}
            >
              {/* submitStatus.isLoading ? <ScheduleSendIcon /> : */ <SendIcon />}
              &nbsp; YES
            </Button>
          </DialogActions>
        </Dialog>
        {showButton ? null :
          <SubmitButton
            variant="contained"
            onClick={() => setDialog(dialogStates.SUBMIT)}
          >
            <SendIcon />
            Submit
          </SubmitButton>
        }
      </>
    );
}

function MaturityCategoryTable({
  columns,
  rows: initialRows,
  maturity,
  category,
  currency,
  selectedYear,
  quarterData,
  showButton,
}) {
  const dispatch = useDispatch();

  for (let i = 0; i < initialRows.length; i++) {
    const row = initialRows[i];
    if (!('rate' in row)) {
      const rateTypeIndex = 'rateType' in row ? Object.keys(row).indexOf('rateType') : Object.keys(row).length;
      initialRows[i] = Object.keys(row).reduce((acc, key, index) => {
        if (index === rateTypeIndex) {
          acc['rate'] = '-';
        }
        acc[key] = row[key];
        return acc;
      }, {});
    }
  }
  const [rows, setRows] = useState(initialRows);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [selectedRows, setSelectedRows] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    //   dispatch(pages(newPage));
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function onDeleteClick() {
    dispatch(deleteBalanceAction(selectedRows.map(item => item)))
      .then(unwrapResult)
      .then((resp) => {
        setRows((rows) => rows.filter(({ id }) => !selectedRows.includes(id)));
        setSelectedRows([]);
      });
  }

  function onChangeAllCheckbox(e) {
    if (e.target.checked) setSelectedRows(rows.map((e) => e.id));
    else setSelectedRows([]);
  }

  function onChangeRowCheckbox(row, isChecked) {
    if (isChecked) setSelectedRows((prevRows) => [...prevRows, row.id]);
    else setSelectedRows((prevRows) => prevRows.filter((id) => id !== row.id));
  }

  function handleAddClick() {
    setOpenForm(true);
  }

  function handleSubmit(entry) {
    dispatch(submitBalanceAction({ ...entry, yearBalance: selectedYear.getFullYear(), quarter: parseInt(quarterData.substring(1)), status: constants.PROFITABILITY.STATUS.DRAFT }))
      .then(unwrapResult)
      .then((resp) => {
        setRows((rows) => {
          // Vérifier si le champ "rate" doit être ajouté à la nouvelle ligne
          if (!('rate' in resp)) {
            const rateTypeIndex = 'rateType' in resp ? Object.keys(resp).indexOf('rateType') : Object.keys(resp).length;
            const updatedResp = { ...resp };
            updatedResp.rate = '-';
            resp = Object.keys(updatedResp).reduce((acc, key, index) => {
              if (index === rateTypeIndex) {
                acc['rate'] = '-';
              }
              acc[key] = updatedResp[key];
              return acc;
            }, {});
          }
          
          // Ajouter la nouvelle ligne à l'état rows
          return [...rows, resp];
        });
        
        setOpenForm(false);
      });

  }

  function closeForm() {
    setOpenForm(false);
  }

  const headingsMap = useMemo(() => groupBy(rows, ["heading"]), [rows]);

  return (
    /*  <DetailTableWrapper> */
    <Box>
      <Dialog open={openForm} onClose={closeForm}>
        <PopUpFormModel
          title={category}
          form={usdBalance}
          open={openForm}
          onSubmit={handleSubmit}
          onCancel={closeForm}
          defaultData={{ maturity, category, currency }}
        />
      </Dialog>
      <StyledTableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderCollapse: "separate",
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                letterSpacing: "0.16em",
                fontWeight: 500,
                fontSize: "1rem",
              }}
            >

              <CompactTableCell
                align="center"
                sx={{
                  borderStyle: "none",
                  backgroundColor: "#0ab4a904",
                }}
                colSpan={1}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {showButton ?
                    null :
                    <>
                      <Button
                        variant="text"
                        startIcon={<PlusIcon fontSize="large" />}
                        onClick={handleAddClick}
                        sx={{
                          padding: "5px 2px",
                          "&.MuiButton-root": {
                            minWidth: "48px",
                          },
                          "& .MuiButton-startIcon": {
                            margin: 0,
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: "1.5rem",
                          },
                        }}
                      />
                      <ButtonDeletionDialog
                        variant="text"
                        startIcon={<RemoveIcon fontSize="large" />}
                        color="error"
                        sx={{
                          padding: "5px 2px",
                          "&.MuiButton-root": {
                            minWidth: "48px",
                          },
                          "& .MuiButton-startIcon": {
                            margin: 0,
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: "1.5rem",
                          },
                        }}
                        onConfirm={onDeleteClick}
                        canOpen={selectedRows.length}
                      />
                    </>
                  }
                </div>
              </CompactTableCell>
              <CompactTableCell
                sx={{
                  backgroundColor: "#0ab4aa",
                  color: "white",
                  borderRadius: "6px 6px 0 0",
                  textTransform: "uppercase",
                  height: 42,
                }}
                align="center"
                colSpan={columns.length + 1}
              >
                {category}
              </CompactTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                key="heading"
                align={"center"}
                sx={{
                  top: 36,
                  minWidth: 80,
                  background: "#062656",
                  color: "white",
                  fontSize: "0.9rem",
                }}
              >
                Heading
              </StyledTableCell>
              <StyledTableCell
                padding="checkbox"
                align="center"
                sx={{
                  top: 36,
                  bgcolor: "#406db0",
                }}
              >
                <StyledCheckbox
                  size="medium"
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < rows.length
                  }
                  checked={
                    selectedRows.length > 0 &&
                    selectedRows.length === rows.length
                  }
                  onChange={onChangeAllCheckbox}
                  inputProps={{
                    "aria-label": `select all ${category}`,
                  }}
                  sx={{ color: "white" }}
                />
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    top: 36,
                    minWidth: column.minWidth,
                    backgroundColor: "#406db0",
                    color: "white",
                    fontSize: "75%",
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              fontSize: ".9rem",
              "& .MuiTableBody-root": {
                height: "100%",
                overflow: "hidden",
              },
            }}
          >
            {Object.entries(headingsMap)?.flatMap(([heading, headingRows]) => {
              return headingRows.map((headingRow, headingRowIndex) => {
                const isSelected = selectedRows.includes(headingRow.id);
                const headingIsSelected = headingRows.some((r) =>
                  selectedRows.includes(r.id)
                );

                return (
                  <TableRow
                    key={headingRow.id}
                    sx={{
                      bgcolor: isSelected ? "#1976d214" : "transparent",
                    }}
                  >
                    {headingRowIndex === 0 && (
                      <CompactTableCell
                        rowSpan={headingRows.length}
                        scope="rowgroup"
                        sx={{
                          bgcolor: headingIsSelected
                            ? "#1976d214"
                            : "transparent",
                        }}
                      >
                        {heading}
                      </CompactTableCell>
                    )}
                    <StyledTableCell
                      scope="row"
                      padding="checkbox"
                      align="center"
                    >
                      <Checkbox
                        size="medium"
                        onChange={(e) =>
                          onChangeRowCheckbox(headingRow, e.target.checked)
                        }
                        inputProps={{
                          "aria-label": `select all ${category}`,
                        }}
                        checked={isSelected}
                      />
                    </StyledTableCell>
                    {Object.entries(headingRow).map(([field, value]) => {

                      if (/amount|rateType|rate|duration/i.test(field)) {
                        const column = columns.find((c) => c.field === field);
                        const formattedValue = column && column.format ? column.format(value) : value;
                        const renderedValue = column && column.render ? column.render(formattedValue) : formattedValue;
                        return (
                          <StyledTableCell
                            align="center"
                            scope="row"
                            key={`${maturity} ${heading} ${category} ${field}`}
                          >
                            {field === 'amount' ? parseFloat(renderedValue).toLocaleString() : renderedValue}
                          </StyledTableCell>
                        );
                      }
                      return null;
                    })}

                  </TableRow>
                );
              });
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={columns.length + 2}
                rowsPerPageOptions={[5, 15, 25]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </StyledTableContainer>
    </Box>
  );
}


