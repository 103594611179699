import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
const ColumnsConfig = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Currency",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "valueDate",
    numeric: false,
    disablePadding: false,
    label: "value date",
  },
  {
    id: "expirationDate",
    numeric: false,
    disablePadding: false,
    label: "Maturity",
  },
  {
    id: "lastQuotation",
    numeric: false,
    disablePadding: false,
    label: "Last quotation",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

export default ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {ColumnsConfig.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <TableSortLabel hideSortIcon>
              <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
