import { Alert, Button, Input, Stack, TextField } from "@mui/material";

import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import constants from "../../common/constants";
import HeaderAlertComponent from "../DailyCash/HeaderAlert.component";
import { useDispatch, useSelector } from "react-redux";
import {
  addMonthlyDeclarationAction,
  changeMonthlyDeclarationDate,
  fetchMonthlyDeclarationsAction,
  submitMonthlyDeclarationAction,
} from "../../redux/declaration/declaration.slice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  selectEligibleDate,
  selectMonthlyDeclarations,
  selectSelectedMonthlyDeclaration,
} from "../../redux/declaration/declaration.selector";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import {
  CustomButton,
  DetailContainer,
  DetailWrapper,
  MainContainer,
} from "./Balances.styles";
import {
  formattingNumber,
  isNullOrUndefined,
  toMoment,
} from "../../UtilFunctions";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { not } from "../../common/utils";

export default () => {
  const dispatch = useDispatch();
  const [locale, setLocale] = React.useState("en");
  const [declaration, setDeclaration] = React.useState({});
  const declareData = useSelector(selectSelectedMonthlyDeclaration);
  const MonthlyDeclaration = useSelector(selectMonthlyDeclarations);
  const [canSubmit, setCanSubmit] = useState(false);
  const eligibleDate = useSelector(selectEligibleDate);
  const [saved, setSaved] = useState(true);
  const [resultG, setresultG] = useState({
    ASSET: 0,
    LIABILITY: 0,
  });
  const [isEligible, setIsEligible] = useState(false);

  React.useEffect(() => {
    setIsEligible(isEligibleMonth());
  }, [eligibleDate, declareData, declaration]);

  React.useEffect(() => {
    if (isNullOrUndefined(declaration)) return () => {};
    const result = Object.keys(declaration).reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr.split("<>")[1]]:
            acc[curr.split("<>")[1]] + Number(declaration[curr]),
        };
      },
      {
        ASSET: 0,
        LIABILITY: 0,
      }
    );
    setresultG(result);
    setCanSubmit(
      result[constants.MONTHLY_DECLARATION_TYPES.ASSET] ===
        result[constants.MONTHLY_DECLARATION_TYPES.LIABILITY]
    );
  }, [declaration]);

  React.useEffect(() => {
    setDeclaration({
      ...MonthlyDeclaration?.declarations,
    });
  }, [MonthlyDeclaration]);

  React.useEffect(() => {
    dispatch(fetchMonthlyDeclarationsAction());
  }, [declareData]);

  React.useEffect(() => {
    dispatch(fetchMonthlyDeclarationsAction());
  }, []);

  const changeDeclarDate = (date) => {
    dispatch(
      changeMonthlyDeclarationDate({
        declareDate: date,
      })
    );
  };

  const isEligibleMonth = () => {
    // console.log("monthly", eligibleDate, declareData.date);
    return true; //toMoment(eligibleDate).isSame(toMoment(declareData.date), "month");
  };

  const onChangeHandler = (e, target) => {
    setSaved(false);
    const text = e.target.value.replace(/\s/g, "");
    if (!isNaN(text)) {
      if (Number(text) < 1000000000)
        setDeclaration({
          ...declaration,
          [`${e.target.name}<>${target}`]: text,
        });
    } else {
      setDeclaration({
        ...declaration,
        [`${e.target.name}<>${target}`]: text.substring(0, text.length - 1),
      });
    }
  };

  const onSubmit = () => {
    dispatch(submitMonthlyDeclarationAction(declaration))
      .then(unwrapResult)
      .then((resp) => {
        dispatch(fetchMonthlyDeclarationsAction());
      });
  };

  const onSave = () => {
    dispatch(addMonthlyDeclarationAction(declaration))
      .then(unwrapResult)
      .then((resp) => {
        setSaved(true);
        dispatch(fetchMonthlyDeclarationsAction());
      });
  };

  return (
    <MainContainer>
      <HeaderAlertComponent
        isMonthly={true}
        status={declareData.status}
        declareDate={declareData.date}
        locale={locale}
        onChangeDeclareDate={changeDeclarDate}
        comment={
          declareData?.status === constants.DECLARATION.STATUS.REJECTED
            ? MonthlyDeclaration?.comment
            : null
        }
      />
      <DetailContainer>
        <DetailWrapper>
          <span>Assets</span>
          <div className="bloc">
            {assets.map((asset) => {
              return (
                asset.title !== null && (
                  <div key={`asset---${asset.title}`}>
                    <span>{asset.title}</span>
                    <Input
                      disabled={
                        !isEligible ||
                        (declareData.status === undefined ||
                          declareData.status === null ||
                          declareData.status ===
                            constants.DECLARATION.STATUS.DRAFT ||
                          declareData.status ===
                            constants.DECLARATION.STATUS.SUBMITTED) === false
                      }
                      value={formattingNumber(
                        declaration[
                          `${asset.title}<>${constants.MONTHLY_DECLARATION_TYPES.ASSET}`
                        ] | 0
                      )}
                      name={asset.title}
                      onChange={(e) =>
                        onChangeHandler(
                          e,
                          constants.MONTHLY_DECLARATION_TYPES.ASSET
                        )
                      }
                    ></Input>
                  </div>
                )
              );
            })}
          </div>
          <div className="total">
            TOTAL ASSETS:
            <span>
              {formattingNumber(
                resultG[constants.MONTHLY_DECLARATION_TYPES.ASSET]
              )}
            </span>
          </div>
        </DetailWrapper>
        <DetailWrapper>
          <span>Liabilities</span>
          <div className="bloc">
            {liabilities.map((liability) => {
              return (
                liability.title !== null && (
                  <div key={`liability---${liability.title}`}>
                    <span>{liability.title}</span>
                    <Input
                      disabled={
                        !isEligible ||
                        (declareData.status === undefined ||
                          declareData.status === null ||
                          declareData.status ===
                            constants.DECLARATION.STATUS.DRAFT ||
                          declareData.status ===
                            constants.DECLARATION.STATUS.SUBMITTED) === false
                      }
                      value={formattingNumber(
                        declaration[
                          `${liability.title}<>${constants.MONTHLY_DECLARATION_TYPES.LIABILITY}`
                        ] | 0
                      )}
                      name={liability.title}
                      onChange={(e) =>
                        onChangeHandler(
                          e,
                          constants.MONTHLY_DECLARATION_TYPES.LIABILITY
                        )
                      }
                    ></Input>
                  </div>
                )
              );
            })}
          </div>
          <div className="total">
            TOTAL LIABILITIES:
            <span>
              {formattingNumber(
                resultG[constants.MONTHLY_DECLARATION_TYPES.LIABILITY]
              )}
            </span>
          </div>
        </DetailWrapper>
      </DetailContainer>
      <div style={{ textAlign: "center" }}>
        {isEligible &&
          (declareData.status === undefined ||
            declareData.status === null ||
            declareData.status === constants.DECLARATION.STATUS.DRAFT ||
            declareData.status === constants.DECLARATION.STATUS.SUBMITTED) && (
            <CustomButton
              style={{
                color: "#25bbe6",
                borderColor: "#25bbe6",
              }}
              variant="outlined"
              islarge={not(
                isEligible &&
                  Object.keys(MonthlyDeclaration).length !== 0 &&
                  declareData.status === constants.DECLARATION.STATUS.DRAFT
              )}
              onClick={onSave}
            >
              {saved ? <BookmarkIcon /> : <TurnedInNotIcon />}
              Save
            </CustomButton>
          )}
        {isEligible &&
          Object.keys(MonthlyDeclaration).length !== 0 &&
          declareData.status === constants.DECLARATION.STATUS.DRAFT && (
            <CustomButton
              disabled={!canSubmit || !saved}
              variant="outlined"
              style={{
                color: "#00a19a",
                borderColor: "#00a19a",
              }}
              color="success"
              onClick={onSubmit}
            >
              <SendIcon />
              Submit
            </CustomButton>
          )}
      </div>
    </MainContainer>
  );
};

const liabilities = [
  {
    title: "Customer deposits",
  },
  {
    title: "Interbank MM deposits",
  },
  {
    title: "Interbank trade finance deposit",
  },
  {
    title: "Others interbank deposits",
  },
  {
    title: "Deposits from head office",
  },
  {
    title: "Deposits from subsidiaries",
  },
  {
    title: "Collateralized deposit (Repo, TRS Loans,…)",
  },
  {
    title: "Long term deposit with financial institutions",
  },
  {
    title: "Customer long term deposit",
  },
  {
    title: "Foreign exchange position",
  },
  {
    title: "Others liabilities",
  },
];

const assets = [
  {
    title: "Cash (with central bank or others)",
  },
  {
    title: "Interbank MM loans",
  },
  {
    title: "Loans to head office",
  },
  {
    title: "Loans to head office's subsidiaries",
  },
  {
    title: "Collateralized loans (Reverse Repo, TRS Loan..)",
  },
  {
    title: "Customer loans",
  },
  {
    title: "Bonds",
  },
  {
    title: "Others financial assets",
  },
  {
    title: "Foreign exchange position",
  },
  {
    title: "Others assets",
  },
];
