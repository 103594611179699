import { Button, Checkbox, FormControlLabel, Input } from "@mui/material";
import styled, { keyframes } from "styled-components";

const dimensions = {
  FORM_CARD: {
    width: "350px",
    height: "500px",
  },
  LOGO_CONTAINER: {
    width: "100%",
    height: "75px",
  },
};

const AlertTransition = keyframes`
0% {   
    top : -60px;

} 
70% {   
    top: 16px;
}
80% {   
    top: 14px;
}
90% {   
    top: 12px;
}
100% {   
    top: 10px;
}
`;

export const BackgroundImgContainer = styled.div`
  position: absolute;
  top: 30%;
  bottom: 35%;
  right: 0;
  left: 0;
  & > img {
    height: 100%;
    width: 100vw;
  }
`;

export const PageContainer = styled.div`
  height: 100vh !important;
  width: 100vw !important;
  background: white;

  overflow: hidden !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormCardContainer = styled.div`
  z-index: 1000;
  height: ${dimensions.FORM_CARD.height};
  width: ${dimensions.FORM_CARD.width};
  border-radius: 5px;
  background: transparent;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
`;

export const LogoContainer = styled.div`
  height: ${dimensions.LOGO_CONTAINER.height};
  width: ${dimensions.LOGO_CONTAINER.width};

  display: flex;
  align-items: center;
  justify-content: center;

  background: #495a74;
  opacity: 0.8;
  padding: 30px 40px;

  .appLogo {
    height: 40px;
  }
`;

export const LogoImg = styled.img`
  margin: 30px 30px;
`;

export const FormContainer = styled.form`
  padding: 30px 10px 10px 10px;
  text-align: center;
  background: white;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  padding-bottom: 50px;
`;

export const InputField = styled(Input)`
  margin-bottom: 10px !important;
  width: 100%;
  padding: 3px 5%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  font-size: 0.8em !important;
  letter-spacing: 0.14em !important;

  color: black;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black !important;
  }

  .MuiInput-input {
    background: transparent !important;
  }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-bottom: 10px !important;
  width: 100%;
  padding: 3px 5%;
  display: flex;
  color: black !important;
`;

export const CheckboxStyled = styled(Checkbox)``;

export const ButtonStyled = styled(Button)`
  color: #4e607b !important;
  letter-spacing: 0.18em !important;
  font-weight: 600 !important;
  width: 100% !important;
  border: 1px solid rgba(50, 50, 93, 0.25) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-top: 30px !important;
`;

export const AlertContainer = styled.div`
  position: absolute;
  top: 1%;
  left: 30%;
  animation: ${AlertTransition};
  animation-duration: 1s;
`;

export const AppLogo = styled.div`
  height: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.18em;
  font-weight: 500;
  font-size: 0.89em;
  margin-bottom: 30px;
  padding: 20px 10px;

  .appLogo {
    height: 40px;
  }
`;
