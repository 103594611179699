import axios from "axios";
import constants from "../constants";
import qs from "qs";
import random from "lodash/random";
/*
 *header content-type just for post request
 */
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

const api = (accessToken, contentType) => {
  let axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json, */*",
      "Content-Type": contentType,
      "application-name": "FRONTOFFICE",
      uuid: random(100000, 999999),
    },
  };
  axiosConfig.headers["Access-Control-Allow-Origin"] = "*";
  const currentLocation = window.location.href;
  if (accessToken)
    axiosConfig.headers[
      "Authorization"
    ] = `${constants.ATHORIZATION_PREFIX}${accessToken}`;
    
/*   axiosConfig["baseURL"] = "http://10.110.101.76:8080/tresodeviseFO/api";
*/
 axiosConfig["baseURL"] = "https://www.boamarkettreasury.com/tresodeviseFO/api"
 
/*    axiosConfig["baseURL"] = "http://localhost:8080/api";
 */
/*     if (process.env.NODE_ENV === "production")
    axiosConfig["baseURL"] =
      currentLocation.indexOf("www") > 0
        ? currentLocation.split(".")[2].substring(0, 2) === "ma"
          ? process.env[`REACT_APP_BASE_URL_API`].replace("com", "ma")
          : process.env[`REACT_APP_BASE_URL_API`]
        : currentLocation.split(".")[1].substring(0, 2) === "ma"
        ? process.env[`REACT_APP_BASE_URL_API`]
            .replace("com", "ma")
            .replace("www.", "")
        : process.env[`REACT_APP_BASE_URL_API`].replace("www.", ""); */

  return axios.create(axiosConfig);
};

export const get = (url, params, accessToken) => {
  return api(accessToken).get(url, { params });
};

export const post = (url, body, params, accessToken) => {
  return api(accessToken, "application/json").post(
    url,
    JSON.stringify(body, {}),
    { params }
  );
};

export const put = (url, body, params, accessToken) => {
  return api(accessToken, "application/json").put(url, body, { params });
};

export const deleteMethod = (url, body, params, accessToken) => {
  return api(accessToken, "application/json").delete(url, {
    data: body,
  });
};
