import styled from "styled-components";

export default () => {
  return (
    <Container>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;

  .wave {
    width: 10px;

    background: linear-gradient(45deg, cyan, #fff);
    margin: 15px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(1) {
    animation-delay: 0.1s;
    height: 50px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
    height: 100px;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
    height: 150px;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
    height: 200px;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
    height: 250px;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
    height: 200px;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
    height: 150px;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
    height: 100px;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
    height: 50px;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
    height: 25px;
  }

  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
`;
