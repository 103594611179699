import { Avatar, Box, Tab, Tabs, Drawer, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  changeDeclareDate,
  changeMonthlyDeclarationDate,
} from "../../redux/declaration/declaration.slice";
import {
  getNotificationAction,
  markNotificationAsSeenAction,
} from "../../redux/notification/notification.slice";
import Balances from "../Balances";
import ProfitabilityIndicators from "../ProfitabilityIndicators/ProfitabilityIndicators";
import DailyCash from "../DailyCash";
import Navbar from "./Navbar";
import tour from "../../assets/toursvg2022.svg";
import Kpi from "../kpi";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import RiskIndicators from "../riskIndicators/RiskIndicatorsPage";

export const submenus = {
  SUBMENU1: "submenu1",
  SUBMENU2: "submenu2",
  SUBMENU3: "submenu3",
};
export const menu = {
  GLOBAL_MARKET: "Global Market",
  TREASURY: "Treasury",
};
export const treasuryMenu = {
  DAILY_CASH: "Daily cash requirements",
  BALANCE_SHEET: "Balance sheet",
};
export const globalMarketMenu = {
  RISK_INDICATORS: "Risk Indicators",
  PROFITABILITY_INDICATORS: "Profitability Indicators",
  KPI: "KPI GM",
};

export default function Dashboard() {
  const matches = useMediaQuery("(min-width:601px)");
  const [value, setValue] = useState(menu.TREASURY);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  console.log("value", value);

  // const [openSubmenu, setOpenSubmenu] = useState(false);
  // const [openSubmenu2, setOpenSubmenu2] = useState(false);
  const [subMenuValue, setSubMenuValue] = useState(null);
  const [subMenuRiskValue, setSubMenuRiskValue] = useState(null);

  const openTreasuryMenu = value === menu.TREASURY;
  const openGlobalMarketsMenu = value === menu.GLOBAL_MARKET;
  const openRiskIndicatorsMenu =
    value === menu.GLOBAL_MARKET &&
    subMenuValue === globalMarketMenu.RISK_INDICATORS;

  const handleSubMenuClick = (newSubMenuValue) => {
    setSubMenuValue(newSubMenuValue);
  };

  const handleClick = () => {
    // setOpenSubmenu(!openSubmenu);
  };

  useEffect(() => {
    const newSubmenu = openTreasuryMenu
      ? treasuryMenu.DAILY_CASH
      : globalMarketMenu.PROFITABILITY_INDICATORS;

    setSubMenuValue(newSubmenu);
  }, [openTreasuryMenu]);

  useEffect(() => {
    if (!matches) {
      setOpen(false);
    }
  }, [matches]);

  const onSeeNotification = (selected) => {
    dispatch(
      selected.declarationType.toUpperCase() === "DAILY"
        ? changeDeclareDate({
            declareDate: selected.declarationDate,
          })
        : dispatch(
            changeMonthlyDeclarationDate({
              declareDate: selected.declarationDate,
            })
          )
    );

    // setValue(selected.declarationType.toUpperCase() === "DAILY" ? 0 : 1);

    dispatch(markNotificationAsSeenAction({ id: selected.id })).then((__) => {
      dispatch(getNotificationAction());
    });
  };

  const handleChange = (event, newValue) => {
    // if (newValue === 4) {
    //   setOpenSubmenu(true);
    //   setValue(4);
    //   setSubMenuValue(submenus.SUBMENU1);
    //   return;
    // }
    // dispatch({type: "SET_ACTIVE_STEP", activeStep: newValue})
    // setOpenSubmenu(false);
    setValue(newValue);
    setOpen(false);
  };

  const handleBalanceClick = () => {
    setSubMenuRiskValue(submenus.SUBMENU1);
  };
  return (
    <>
      <Navbar
        setShowSider={setOpen}
        showSider={open}
        onSeeNotification={onSeeNotification}
      />
      <MainContainer>
        <SideBackground showsider={matches || open}>
          <ImageContainer>
            <Image src={tour} />
          </ImageContainer>
        </SideBackground>
        <TabsContainer
          sx={{
            display: "flex",
            width: "100%",
            background: "#00294d",
            height: "100%",
          }}
        >
          {(matches || open) && (
            <SideBarStyled
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={"tab-tabs"}
            >
              <Tab
                value={menu.TREASURY}
                label={<span style={{ fontSize: "0.98em" }}>Treasury</span>}
              />
              <Collapse in={openTreasuryMenu} timeout="auto" unmountOnExit>
                <List>
                  <ListItemButton
                    style={{
                      borderBottomLeftRadius: "20px",
                      borderTopLeftRadius: "3px",
                      marginLeft: "10%",
                      paddingLeft: 25,
                      background:
                        subMenuValue === treasuryMenu.DAILY_CASH
                          ? "#b4b309"
                          : "transparent",
                    }}
                    onClick={() => setSubMenuValue(treasuryMenu.DAILY_CASH)}
                  >
                    <ListItemText primary={treasuryMenu.DAILY_CASH} />
                  </ListItemButton>

                  <ListItemButton
                    style={{
                      borderBottomLeftRadius: "20px",
                      borderTopLeftRadius: "3px",
                      marginLeft: "10%",
                      paddingLeft: 25,
                      background:
                        subMenuValue === treasuryMenu.BALANCE_SHEET
                          ? "#b4b309"
                          : "transparent",
                    }}
                    onClick={() => setSubMenuValue(treasuryMenu.BALANCE_SHEET)}
                  >
                    <ListItemText primary={treasuryMenu.BALANCE_SHEET} />
                  </ListItemButton>
                </List>
              </Collapse>
              <Tab
                value={menu.GLOBAL_MARKET}
                label={
                  <span style={{ fontSize: "0.98em" }}>Global Markets</span>
                }
              />
              <Collapse in={openGlobalMarketsMenu} timeout="auto" unmountOnExit>
                <List>
                  <ListItemButton
                    style={{
                      borderBottomLeftRadius: "20px",
                      borderTopLeftRadius: "3px",
                      marginLeft: "10%",
                      paddingLeft: 25,
                      background:
                        subMenuValue ===
                        globalMarketMenu.PROFITABILITY_INDICATORS
                          ? "#b4b309"
                          : "transparent",
                    }}
                    onClick={() =>
                      setSubMenuValue(globalMarketMenu.PROFITABILITY_INDICATORS)
                    }
                  >
                    <ListItemText
                      primary={globalMarketMenu.PROFITABILITY_INDICATORS}
                    />
                  </ListItemButton>
                  <ListItemButton
                    style={{
                      borderBottomLeftRadius: "20px",
                      borderTopLeftRadius: "3px",
                      marginLeft: "10%",
                      paddingLeft: 25,
                      background:
                        subMenuValue === globalMarketMenu.KPI
                          ? "#b4b309"
                          : "transparent",
                    }}
                    onClick={() => setSubMenuValue(globalMarketMenu.KPI)}
                  >
                    <ListItemText primary={globalMarketMenu.KPI} />
                  </ListItemButton>
                  <ListItemButton
                    style={{
                      borderBottomLeftRadius: "20px",
                      borderTopLeftRadius: "3px",
                      marginLeft: "10%",
                      paddingLeft: 25,
                      background:
                        subMenuValue === globalMarketMenu.RISK_INDICATORS
                          ? "#8d8d07"
                          : "transparent",
                    }}
                    onClick={() => {
                      setSubMenuValue(globalMarketMenu.RISK_INDICATORS);
                      setSubMenuRiskValue(submenus.SUBMENU1);
                    }}
                  >
                    <ListItemText primary={globalMarketMenu.RISK_INDICATORS} />
                  </ListItemButton>
                  <Collapse
                    in={openRiskIndicatorsMenu}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <ListItemButton
                        style={{
                          borderBottomLeftRadius: "20px",
                          borderTopLeftRadius: "3px",
                          marginLeft: "18%",
                          paddingLeft: 25,
                          background:
                            subMenuRiskValue === submenus.SUBMENU1
                              ? "#b4b309"
                              : "transparent",
                        }}
                        onClick={() => setSubMenuRiskValue(submenus.SUBMENU1)}
                      >
                        <ListItemText primary="Balance" />
                      </ListItemButton>
                      <ListItemButton
                        style={{
                          borderBottomLeftRadius: "20px",
                          borderTopLeftRadius: "3px",
                          marginLeft: "18%",
                          paddingLeft: 25,
                          background:
                            subMenuRiskValue === submenus.SUBMENU2
                              ? "#b4b309"
                              : "transparent",
                        }}
                        onClick={() => setSubMenuRiskValue(submenus.SUBMENU2)}
                      >
                        <ListItemText primary="FX positions" />
                      </ListItemButton>
                      <ListItemButton
                        style={{
                          borderBottomLeftRadius: "20px",
                          borderTopLeftRadius: "3px",
                          marginLeft: "18%",
                          paddingLeft: 25,
                          background:
                            subMenuRiskValue === submenus.SUBMENU3
                              ? "#b4b309"
                              : "transparent",
                        }}
                        onClick={() => setSubMenuRiskValue(submenus.SUBMENU3)}
                      >
                        <ListItemText primary="Bonds portfolio" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              </Collapse>
              {/* <Tab
                label={
                  <span style={{ fontSize: "0.98em" }}>
                    Profitability indicators
                  </span>
                }
              />
              <Tab label={<span style={{ fontSize: "0.98em" }}>KPI</span>} />
              <Tab
                label={
                  //  <div onClick={handleClick}>
                  <span style={{ fontSize: "0.98em" }}>Risk Indicators</span>
                  //  </div>
                }
              /> */}
              {/* <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
                <List>
                  <ListItemButton
                    style={{
                      borderBottomLeftRadius: "20px",
                      borderTopLeftRadius: "3px",
                      marginLeft: "10%",
                      paddingLeft: 25,
                      background:
                        subMenuValue === submenus.SUBMENU1
                          ? "#b4b309"
                          : "transparent",
                    }}
                    onClick={() => setSubMenuValue(submenus.SUBMENU1)}
                  >
                    <ListItemText primary="Balance sheet" />
                  </ListItemButton>

                  <ListItemButton
                    style={{
                      borderBottomLeftRadius: "20px",
                      borderTopLeftRadius: "3px",
                      marginLeft: "10%",
                      paddingLeft: 25,
                      background:
                        subMenuValue === submenus.SUBMENU2
                          ? "#b4b309"
                          : "transparent",
                    }}
                    onClick={() => setSubMenuValue(submenus.SUBMENU2)}
                  >
                    <ListItemText primary="FX positions" />
                  </ListItemButton>

                  <ListItemButton
                    style={{
                      borderBottomLeftRadius: "20px",
                      borderTopLeftRadius: "3px",
                      marginLeft: "10%",
                      paddingLeft: 25,
                      background:
                        subMenuValue === submenus.SUBMENU3
                          ? "#b4b309"
                          : "transparent",
                    }}
                    onClick={() => setSubMenuValue(submenus.SUBMENU3)}
                  >
                    <ListItemText primary="Bonds portfolio" />
                  </ListItemButton>
                </List>
              </Collapse> */}
            </SideBarStyled>
          )}

          <Container onClick={() => setOpen(false)}>
            {value === menu.TREASURY &&
              (subMenuValue === treasuryMenu.DAILY_CASH ? (
                <DailyCash />
              ) : subMenuValue === treasuryMenu.BALANCE_SHEET ? (
                <Balances />
              ) : null)}
            {value === menu.GLOBAL_MARKET &&
              (subMenuValue === globalMarketMenu.PROFITABILITY_INDICATORS ? (
                <ProfitabilityIndicators />
              ) : subMenuValue === globalMarketMenu.KPI ? (
                <Kpi />
              ) : null)}
            {/* {value === 1 && <Balances />} */}
            {/* {value === 2 && <ProfitabilityIndicators />} */}
            {/* {value === 3 && <Kpi />} */}
            {value === menu.GLOBAL_MARKET &&
              subMenuValue === globalMarketMenu.RISK_INDICATORS && (
                <RiskIndicators
                  onSubClick={handleSubMenuClick}
                  subMenuValue={subMenuRiskValue}
                />
              )}
          </Container>
        </TabsContainer>
      </MainContainer>
    </>
  );
}

const TabsContainer = styled(Box)`
  background: #062656 !important; /* fallback for old browsers */
  z-index: 100;
`;

const MainContainer = styled.div`
  height: calc(100% - 90px);
  width: 100%;
`;

const Container = styled.div`
  //  border-top-left-radius: 22px;
  background: white;
  width: calc(100% - 210px);
  height: calc(100% - 90px);
  overflow: auto;
  position: absolute !important;
  top: 90px;
  left: 210px;
  padding-top: 20px;
  z-index: 2000;

  @media (max-width: 600px) {
    width: calc(100%);
    left: 0px;
  }

  animation-name: from-bottom-to-top;
  //animation-duration: 2s;
  animation-timing-function: linear;
  z-index: 0;
  @keyframes from-bottom-to-top {
    0% {
      left: calc(-100vw + 225px);
    }
    100% {
      left: 220px;
    }
  }
`;

const SideBackground = styled.div`
  height: 92%;
  display: ${(props) => (props.showsider === true ? undefined : "none")};
  background: #062656; /* fallback for old browsers */

  position: absolute;
  z-index: 100;
`;

const ImageContainer = styled.div`
  z-index: 1000;
  height: 98%;
  width: 210px;
  display: flex;
  align-items: flex-end;
  opacity: 0.3;
`;
const Image = styled.img`
  width: 210px;
  position: absolute;
  bottom: 10px;

  animation-name: from-center-to-corner;
  // animation-duration: 2s;
  animation-timing-function: ease;

  @keyframes from-center-to-corner {
    0% {
      width: 400px;
      bottom: 30%;

      left: 30vw;
    }
    100% {
      bottom: 10px;
      width: 220px;
      left: 0;
    }
  }
`;

const SideBarStyled = styled(Tabs)`
  padding-top: 20px;
  width: 210px;
  /* max-width: 220px;
  min-width: 180px; */
  background: transparent;
  z-index: 101;

  .MuiButtonBase-root {
    color: white !important;
    letter-spacing: 0.15em;
    font-size: 0.7em;
    text-align: left;
  }
  .Mui-selected {
    color: white !important;
    background: #0ab4aa !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 5px;
    margin: 2px;
  }
  .MuiTabs-indicator {
    background-color: none;
    width: 0px;
  }
  .MuiButtonBase-root {
    :hover {
      background: #17b1ca !important;
      border-radius: 5px;
      margin: 1px;
    }
  }
`;
