import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import React from "react";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Input,
  Button,
  IconButton,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormGroup,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Tooltip,
  Slide,
} from "@mui/material";
import EnhancedTableHead from "./EnhancedTableHead";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  dateToString,
  formattingNumber,
  getComparator,
  stableSort,
  toMoment,
} from "../../UtilFunctions";
import constants from "../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDeclarationsAction,
  performAction,
} from "../../redux/declaration/declaration.slice";
import {
  selectContreparties,
  selectSelectedDate,
} from "../../redux/declaration/declaration.selector";
import { DialogFormContainer } from "./dailyCash.styles";
import { useEffect } from "react";
import moment from "moment";

export default function TableDeclaration({
  type,
  list = [],
  setEditRow,
  setSelectedIds,
  selectedElements,
  onDelete,
  isEligible,
}) {
  const dispatch = useDispatch();
  const [canDelete, setCanDelete] = React.useState(true);
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const declareDate = useSelector(selectSelectedDate);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [payload, setPayload] = React.useState(null);

  const [comment, setComment] = React.useState(null);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  React.useEffect(() => {
    setSelectedIds(selected);

    setCanDelete(
      !list
        ?.filter((r) => selected.includes(r.id))
        .reduce((acc, curr) => acc || curr.hasOwnProperty("quotations"), false)
    );
  }, [selected]);

  React.useEffect(() => {
    if (selected.length !== selectedElements[type].length)
      setSelected(selectedElements[type]);
  }, [type, selectedElements]);

  const editSelected = () => {
    setEditRow(selectedRow);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list
        .filter(
          (n) =>
            n.status === constants.DECLARATION.STATUS.DRAFT ||
            n.status === constants.DECLARATION.STATUS.SUBMITTED
        )
        .map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
      setSelectedRow(row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const showComment = (lcomment) => {
    setComment(lcomment);
  };

  const onExecuteActionOnQuotation = (
    declarationId,
    action,
    comment,
    conterparty
  ) => {
    dispatch(
      performAction({
        declarationId,
        action,
        comment,
        contreparty: conterparty ? conterparty?.contreparty : undefined,
        contrepartyQuotation: conterparty ? conterparty?.quotation : undefined,
        succeeded: conterparty ? conterparty?.succeeded : undefined,
        comment2: conterparty ? conterparty?.comment2 : undefined,
      })
    )
      .then(unwrapResult)
      .then((resp) => {
        setPayload(null);
        dispatch(
          fetchDeclarationsAction({
            dateDeclaration: dateToString(declareDate),
          })
        );
      });
  };

  return (
    <div className={"div-table"}>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            canDelete={canDelete}
            numSelected={selectedElements[type].length}
            specificCountSelected={selectedElements[type].length}
            type={type}
            deleteSelected={() => onDelete(type)}
            editSelected={editSelected}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selectedElements[type].length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={
                  list?.filter(
                    (n) =>
                      n.status === constants.DECLARATION.STATUS.DRAFT ||
                      n.status === constants.DECLARATION.STATUS.SUBMITTED
                  )?.length
                }
              />
              <TableBody>
                {stableSort(list, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = selected.indexOf(row.id) !== -1;
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`row-declaration--${row.id}`}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          {(isEligible ||
                            moment().isSameOrBefore(
                              toMoment(row?.valueDate),
                              "day"
                            )) &&
                            (row.status ===
                              constants.DECLARATION.STATUS.DRAFT ||
                              row.status ===
                                constants.DECLARATION.STATUS.SUBMITTED) && (
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                onClick={(event) =>
                                  (row.status ===
                                    constants.DECLARATION.STATUS.DRAFT ||
                                    row.status ===
                                      constants.DECLARATION.STATUS.SUBMITTED) &&
                                  handleClick(event, row)
                                }
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            )}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.currencyLabel}
                        </TableCell>
                        {/*<TableCell>{row.type}</TableCell>*/}
                        <TableCell>
                          {formattingNumber(row.quantity | 0)}
                        </TableCell>
                        <TableCell>{row.valueDate}</TableCell>
                        <TableCell>{row.expirationDate}</TableCell>
                        <TableCell>
                          {row?.quotations && row?.quotations?.length > 0 ? (
                            row?.quotations[0].status === "SUBMITTED" &&
                            (isEligible ||
                              moment().isSameOrBefore(
                                toMoment(row?.valueDate),
                                "day"
                              )) ? (
                              <>
                                <HourglassTopIcon />
                                {row?.quotations[0].amount}
                                <IconButton
                                  onClick={(__) =>
                                    setPayload({
                                      id: row.id,
                                      action: "ACCEPT",
                                      amount: row?.quotations[0].amount,
                                    })
                                  }
                                >
                                  <CheckIcon color="success" />
                                </IconButton>
                                <IconButton
                                  onClick={(__) =>
                                    setPayload({
                                      id: row.id,
                                      action: "REFUSE",
                                      amount: row?.quotations[0].amount,
                                    })
                                  }
                                >
                                  <CloseIcon color="error" />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                {row?.quotations[0].status === "ACCEPTED" ? (
                                  <CheckIcon />
                                ) : (
                                  <CloseIcon />
                                )}
                                {row?.quotations[0].amount}
                                {row?.status === "REFUSED" && (
                                  <Button
                                    color={
                                      row?.contreparty ? "success" : "error"
                                    }
                                    onClick={(__) => {
                                      setPayload({
                                        id: row.id,
                                        action: row?.contreparty
                                          ? "SHOW_DETAILS"
                                          : isEligible
                                          ? "ENTER_CONTERPARTY"
                                          : "SHOW_DETAILS",
                                        quotation: row?.quotationContreparty,
                                        contreparty: row?.contreparty,
                                        comment2: row?.comment2,
                                        succeeded: row?.succeeded,
                                      });
                                    }}
                                  >
                                    {row?.contreparty
                                      ? "more details"
                                      : "select counterparty"}
                                  </Button>
                                )}
                              </>
                            )
                          ) : null}
                        </TableCell>
                        <TableCell>{row.status}</TableCell>
                        {row?.comment3 !== null &&
                          row?.comment3?.trim().length > 0 && (
                            <TableCell width={5}>
                              <IconButton
                                onClick={() => showComment(row?.comment3)}
                              >
                                <InsertCommentIcon />
                              </IconButton>
                            </TableCell>
                          )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <ShowComment comment={comment} setComment={setComment} />
          <ConfirmActionModal
            payload={payload}
            setPayload={setPayload}
            onExecuteActionOnQuotation={onExecuteActionOnQuotation}
          />
        </Paper>
      </Box>
    </div>
  );
}

const ConfirmActionModal = ({
  payload,
  setPayload,
  onExecuteActionOnQuotation,
}) => {
  const [comment, setComment] = React.useState("");
  const [formValues, setFormValues] = React.useState({
    contreparty: "",
    quotation: 0,
    comment2: "",
    succeeded: true,
  });
  const contrepparties = useSelector(selectContreparties);

  useEffect(() => {
    if (payload?.quotation !== undefined && payload?.contreparty !== undefined)
      setFormValues({
        contreparty: payload?.contreparty?.id,
        quotation: payload?.quotation,
        comment2: payload?.comment2 ? payload?.comment2 : "",
        succeeded: payload?.succeeded === true ? true : false,
      });
  }, [payload]);

  return (
    <Dialog
      open={payload !== null}
      onClose={() => setPayload(null)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {payload?.action === "ENTER_CONTERPARTY" ||
        payload?.action === "SHOW_DETAILS"
          ? "Select a counterparty"
          : "Action confirmation"}
      </DialogTitle>
      <DialogContent>
        {payload?.action === "ENTER_CONTERPARTY" ||
        payload?.action === "SHOW_DETAILS" ? (
          <DialogFormContainer>
            <FormGroup sx={{ m: 1 }}>
              <FormLabel id="demo-form-control-label-placement">
                {`Declaration succeeded`}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="succeeded"
                value={formValues?.succeeded === true ? true : false}
                onChange={(e) => {
                  setFormValues((old) => ({
                    ...old,
                    succeeded: e.target.value,
                  }));
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormGroup>
            <FormControl fullWidth>
              <InputLabel id="simple-select-label">Counterparty</InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                label="Counterparty"
                disabled={payload?.action === "SHOW_DETAILS"}
                className="select-contreparty"
                value={formValues?.contreparty}
                onChange={(e) => {
                  setFormValues((old) => ({
                    ...old,
                    contreparty: e.target.value,
                  }));
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {contrepparties?.map((contreparty) => (
                  <MenuItem
                    key={`form-contreparty-${contreparty.id}`}
                    value={`${contreparty.id}`}
                  >
                    {contreparty?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: "30ch" }}>
              <TextField
                label="Quotation"
                type="number"
                disabled={payload?.action === "SHOW_DETAILS"}
                value={formValues?.quotation}
                onChange={(e) => {
                  setFormValues((old) => ({
                    ...old,
                    quotation: e.target.value,
                  }));
                }}
              ></TextField>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                label="Comment"
                type="text"
                multiline
                rows={4}
                disabled={payload?.action === "SHOW_DETAILS"}
                value={formValues?.comment2}
                onChange={(e) => {
                  setFormValues((old) => ({
                    ...old,
                    comment2: e.target.value,
                  }));
                }}
              ></TextField>
            </FormControl>
          </DialogFormContainer>
        ) : (
          <>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure that you want ${payload?.action} the quotation :${payload?.amount} ? `}
            </DialogContentText>
            {payload?.action === "REFUSE" && (
              <DialogFormContainer>
                <TextField
                  className="input"
                  id="outlined-multiline-flexible"
                  label="Comment"
                  placeholder="Please enter your comment here"
                  multiline
                  rows={4}
                  value={comment}
                  onChange={(event) => setComment(event.target.value)}
                />
              </DialogFormContainer>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPayload(null)}>Dismiss</Button>
        {payload?.action !== "SHOW_DETAILS" && (
          <Button
            disabled={
              !(
                (payload?.action === "REFUSE" && comment.trim().length > 0) ||
                payload?.action === "ACCEPT" ||
                (payload?.action === "ENTER_CONTERPARTY" &&
                  formValues.contreparty > 0 &&
                  formValues.quotation > 0)
              )
            }
            onClick={() => {
              onExecuteActionOnQuotation(
                payload?.id,
                payload?.action,
                comment,
                formValues
              );
              setComment("");
              setFormValues({
                contreparty: "",
                quotation: 0,
                succeeded: true,
                comment: "",
              });
            }}
            autoFocus
          >
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ShowComment = ({ comment, setComment }) => {
  const handleClose = () => {
    setComment(null);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={comment !== null}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {comment}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
