import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledTitle = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;

  color: #333; // Dark grey text

  h1 {
    /* text-decoration: underline; */
    font-size: 1.5rem; // Adjust the font size
  }
`;

export const CustomButton = styled(Button)`
  margin: 0px 14px !important;
  width: ${(props) => (props.islarge ? "220px" : "170px")};
  border-radius: 10px !important;

  .MuiSvgIcon-root {
    margin: 0px 10px !important;
  }
`;

export const DetailContainer = styled.div`
  width: calc(100vw - 240px);
  max-width: calc(100vw - 240px);
  min-width: 1000px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px 0px;
  font-size: 0.8em;
  margin-bottom: 50px;
  padding-bottom: 70px;
`;

export const DetailWrapper = styled.div`
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  width: 45%;
  min-height: 400px;
  margin: 5px 10px;

  > span {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: larger;
    letter-spacing: 0.16em;
    background: #0ab4aa;
    color: white;
    text-transform: uppercase;

    border-radius: 15px 15px 0px 0px;
    height: 50px;
    padding-top: 12px;
  }
  .bloc {
    padding: 30px 20px;
  }

  > div > div {
    display: flex;
    justify-content: space-between;

    :focus-within {
      font-weight: 700;
    }

    > span {
      min-width: 120px;
    }
    .MuiInput-input {
      width: 100px;
      min-width: 100px;
      text-align: right;
      font-size: 0.8em;
    }
  }
`;

export const MainContainer = styled.div`
  width: calc(100vw - 240px);
  min-width: 1000px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .total {
    padding: 10px 20px;
    padding-bottom: 40px;
    font-size: 1.1em;
    & > span {
      margin-left: 10px;
      font-weight: 600;
    }
  }
`;
