import { postMethod, get, post, put } from "../../common/api";
import endpoint from "../../common/api/endpoint";
import _ from 'lodash';


export const submitProfitabilityApi = (profitability, accessToken) =>{
    return post(endpoint.PROFITABILITY.SUBMIT, profitability, null, accessToken);
}

export const fetchtProfitabilitApi = (payload,  accessToken) =>{
    const params = {
        "quarter" : payload.quarter,
        "yearProfitability" : payload.yearProfitability,
    }
    return get(endpoint.PROFITABILITY.GET, params, accessToken);
}

export const changeStatusProfitabilityyApi = (payload, accessToken) =>{
    const params = {
        "profitabilityId" : payload.profitabilityId,
        "status" : payload.status,
    }
    return put(endpoint.PROFITABILITY.PUT, null, params, accessToken);
}


