import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
export default ({
  numSelected,
  specificCountSelected,
  deleteSelected,
  editSelected,
  type,
  canDelete,
}) => {
  return (
    <Toolbar
      style={{ border: "1px solid rgba(0, 0, 0, 0.12)", minHeight: "unset" }}
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        bgcolor: "rgba(0, 0, 0, .03)",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {type == "LOAN" ? "Loan" : "Deposit"}
        </Typography>
      )}

      {numSelected == 1 && specificCountSelected == 1 ? (
        <Tooltip title="Edit">
          <IconButton onClick={editSelected}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : undefined}
      {numSelected > 0 && canDelete ? (
        <Tooltip title="Delete">
          <IconButton onClick={deleteSelected}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
};

{
  /* <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip> */
}
