const endpoint = {
  AUTH: {
    REFRESH_TOKEN: "/auth/refresh-token",
    LOGIN: "/auth/login",
    CHANGE_PASSWORD: "/auth/change-password",
    LOGOUT: "/auth/logout",
  },
  DECLARATION: {
    ADD: "/declarations/add",
    GET: "/declarations/",
    SUBMIT: "/declarations/submit",
    DELETE: "/declarations/",
    ADD_MONTHLY: "/declarations/monthly",
    GET_MONTHLY: "/declarations/monthly",
    SUBMIT_MONTHLY: "/declarations/monthly/submit",
    EXECUTE_ACTION: "/declarations/perform-action",
  },
  PROFITABILITY: {
    SUBMIT: "/profitability/submit-profitability",
    GET: "/profitability/by-period",
    PUT: "/profitability/submit-status",
  },
  KPI: {
    SUBMIT: "/kpi/submit-kpi",
    GET: "/kpi/by-period",
    PUT: "/kpi/submit-status",
  },
  BALANCE: {
    SUBMIT: "/balance/submit-balance",
    DELETE: "/balance/delete-balance",
  },
  PERIODE: {
    GET: "/periode/by-period",
    PUT: "/periode/change-status",
  },
  POSITIONFX: {
    SUBMIT: "/positionFx/submit-positionFx",
  },
  PERIODEFX: {
    GET: "/periodeFx/by-period",
    PUT: "/periodeFx/change-status",
  },
  BONDPORTFOLIO: {
    SUBMIT: "/bond-portfolio/submit-bp",
    DELETE: "/bond-portfolio/delete-bp",
  },
  PERIODEBP: {
    GET: "/periodeBP/by-period",
    PUT: "/periodeBP/change-status",
  },
  NOTIFICATION: {
    GET: "/notifications/",
    MARK_AS_SEEN: "/notifications/",
  },
};

export default endpoint;
