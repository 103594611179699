import { LocalizationProvider } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  Slide,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import constants from "../../common/constants";
import {
  AlertStyled,
  DatePickerStyled,
  DialogStyled,
  MoreInfoButton,
  ReloadButton,
  ToolbarContainer,
} from "./dailyCash.styles";
import SyncIcon from "@mui/icons-material/Sync";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  fetchDeclarationsAction,
  fetchMonthlyDeclarationsAction,
} from "../../redux/declaration/declaration.slice";
import { useDispatch } from "react-redux";
import { getMomentDateFromNormal } from "../../UtilFunctions";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import React, { useState } from "react";
import { forwardRef } from "react";

export default function HeaderAlert({
  status,
  onChangeDeclareDate,
  declareDate,
  locale,
  isMonthly,
  comment,
}) {
  const dispatch = useDispatch();
  const [showComment, setShowComment] = useState(false);
  const onReload = () => {
    if (isMonthly) {
      dispatch(fetchMonthlyDeclarationsAction());
    } else {
      dispatch(fetchDeclarationsAction());
    }
  };

  const onShowDetails = () => {
    setShowComment((old) => !old);
  };

  const alert = (
    <ToolbarContainer>
      <AlertStyled variant="outlined" severity={severityStatus(status)}>
        {status === constants.DECLARATION.STATUS.DRAFT && (
          <p className="paragraph">
            {`You still didn't declare your ${
              isMonthly ? "monthly" : "daily"
            } need of cash ${isMonthly ? "for this month" : "for today"}`}
          </p>
        )}
        {status === constants.DECLARATION.STATUS.SUBMITTED && (
          <p className="paragraph">
            {`Your ${
              isMonthly ? "monthly" : "daily"
            } declaration was submitted and is waiting for approval`}
          </p>
        )}
        {(status === constants.DECLARATION.STATUS.APPROVED ||
          status === constants.DECLARATION.STATUS.ACCEPTED) && (
          <p className="paragraph">
            {`Your ${
              isMonthly ? "monthly" : "daily"
            } declaration was approved and cannot be changed`}
          </p>
        )}
        {status == constants.DECLARATION.STATUS.REJECTED && (
          <p className="paragraph">
            {`Your ${
              isMonthly ? "monthly" : "daily"
            } declaration was rejected and cannot be changed`}
          </p>
        )}
        {comment && comment !== null && (
          <MoreInfoButton size="large" onClick={onShowDetails}>
            <StickyNote2Icon />
          </MoreInfoButton>
        )}

        <CommentDialog
          selectedValue={comment}
          open={showComment}
          onClose={onShowDetails}
        />
      </AlertStyled>
      <div className={"date-today"}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={constants.localeMap[locale]}
        >
          <DatePickerStyled
            disableFuture
            openTo={isMonthly ? "month" : "day"}
            views={isMonthly ? ["year", "month"] : ["year", "month", "day"]}
            value={
              typeof declareDate === "string"
                ? getMomentDateFromNormal(declareDate)
                : declareDate
            }
            onChange={onChangeDeclareDate}
            renderInput={(params) => <TextField {...params} />}
            label={"Date"}
          ></DatePickerStyled>
          <ReloadButton size="large" onClick={onReload}>
            <SyncIcon />
          </ReloadButton>
        </LocalizationProvider>
      </div>
    </ToolbarContainer>
  );

  return isMonthly ? (
    alert
  ) : (
    <div className={"date-today"} style={{ marginTop: 20, marginRight: 25 }}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={constants.localeMap[locale]}
      >
        <DatePickerStyled
          disableFuture
          openTo={isMonthly ? "month" : "day"}
          views={isMonthly ? ["year", "month"] : ["year", "month", "day"]}
          value={
            typeof declareDate === "string"
              ? getMomentDateFromNormal(declareDate)
              : declareDate
          }
          onChange={onChangeDeclareDate}
          renderInput={(params) => <TextField {...params} />}
          label={"Date"}
        ></DatePickerStyled>
        <ReloadButton size="large" onClick={onReload}>
          <SyncIcon />
        </ReloadButton>
      </LocalizationProvider>
    </div>
  );
}

const CommentDialog = ({ onClose, selectedValue, open }) => {
  return (
    <DialogStyled
      TransitionComponent={Transition}
      onClose={onClose}
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        The rejection reason details
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {selectedValue}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </DialogStyled>
  );
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const severityStatus = (status) => {
  if (status == constants.DECLARATION.STATUS.DRAFT) return "warning";
  if (status == constants.DECLARATION.STATUS.SUBMITTED) return "info";
  if (
    status == constants.DECLARATION.STATUS.APPROVED ||
    status == constants.DECLARATION.STATUS.ACCEPTED
  )
    return "success";
  if (status == constants.DECLARATION.STATUS.REJECTED) return "error";
  return "warning";
};
