import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import {
  selectAccessToken,
  selectChangePasswordStatus,
  selectLoginStatus,
  selectRefreshToken,
  selectTokenId,
} from "../../redux/authentication/authentication.selector";
import {
  loadFromSessionStorage,
  loginAction,
  logOutClear,
} from "../../redux/authentication/authentication.slice";
import { isNullOrUndefined, isTokenExpired } from "../../UtilFunctions";
import constants from "../constants";
import LoadingComponent from "../LoadingComponent";

export default function GuardHOC({ children }) {
  const accessToken = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);
  const status = useSelector(selectLoginStatus);
  const resetPwdStatus = useSelector(selectChangePasswordStatus);
  const tokenId = useSelector(selectTokenId);

  const dispatch = useDispatch();
  /**
   *
   */
  useEffect(() => {
    dispatch(loadFromSessionStorage());
  }, [dispatch]);

  useEffect(() => {
    if (false === isNullOrUndefined(refreshToken)) {
      if (isTokenExpired(refreshToken)) {
        dispatch(logOutClear());
      } else {
        if (isNullOrUndefined(accessToken)) {
          dispatch(loginAction({ refreshToken, tokenId }));
        }
      }
    }
  }, [refreshToken, accessToken, dispatch, tokenId]);

  const location = useLocation();

  if (
    status === constants.API_CALL_STATUS.PENDING ||
    resetPwdStatus === constants.API_CALL_STATUS.PENDING
  )
    return <LoadingComponent />;

  if (
    resetPwdStatus === constants.API_CALL_STATUS.REJECTED ||
    status === constants.API_CALL_STATUS.REJECTED ||
    ((resetPwdStatus !== constants.API_CALL_STATUS.PENDING ||
      status !== constants.API_CALL_STATUS.PENDING) &&
      accessToken === null)
  ) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
